<template>
  <div class="home">
    <div class="opacity-overlay"></div>
    <button class="btn" @click="login()" v-if="!user && !isLoading">
      Login
    </button>
    <button class="btn btn-logout" @click="logout" v-if="isAuthenticated">
      Logout
    </button>

    <!-- Removed the paragraph that displays the username -->

    <div
      class="guilds-dropdown-container"
      v-if="isAuthenticated && user && user.guilds && !isLoading"
    >
      <div class="gd-container-header">
        <h1>Manage Servers</h1>
      </div>

      <div class="gd-container-body">
        <select v-model="selectedGuild" class="choose-servers-dropdown">
          <option value="" disabled selected>Choose A Server</option>
          <option
            v-for="guild in user.guilds"
            :key="guild.id"
            :value="guild.id"
          >
            {{ guild.name }}
          </option>
        </select>

        <div class="btns-container">
          <button
            class="modal-btn add-btn"
            @click="AddBotToServer"
            :disabled="botInGuild || !selectedGuild"
          >
            Add Bot
          </button>
          <button
            class="modal-btn manage-btn"
            @click="SelectServer"
            :disabled="!botInGuild || !selectedGuild || isAlreadyLoadingServer"
          >
            <p class="btn-text">Manage Server</p>
          </button>
        </div>
      </div>
    </div>
    <div class="loader" v-if="isLoading">Loading...</div>
    <StatusMessage />
  </div>
</template>

<script>
import axios from 'axios';
axios.defaults.withCredentials = true;
import { mapGetters } from 'vuex';
import StatusMessage from '../components/StatusComponent.vue';

export default {
  name: 'Home',
  components: {
    StatusMessage,
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'user']),
  },
  data() {
    return {
      username: null,
      guilds: null,
      selectedGuild: '',
      isLoading: false,
      botGuilds: [], // Initialize as an empty array
      botInGuild: false,
      isAlreadyLoadingServer: false,
    };
  },
  created() {
    this.checkCode();
    this.fetchBotGuilds(); // Call fetchBotGuilds in created hook
  },
  methods: {
    fetchBotGuilds() {
      if (this.isAuthenticated) {
        // raise fake error

        this.isLoading = true;
        axios
          .get(`${this.$store.state.apiUrl}/api/dashboard/discord/guilds/bot`)
          .then(response => {
            this.botGuilds = response.data.data;
            if (this.selectedGuild) {
              // Recheck if bot is in guild after fetching botGuilds
              this.checkIfBotInGuild(this.selectedGuild);
            }
          })
          .catch(error => {
            this.$store.commit('setStatusMessage', {
              type: 'error',
              text: 'Error fetching discord bot guilds. Please try again later.',
            });
            return;
          });
          this.isLoading = false;
      }
    },
    checkIfBotInGuild(guildId) {
      const isGuildInBotGuilds = this.botGuilds.some(
        guild => guild.id === guildId
      );
      this.botInGuild = isGuildInBotGuilds;
    },

    AddBotToServer() {
      // open a url in a new tab to add the bot to the server
      window.open(
        `https://discord.com/api/oauth2/authorize?client_id=${this.$store.state.application_id}&permissions=8&scope=bot`
      );
    },
    login() {
      window.location.href =
      this.$store.state.discordRedirectUrl 
    },
    logout() {
      // emit the logout event
      this.$store.dispatch('logout');
    },
    checkCode() {
      const code = this.$route.query.code;
      if (code) {
        this.isLoading = true;
        // clear the query string
        this.$router.replace({ query: {} });
        axios
          .post(`${this.$store.state.apiUrl}/api/dashboard/discord-callback`, {
            code: code,
            // headers: { 'Allow-Control-Allow-Origin': '*' },
          })
          .then(response => {
            this.username = response.data.data.user.username;
            this.guilds = response.data.data.user.guilds;
            this.$store.commit('setUser', response.data.data.user);
            this.$store.commit('setAuthenticated', true);
            this.$store.commit(
              'setDiscordAccessToken',
              response.data.data.access_token
            );
            this.$store.commit(
              'setDiscordRefreshToken',
              response.data.data.refresh_token
            );
            this.isLoading = false;
            // change the is authenicated state
          })
          .catch(error => {
            this.$store.commit('setStatusMessage', {
              type: 'error',
              text: 'Error logging in. Please try again later.',
            });

            this.isLoading = false;
          });
      }
    },
    async SelectServer() {
      this.isAlreadyLoadingServer = true;
      try {
        const response = await this.$store.dispatch(
          'ManageServer',
          this.selectedGuild
        );
        if (response) {
          this.$store.commit('setActiveTab', 'DashboardHome');
          this.$router.push({ name: 'Dashboard' });
        } else {
          this.$store.commit('setStatusMessage', {
            type: 'error',
            text: 'Could not access dashboard',
          });
        }
      } catch (error) {
        this.$store.commit('setStatusMessage', {
          type: 'error',
          text: 'Could not access dashboard',
        });
      } finally {
        this.isAlreadyLoadingServer = false;
      }
    },
  },
  watch: {
    $route: 'checkCode',
    isAuthenticated(newValue) {
      if (newValue) {
        this.fetchBotGuilds(); // Refetch botGuilds when isAuthenticated changes
      }
    },
    selectedGuild(newGuildId) {
      this.checkIfBotInGuild(newGuildId);
    },
  },
};
</script>

<style scoped>
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #090f18;
  position: relative; /* Added to position the logo */
}

.home::before {
  content: '';
  position: absolute;
  top: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  background-repeat: no-repeat;
  background-size: contain; /* Adjust as needed */
  width: 100px; /* Adjust as needed */
  height: 100px; /* Adjust as needed */
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.4rem;
  color: #b8ffe5;
}

.btn-logout {
  position: absolute;
  top: 20px;
  left: 20px; /* Adjust as needed */
  /* Other styles as needed */
  /* width: 100px; */
  padding: 10px;
  font-size: 1.4rem !important;
  cursor: pointer;
}


/* put username top left corner of the screen aligned with the logo and same padding */
.guilds-dropdown-container {
  display: flex;
  flex-direction: column;
  background: linear-gradient(
      0deg,
      rgba(187, 218, 255, 0.08),
      rgba(187, 218, 255, 0.08)
    ),
    linear-gradient(0deg, rgba(187, 218, 255, 0.04), rgba(187, 218, 255, 0.04));
  justify-content: center;
  border-radius: 10px;
  border-bottom: 1px solid #c3eefc29;
  margin: 20px auto; /* Center the dropdown */
  width: 400px; /* Adjust as needed */
}

.gd-container-header {
  border-top: 1px solid #c3eefc29;
  border-right: 1px solid #c3eefc29;
  border-left: 1px solid #c3eefc29;
  padding: 24px;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom: 1px solid #c3eefc29;
}

.gd-container-header h1 {
  margin: 0;
  font-family: 'Inter';
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
}

.gd-container-body {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  border-left: 1px solid #c3eefc29;
  border-right: 1px solid #c3eefc29;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.choose-servers-dropdown {
  /* Frame 171 */

  box-sizing: border-box;

  display: inline-block;
  padding: 12px 16px;
  gap: 16px;

  height: 45px;

  border: 1px solid rgba(195, 238, 252, 0.16);
  border-radius: 12px;

  width: 100%;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  /* identical to box height, or 21px */

  color: #ffffff;
  appearance: none; /* Remove default arrow */
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><path d="M12.6666 6.5L7.99992 11.1667L3.33325 6.5" stroke="white" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-position: right 16px center; /* Position it 16px from the right edge */
  padding-right: 36px; /* Add padding to prevent the text from overlapping the arrow */
  cursor: pointer;
  background-color: rgba(195, 218, 252, 0.08);
}

.choose-servers-dropdown:focus {
  outline: none;
}

.choose-servers-dropdown option {
  color: black;
}

.btns-container {
  display: flex;
  gap: 8px;
  padding-top: 24px;
  padding-bottom: 24px;
}
.modal-btn {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 24px;
  padding: 12px 16px 12px 12px;
  font-family: 'DM Mono';
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-align: center;
  border: none;
  width: 100%;
  justify-content: center;
  cursor: pointer;
}
.add-btn {
  background: linear-gradient(
      0deg,
      rgba(195, 218, 252, 0.08),
      rgba(195, 218, 252, 0.08)
    ),
    linear-gradient(0deg, rgba(195, 238, 252, 0.16), rgba(195, 238, 252, 0.16));
  border: 1px solid #c3eefc29;

  color: #bbced8;
}

.modal-btn:disabled {
  opacity: 0.5;

  cursor: not-allowed;
}
</style>
