<template>
  <router-view />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  created() {
    this.connectWebSocket(); // Call the action to establish WebSocket connection
  },
  methods: {
    ...mapActions(['connectWebSocket']),
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&family=Inter:wght@100..900&display=swap');
html,
body {
  margin: 0;
  padding: 0;
  font-size: 10px;
}

@media (max-width: 800px) {
  html,
  body {
    font-size: 10px;
  }
  
}

.discord-user-pfp {
  /* border-radius: 50%; */

  width: 32px;

  border-radius: 50%;
}

* {
  box-sizing: border-box;
}

p {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}

.content-header {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  margin: 0;
  font-size: 2.4rem !important;
  line-height: 29px;
  margin-bottom: 8px;
  /* identical to box height */

  color: #ffffff;
}

.delete-btn {
    cursor: pointer;
}
.delete-btn img {
    width: 24px;
}


.content-header-sub {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  color: #bbd1d8;
  margin-bottom: 24px;
}


.modal-btn {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 24px;
  padding: 12px 16px 12px 12px;
  font-family: 'DM Mono';
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-align: center;
  border: none;
  width: 100%;
  justify-content: center;
  cursor: pointer;
}


.save-btn {
  background: #ffffff;
}


.btn {
  padding: 12px;
  font-size: 1.4rem;
  border-radius: 24px;
  background: white;
  border: none;
  font-family: 'DM Mono';
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  min-width: 170px;
  letter-spacing: 0.08em;

  color: black; /* Text color is your brand color */

  cursor: pointer;
}

.cancel-btn {
  background: linear-gradient(
      0deg,
      rgba(195, 218, 252, 0.08),
      rgba(195, 218, 252, 0.08)
    ),
    linear-gradient(0deg, rgba(195, 238, 252, 0.16), rgba(195, 238, 252, 0.16));
  border: 1px solid #c3eefc29;
  color: #bbced8;
}

.save-btn .btn-text {
  margin-left: 8px;
}


.dropdown {
  box-sizing: border-box;
  display: inline-block;
  padding: 8px 12px;
  border: 1px solid rgba(195, 238, 252, 0.16);
  border-radius: 12px;
  font-family: 'Inter';
  font-style: normal;
  width: 165px;

  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  /* identical to box height, or 21px */
  color: #ffffff;
  appearance: none; /* Remove default arrow */
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><path d="M12.6666 6.5L7.99992 11.1667L3.33325 6.5" stroke="white" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-position: right 16px center; /* Position it 16px from the right edge */
  padding-right: 36px; /* Add padding to prevent the text from overlapping the arrow */
  cursor: pointer;
  background-color: rgba(195, 218, 252, 0.08);
}

.dropdown option {
  color: black;
}


.modal-sub-heading {
  /* COMMAND NAME */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 0.08em;

  color: #bbced8;
  text-align: center;
}
.modal-main-heading {
  /* Add Channels */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 29px;
  /* identical to box height */

  color: #ffffff;
  text-align: center;

  margin-top: 8px;
}

.modal-row {
  display: flex;
  justify-content: space-between;
}

.modal-row-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.modal-input {
  width: 165px;
}
.modal-footer-content-container {
  display: flex;
  gap: 8px;
}

.required-input:after {
    content:" *";
    color: #6fe0af;
    left: 100px;
  }

  .modal-option {
  flex-direction: column;
  text-align: left;
  display: flex;
  row-gap: 4px;
}

.modal-option label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 15px;
  /* identical to box height */
  /* letter-spacing: 0.08em; */
  padding-left: 5px;

  color: #bbced8;
}


.modal-option-input {
  background-color: rgba(195, 218, 252, 0.08);
  box-sizing: border-box;

  display: inline-block;

  padding: 8px 12px;

  border: 1px solid rgba(195, 238, 252, 0.16);
  border-radius: 12px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  /* identical to box height, or 21px */

  color: #ffffff;
}
.dropdown:focus,
.modal-option-input:focus {
  outline: none;
}

.modal-option-input {
  width: 165px;
}


.modal-checkbox-select {
  padding: 12px;
  border-radius: 8px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: 'DM Mono';
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(187, 206, 216, 1);
}



</style>
