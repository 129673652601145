<template>
  <div class="item-auto-ban-management-container">
    <div class="item-ban-management-top">
      <h1 class="content-header">Item Ban Management</h1>
      <p class="content-header-sub">
        Manage your banned items across your servers
      </p>
    </div>

    <div class="item-ban-management-options">
      <select
        name=""
        id=""
        class="choose-shop-item dropdown"
        v-model="selectedBannedItem"
        @change="updateSelectedItemSelect"
      >
        <option disabled value="">Edit Banned Item</option>
        <option
          v-for="banneditem in bannedItems"
          :key="banneditem.id"
          :value="banneditem"
        >
          {{ banneditem.item_name }}
        </option>
      </select>
      <button
        class="btn new-banned-item-btn"
        @click="openAddBannedItemModal"
        :disabled="!itemAddBtnEnabled"
      >
        <p>Add New Banned Item</p>
      </button>
    </div>

    <div class="item-ban-management-section" v-if="selectedBannedItem">
        <div class="modal-option">
              <label for="item name" class="required-input"
                >Item Name (Exactly like logs)</label
              >
              <input
                type="text"
                class="modal-option-input"
                v-model="selectedBannedItemUpdated.item_name"
                maxlength="100"
              />
            </div>
        <div class="modal-option">
              <label for="ban_duration" class=""
                >Ban Duration (Days)</label
              >
              <input
                type="text"
                class="modal-option-input"
                v-model="selectedBannedItemUpdated.ban_duration"
                maxlength="100"
              />
            </div>

            <div class="modal-option">
              <label for="item name" class="">Bail Price</label>
              <input
                type="number"
                class="modal-option-input"
                v-model="selectedBannedItemUpdated.bail_price"
                min="1"
                max="1000000000"
              />
            </div>
            <div class="modal-option">
              <label for="auto_bail" class="">Auto Bail</label>
              <select
                name=""
                class="dropdown"
                v-model="selectedBannedItemUpdated.auto_bail"
              >
                <option value="false">False</option>
                <option value="true">True</option>
              </select>
            </div>

            <div class="modal-option">
              <label for="enabled" class="">Enabled</label>
              <select
                name=""
                class="dropdown"
                v-model="selectedBannedItemUpdated.enabled"
              >
                <option value="false">False</option>
                <option value="true">True</option>
              </select>
            </div>
            <button
        class="btn edit-servers-btn"
        @click="openEditItemBanServersModal"
      >
        <p>Edit Servers</p>
      </button>

            <div class="btn-container-banned-item-update"> 
            <button class="modal-btn cancel-btn" @click="discardUpdatedChanges">
            Cancel
          </button>
          <button
            class="modal-btn save-btn"
            @click="UpdateSelectedBannedItem"
            :disabled="
              !itemUpdateBtnEnabled
            "
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="btn-text">Save</p>
          </button>
          <div class="delete-btn" @click="deleteItemBan">
                <img src="../assets/TrashOutline.svg" alt="">
            </div>

        </div>
    </div>

    <Modal :show="showModal" @update:show="showModal = $event">
      <template v-slot:header-content>
        <div class="header-content-container">
          <p class="modal-sub-heading">Banned Items</p>
          <p class="modal-main-heading">Add New Banned Item</p>
        </div>
      </template>
      <template v-slot:body-content>
        <div class="modal-row-container">
          <div class="modal-row">
            <div class="modal-option">
              <label for="item name" class="required-input"
                >Item Name (Exactly like logs)</label
              >
              <input
                type="text"
                class="modal-option-input"
                v-model="newBannedItem.item_name"
                maxlength="100"
              />
            </div>
            <div class="modal-option">
              <label for="item name" class="">Ban Duration (Days)</label>
              <input
                type="number"
                class="modal-option-input"
                v-model="newBannedItem.ban_duration"
                min="0"
                max="365"
              />
            </div>
          </div>
          <div class="modal-row">
            <div class="modal-option">
              <label for="item name" class="">Bail Price</label>
              <input
                type="number"
                class="modal-option-input"
                v-model="newBannedItem.bail_price"
                min="1"
                max="1000000000"
              />
            </div>
            <div class="modal-option">
              <label for="auto_bail" class="">Auto Bail</label>
              <select
                name=""
                class="dropdown"
                v-model="newBannedItem.auto_bail"
              >
                <option value="false">False</option>
                <option value="true">True</option>
              </select>
            </div>
          </div>
          <div class="modal-row">
            <div class="modal-option">
              <label for="enabled" class="">Enabled</label>
              <select
                name=""
                class="dropdown"
                v-model="newBannedItem.enabled"
              >
                <option value="false">False</option>
                <option value="true">True</option>
              </select>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer-content>
        <div class="modal-footer-content-container">
          <button class="modal-btn cancel-btn" @click="discardNewBannedItem">
            Cancel
          </button>
          <button
            class="modal-btn save-btn"
            @click="AddNewBannedItem"
            :disabled="
              !itemAddBtnEnabled || !newBannedItem.item_name
            "
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="btn-text">Save Changes</p>
          </button>
        </div>
      </template>
    </Modal>

    <Modal
      :show="ShowEditBannedItemServersModal"
      @update:show="ShowEditBannedItemServersModal = $event"
    >
      <template v-slot:header-content>
        <div class="modal-header-content-container">
          <p class="modal-sub-heading">Edit Servers</p>
          <p class="modal-main-heading">Edit The Servers This Item Is Banned On</p>
        </div>
      </template>

      <template v-slot:body-content>
        <div class="modal-body-content-container">
          <div class="modal-body-restricted-servers-container">
            <div
              v-for="server in currentLinkedServers"
              :key="server.id"
              class="modal-checkbox-select"
              @click="updateSelectedBannedItemServers($event, server.id)"
              :class="{
                'active-checked': selectedBannedItemUpdated.servers.some(
                  (selectedServer) => selectedServer.id === server.id
                ),
              }"
            >
              <input
                type="checkbox"
                :id="server.id"
                class="checkbox"
                :checked="selectedBannedItemUpdated.servers.some(
                  (selectedServer) => selectedServer.id === server.id
                )"
                @click.prevent
              />
              <label :for="server.id" class="checkbox-label"></label>
              <p class="checkbox-name">{{ server.server_identifier }}</p>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:footer-content>
        <div class="modal-footer-content-container">
          
        </div>
      </template>
    </Modal>

  </div>
</template>

<script>
// import vue store
import { mapState } from 'vuex';
import Modal from './Modal.vue';
export default {
  name: 'ItemAutoBanManagement',

  components: { Modal },

  data() {
    return {
      selectedBannedItem: '',
      selectedBannedItemUpdated: {},
      newBannedItem: {},
      
      showModal: false,
      showEditModal: false,
      itemUpdateBtnEnabled: true,
      itemAddBtnEnabled: true,
      ShowEditBannedItemServersModal: false,
      updateServersBtnEnabled: true,
    };
  },

  computed: {
    bannedItems() {
      return this.$store.state.bannedItems.banned_items;
    },
    currentLinkedServers() {
      return this.$store.state.linked_servers
      
    },
  },

  methods: {
   async loadBannedItems(){

    let currentCacheTimestamp = this.$store.state.bannedItems.last_cache_timestamp;
    let currentTime = Date.now();
    let timeDifference = currentTime - currentCacheTimestamp;

    // if the cache is older than 5 minutes, refresh the cache
    if (timeDifference > 300000) {
      const response = await this.$store.dispatch('sendRequest', {
        method: 'GET',
        url: `${this.$store.state.apiUrl}/api/item-auto-ban`,
        show_message_on_success: false,
      });

      if (response) {
        const websocket_message = {
          event: 'update_banned_items',
          discord_server_id: this.$store.state.client_discord_server_id,
          data: response.item_bans,
        };
        this.$store.state.websocket.send(
          JSON.stringify(websocket_message)
        );
      }
    } 


   },

    openEditItemBanServersModal() {
      this.ShowEditBannedItemServersModal = true;
    },

    openAddBannedItemModal() {
      this.showModal = true;
    },

    discardNewBannedItem() {
      this.showModal = false;
      this.newBannedItem = {};
    },

    async AddNewBannedItem() {
      this.itemAddBtnEnabled = false;
      const response = await this.$store.dispatch('sendRequest', {
        method: 'POST',
        url: `${this.$store.state.apiUrl}/api/item-auto-ban`,
        data: this.newBannedItem,
      });

      if (response) {
        let current_banned_items = JSON.parse(JSON.stringify(this.bannedItems));
        current_banned_items.push(response.item_ban);
        let websocket_message = {
        event: "update_banned_items",
        discord_server_id: this.$store.state.client_discord_server_id,
        data: current_banned_items,
      };
      this.$store.state.websocket.send(
        JSON.stringify(websocket_message)
      );
      this.showModal = false;
      this.newBannedItem = {};
    } 
    this.itemAddBtnEnabled = true;
    },

    updateSelectedItemSelect() {

      this.selectedBannedItemUpdated = JSON.parse(
        JSON.stringify(this.selectedBannedItem)
      );
      

    },

   async updateSelectedBannedItemServers(event, server_id){

    if (!event.target.classList.contains('modal-checkbox-select')) {
      return;
    }

    // check if the server is already in the selected servers
    let server_index = this.selectedBannedItemUpdated.servers.findIndex(
      (server) => server.id === server_id
    );

    if (server_index === -1) {
      this.selectedBannedItemUpdated.servers.push({
        id: server_id,
        name: this.currentLinkedServers.find(
          (server) => server.id === server_id
        ).server_identifier,
      });
    } else {
      this.selectedBannedItemUpdated.servers.splice(server_index, 1);
    }

   },

   getChangedKeyValuePairs(changdObject, originalObject) {
      let changedValues = {};
      for (let key in changdObject) {
        if (changdObject[key] !== originalObject[key]) {
          changedValues[key] = changdObject[key];
        }
      }
      return changedValues;
    },

    async UpdateSelectedBannedItem() {
        this.itemUpdateBtnEnabled = false;

        let changed_values = this.getChangedKeyValuePairs(
        this.selectedBannedItemUpdated,
        this.selectedBannedItem
      );

      let noServerChanges = this.selectedBannedItemUpdated.servers.every(
        server => this.selectedBannedItem.servers.some(s => s.id === server.id)
      ) && this.selectedBannedItem.servers.every(
        server => this.selectedBannedItemUpdated.servers.some(s => s.id === server.id)
      );
      // Handle the case where servers is present in changed_values
      if (changed_values.servers) {
        // If the servers array is empty, set it to an empty array
        if (changed_values.servers.length === 0) {
          changed_values.servers = [];
        }
      }

      // If there are no changes in restricted servers, remove the servers key from changed_values
      if (noServerChanges) {
        delete changed_values.servers;
      }
      // if key is servers, change it to restricted_server_ids
      if (changed_values.servers) {
        // we only need the server ids
        changed_values.server_ids = changed_values.servers.map(
          (server) => server.id
        );
        delete changed_values.servers;
      }
        const response = await this.$store.dispatch('sendRequest', {
            method: 'PUT',
            url: `${this.$store.state.apiUrl}/api/item-auto-ban`,
            data: {
              id: this.selectedBannedItemUpdated.id,
              ...changed_values,
            }
        });
    
        if (response) {
          let current_banned_items = JSON.parse(JSON.stringify(this.bannedItems));
          let item_index = current_banned_items.findIndex(
            (item) => item.id === response.item_ban.id
          );
          current_banned_items[item_index] = response.item_ban;
          let websocket_message = {
            event: "update_banned_items",
            discord_server_id: this.$store.state.client_discord_server_id,
            data: current_banned_items,
          };
          this.$store.state.websocket.send(
            JSON.stringify(websocket_message)
          );
        }
        this.itemUpdateBtnEnabled = true;
        this.selectedBannedItem = response.item_ban;
    },

    discardUpdatedChanges() {
      this.selectedBannedItemUpdated = JSON.parse(
        JSON.stringify(this.selectedBannedItem)
      );
    },

    async deleteItemBan() {
      const response = await this.$store.dispatch('sendRequest', {
        method: 'DELETE',
        url: `${this.$store.state.apiUrl}/api/item-auto-ban`,
        data: {
          id: this.selectedBannedItemUpdated.id,
        },
      });

      if (response) {
        let current_banned_items = JSON.parse(JSON.stringify(this.bannedItems));
        let item_index = current_banned_items.findIndex(
          (item) => item.id === this.selectedBannedItemUpdated.id
        );
        current_banned_items.splice(item_index, 1);
        let websocket_message = {
          event: "update_banned_items",
          discord_server_id: this.$store.state.client_discord_server_id,
          data: current_banned_items,
        };
        this.$store.state.websocket.send(
          JSON.stringify(websocket_message)
        );
        this.selectedBannedItem = '';
        this.selectedBannedItemUpdated = {};
    

      }
    },

  
  },

  created() {
    this.loadBannedItems();
  },
};
</script>

<style scoped>
.item-auto-ban-management-container {
  background: rgba(187, 218, 255, 0.04);
  border: 1px solid rgba(187, 218, 255, 0.08);
  border-radius: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  text-align: left;
}

.item-ban-management-options {
  display: flex;
  gap: 24px;
  align-items: center;
}

.new-banned-item-btn {
  box-sizing: border-box;
  display: inline-block;
  line-height: 150%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-family: 'DM Mono';
  font-size: 1.4em;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-align: left;
  padding: 10px 12px 10px 12px;
  border-radius: 12px;
  outline: none;
  border: none;
}

.edit-servers-btn {
    box-sizing: border-box;
  display: inline-block;
  line-height: 150%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-family: 'DM Mono';
  font-size: 1.4em;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-align: left;
  padding: 10px 12px 10px 12px;
  border-radius: 12px;
  outline: none;
  border: none;
  height: fit-content;
  width: fit-content
}

.item-ban-management-section {
    margin-top: 24px;
    display: grid;
    /* make the grid auto responsive, as many columns as it can fit, each column 200px max */
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    row-gap: 16px;
    align-items: end;
}

.btn-container-banned-item-update {
  display: flex;
  gap: 8px;
  margin-top: 24px;
}

@media (max-width: 500px) {
  .item-ban-management-options {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  
}

.active-checked {
  background: rgba(195, 218, 252, 0.08);
}
.checkbox {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #bbced8a3;
  border-radius: 4px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-label {
  width: 16px;
  height: 16px;
  border: 1px solid #bbced8a3;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.checkbox:checked + .checkbox-label {
  background: #42d09c3d;

  border: 2px solid #42d09ca3;
  background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M13.3657%204.23447C13.5157%204.3845%2013.5999%204.58794%2013.5999%204.80007C13.5999%205.0122%2013.5157%205.21565%2013.3657%205.36567L6.96568%2011.7657C6.81566%2011.9156%206.61221%2011.9999%206.40008%2011.9999C6.18795%2011.9999%205.9845%2011.9156%205.83448%2011.7657L2.63448%208.56567C2.48876%208.41479%202.40812%208.21271%202.40994%208.00295C2.41177%207.7932%202.4959%207.59255%202.64423%207.44422C2.79255%207.29589%202.9932%207.21176%203.20296%207.20993C3.41272%207.20811%203.6148%207.28875%203.76568%207.43447L6.40008%2010.0689L12.2345%204.23447C12.3845%204.0845%2012.588%204.00024%2012.8001%204.00024C13.0122%204.00024%2013.2157%204.0845%2013.3657%204.23447Z%22%20fill%3D%22%2342D09C%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: center;
}
.delete-btn {
  align-self: center;
  margin-left: 12px;
}
</style>
