<template>
  <div class="menu">
    <div class="welcome-container">
      <img :src="avatarUrl" alt="" class="discord-user-pfp" />
      <p class="welcome-text">Welcome, {{ user && user.username ? user.username : 'User' }}</p>
    </div>
    <div class="menu-flex-container">
      <button
        class="menu-button"
        :class="{ active: activeTab === 'DashboardHome' }"
        @click="switchTab('DashboardHome')"
      >
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="menu-btn-img"
        >
          <path
            d="M4.2002 5.59995C4.2002 5.22865 4.34769 4.87255 4.61025 4.61C4.8728 4.34745 5.22889 4.19995 5.6002 4.19995H22.4002C22.7715 4.19995 23.1276 4.34745 23.3901 4.61C23.6527 4.87255 23.8002 5.22865 23.8002 5.59995V8.39995C23.8002 8.77125 23.6527 9.12735 23.3901 9.3899C23.1276 9.65245 22.7715 9.79995 22.4002 9.79995H5.6002C5.22889 9.79995 4.8728 9.65245 4.61025 9.3899C4.34769 9.12735 4.2002 8.77125 4.2002 8.39995V5.59995ZM4.2002 14C4.2002 13.6286 4.34769 13.2726 4.61025 13.01C4.8728 12.7475 5.22889 12.6 5.6002 12.6H14.0002C14.3715 12.6 14.7276 12.7475 14.9901 13.01C15.2527 13.2726 15.4002 13.6286 15.4002 14V22.4C15.4002 22.7713 15.2527 23.1273 14.9901 23.3899C14.7276 23.6525 14.3715 23.8 14.0002 23.8H5.6002C5.22889 23.8 4.8728 23.6525 4.61025 23.3899C4.34769 23.1273 4.2002 22.7713 4.2002 22.4V14ZM19.6002 12.6C19.2289 12.6 18.8728 12.7475 18.6102 13.01C18.3477 13.2726 18.2002 13.6286 18.2002 14V22.4C18.2002 22.7713 18.3477 23.1273 18.6102 23.3899C18.8728 23.6525 19.2289 23.8 19.6002 23.8H22.4002C22.7715 23.8 23.1276 23.6525 23.3901 23.3899C23.6527 23.1273 23.8002 22.7713 23.8002 22.4V14C23.8002 13.6286 23.6527 13.2726 23.3901 13.01C23.1276 12.7475 22.7715 12.6 22.4002 12.6H19.6002Z"
            fill="#BBD1D8"
            fill-opacity="0.64"
          />
        </svg>

        <p class="menu-btn-text">Dashboard</p>
      </button>
      <button
        class="menu-button"
        :class="{ active: activeTab === 'CommandsManagement' }"
        @click="switchTab('CommandsManagement', 'command_management.read')"
      >
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="menu-btn-img"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M25.1998 6.99995V18.2C25.1998 18.9426 24.9048 19.6547 24.3797 20.1798C23.8546 20.705 23.1424 21 22.3998 21H15.3998L8.3998 26.6V21H5.5998C4.8572 21 4.14501 20.705 3.61991 20.1798C3.0948 19.6547 2.7998 18.9426 2.7998 18.2V6.99995C2.7998 6.25734 3.0948 5.54515 3.61991 5.02005C4.14501 4.49495 4.8572 4.19995 5.5998 4.19995H22.3998C23.1424 4.19995 23.8546 4.49495 24.3797 5.02005C24.9048 5.54515 25.1998 6.25734 25.1998 6.99995ZM9.7998 11.2H6.9998V14H9.7998V11.2ZM12.5998 11.2H15.3998V14H12.5998V11.2ZM20.9998 11.2H18.1998V14H20.9998V11.2Z"
            fill="#BBD1D8"
            fill-opacity="0.64"
          />
        </svg>

        <p class="menu-btn-text">Commands</p>
      </button>
      <button
        class="menu-button"
        :class="{ active: activeTab === 'GameServersManagement'}"
        @click="switchTab('GameServersManagement', 'servers.read')"
      >
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.7998 4.19995C9.4285 4.19995 9.07241 4.34745 8.80986 4.61C8.5473 4.87255 8.3998 5.22865 8.3998 5.59995C8.3998 5.97125 8.5473 6.32735 8.80986 6.5899C9.07241 6.85245 9.4285 6.99995 9.7998 6.99995H18.1998C18.5711 6.99995 18.9272 6.85245 19.1898 6.5899C19.4523 6.32735 19.5998 5.97125 19.5998 5.59995C19.5998 5.22865 19.4523 4.87255 19.1898 4.61C18.9272 4.34745 18.5711 4.19995 18.1998 4.19995H9.7998ZM5.5998 9.79995C5.5998 9.42865 5.7473 9.07255 6.00986 8.81C6.27241 8.54745 6.6285 8.39995 6.9998 8.39995H20.9998C21.3711 8.39995 21.7272 8.54745 21.9898 8.81C22.2523 9.07255 22.3998 9.42865 22.3998 9.79995C22.3998 10.1713 22.2523 10.5273 21.9898 10.7899C21.7272 11.0525 21.3711 11.2 20.9998 11.2H6.9998C6.6285 11.2 6.27241 11.0525 6.00986 10.7899C5.7473 10.5273 5.5998 10.1713 5.5998 9.79995ZM2.7998 15.4C2.7998 14.6573 3.0948 13.9452 3.61991 13.4201C4.14501 12.895 4.8572 12.6 5.5998 12.6H22.3998C23.1424 12.6 23.8546 12.895 24.3797 13.4201C24.9048 13.9452 25.1998 14.6573 25.1998 15.4V21C25.1998 21.7426 24.9048 22.4547 24.3797 22.9798C23.8546 23.505 23.1424 23.8 22.3998 23.8H5.5998C4.8572 23.8 4.14501 23.505 3.61991 22.9798C3.0948 22.4547 2.7998 21.7426 2.7998 21V15.4Z"
            fill="#BBD1D8"
            fill-opacity="0.64"
          />
        </svg>

        <p class="menu-btn-text">Game Servers</p>
      </button>
      <button
        class="menu-button"
        :class="{ active: activeTab === 'ScheduledTasks'}"
        @click="switchTab('ScheduledTasks', 'scheduled_tasks.read')"
      >
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.7998 4.19995C9.4285 4.19995 9.07241 4.34745 8.80986 4.61C8.5473 4.87255 8.3998 5.22865 8.3998 5.59995C8.3998 5.97125 8.5473 6.32735 8.80986 6.5899C9.07241 6.85245 9.4285 6.99995 9.7998 6.99995H18.1998C18.5711 6.99995 18.9272 6.85245 19.1898 6.5899C19.4523 6.32735 19.5998 5.97125 19.5998 5.59995C19.5998 5.22865 19.4523 4.87255 19.1898 4.61C18.9272 4.34745 18.5711 4.19995 18.1998 4.19995H9.7998ZM5.5998 9.79995C5.5998 9.42865 5.7473 9.07255 6.00986 8.81C6.27241 8.54745 6.6285 8.39995 6.9998 8.39995H20.9998C21.3711 8.39995 21.7272 8.54745 21.9898 8.81C22.2523 9.07255 22.3998 9.42865 22.3998 9.79995C22.3998 10.1713 22.2523 10.5273 21.9898 10.7899C21.7272 11.0525 21.3711 11.2 20.9998 11.2H6.9998C6.6285 11.2 6.27241 11.0525 6.00986 10.7899C5.7473 10.5273 5.5998 10.1713 5.5998 9.79995ZM2.7998 15.4C2.7998 14.6573 3.0948 13.9452 3.61991 13.4201C4.14501 12.895 4.8572 12.6 5.5998 12.6H22.3998C23.1424 12.6 23.8546 12.895 24.3797 13.4201C24.9048 13.9452 25.1998 14.6573 25.1998 15.4V21C25.1998 21.7426 24.9048 22.4547 24.3797 22.9798C23.8546 23.505 23.1424 23.8 22.3998 23.8H5.5998C4.8572 23.8 4.14501 23.505 3.61991 22.9798C3.0948 22.4547 2.7998 21.7426 2.7998 21V15.4Z"
            fill="#BBD1D8"
            fill-opacity="0.64"
          />
        </svg>

        <p class="menu-btn-text">Scheduled Tasks</p>
      </button>
      <button
        class="menu-button"
        :class="{ active: activeTab === 'ShopManagement' }"
        @click="switchTab('ShopManagement', 'shop_admin.read')"
      >
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.7998 4.19995C9.4285 4.19995 9.07241 4.34745 8.80986 4.61C8.5473 4.87255 8.3998 5.22865 8.3998 5.59995C8.3998 5.97125 8.5473 6.32735 8.80986 6.5899C9.07241 6.85245 9.4285 6.99995 9.7998 6.99995H18.1998C18.5711 6.99995 18.9272 6.85245 19.1898 6.5899C19.4523 6.32735 19.5998 5.97125 19.5998 5.59995C19.5998 5.22865 19.4523 4.87255 19.1898 4.61C18.9272 4.34745 18.5711 4.19995 18.1998 4.19995H9.7998ZM5.5998 9.79995C5.5998 9.42865 5.7473 9.07255 6.00986 8.81C6.27241 8.54745 6.6285 8.39995 6.9998 8.39995H20.9998C21.3711 8.39995 21.7272 8.54745 21.9898 8.81C22.2523 9.07255 22.3998 9.42865 22.3998 9.79995C22.3998 10.1713 22.2523 10.5273 21.9898 10.7899C21.7272 11.0525 21.3711 11.2 20.9998 11.2H6.9998C6.6285 11.2 6.27241 11.0525 6.00986 10.7899C5.7473 10.5273 5.5998 10.1713 5.5998 9.79995ZM2.7998 15.4C2.7998 14.6573 3.0948 13.9452 3.61991 13.4201C4.14501 12.895 4.8572 12.6 5.5998 12.6H22.3998C23.1424 12.6 23.8546 12.895 24.3797 13.4201C24.9048 13.9452 25.1998 14.6573 25.1998 15.4V21C25.1998 21.7426 24.9048 22.4547 24.3797 22.9798C23.8546 23.505 23.1424 23.8 22.3998 23.8H5.5998C4.8572 23.8 4.14501 23.505 3.61991 22.9798C3.0948 22.4547 2.7998 21.7426 2.7998 21V15.4Z"
            fill="#BBD1D8"
            fill-opacity="0.64"
          />
        </svg>

        <p class="menu-btn-text">Shop Management</p>
      </button>

      <button
        class="menu-button"
        :class="{ active: activeTab === 'ItemAutoBanManagement' }"
        @click="switchTab('ItemAutoBanManagement', 'item_auto_ban.read')"
      >
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.7998 4.19995C9.4285 4.19995 9.07241 4.34745 8.80986 4.61C8.5473 4.87255 8.3998 5.22865 8.3998 5.59995C8.3998 5.97125 8.5473 6.32735 8.80986 6.5899C9.07241 6.85245 9.4285 6.99995 9.7998 6.99995H18.1998C18.5711 6.99995 18.9272 6.85245 19.1898 6.5899C19.4523 6.32735 19.5998 5.97125 19.5998 5.59995C19.5998 5.22865 19.4523 4.87255 19.1898 4.61C18.9272 4.34745 18.5711 4.19995 18.1998 4.19995H9.7998ZM5.5998 9.79995C5.5998 9.42865 5.7473 9.07255 6.00986 8.81C6.27241 8.54745 6.6285 8.39995 6.9998 8.39995H20.9998C21.3711 8.39995 21.7272 8.54745 21.9898 8.81C22.2523 9.07255 22.3998 9.42865 22.3998 9.79995C22.3998 10.1713 22.2523 10.5273 21.9898 10.7899C21.7272 11.0525 21.3711 11.2 20.9998 11.2H6.9998C6.6285 11.2 6.27241 11.0525 6.00986 10.7899C5.7473 10.5273 5.5998 10.1713 5.5998 9.79995ZM2.7998 15.4C2.7998 14.6573 3.0948 13.9452 3.61991 13.4201C4.14501 12.895 4.8572 12.6 5.5998 12.6H22.3998C23.1424 12.6 23.8546 12.895 24.3797 13.4201C24.9048 13.9452 25.1998 14.6573 25.1998 15.4V21C25.1998 21.7426 24.9048 22.4547 24.3797 22.9798C23.8546 23.505 23.1424 23.8 22.3998 23.8H5.5998C4.8572 23.8 4.14501 23.505 3.61991 22.9798C3.0948 22.4547 2.7998 21.7426 2.7998 21V15.4Z"
            fill="#BBD1D8"
            fill-opacity="0.64"
          />
        </svg>

        <p class="menu-btn-text">Item Bans</p>
      </button>

      <button
        class="menu-button"
        :class="{ active: activeTab === 'GamblingManagement' }"
        @click="switchTab('GamblingManagement', 'gambling_config.read')"
      >
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.7998 4.19995C9.4285 4.19995 9.07241 4.34745 8.80986 4.61C8.5473 4.87255 8.3998 5.22865 8.3998 5.59995C8.3998 5.97125 8.5473 6.32735 8.80986 6.5899C9.07241 6.85245 9.4285 6.99995 9.7998 6.99995H18.1998C18.5711 6.99995 18.9272 6.85245 19.1898 6.5899C19.4523 6.32735 19.5998 5.97125 19.5998 5.59995C19.5998 5.22865 19.4523 4.87255 19.1898 4.61C18.9272 4.34745 18.5711 4.19995 18.1998 4.19995H9.7998ZM5.5998 9.79995C5.5998 9.42865 5.7473 9.07255 6.00986 8.81C6.27241 8.54745 6.6285 8.39995 6.9998 8.39995H20.9998C21.3711 8.39995 21.7272 8.54745 21.9898 8.81C22.2523 9.07255 22.3998 9.42865 22.3998 9.79995C22.3998 10.1713 22.2523 10.5273 21.9898 10.7899C21.7272 11.0525 21.3711 11.2 20.9998 11.2H6.9998C6.6285 11.2 6.27241 11.0525 6.00986 10.7899C5.7473 10.5273 5.5998 10.1713 5.5998 9.79995ZM2.7998 15.4C2.7998 14.6573 3.0948 13.9452 3.61991 13.4201C4.14501 12.895 4.8572 12.6 5.5998 12.6H22.3998C23.1424 12.6 23.8546 12.895 24.3797 13.4201C24.9048 13.9452 25.1998 14.6573 25.1998 15.4V21C25.1998 21.7426 24.9048 22.4547 24.3797 22.9798C23.8546 23.505 23.1424 23.8 22.3998 23.8H5.5998C4.8572 23.8 4.14501 23.505 3.61991 22.9798C3.0948 22.4547 2.7998 21.7426 2.7998 21V15.4Z"
            fill="#BBD1D8"
            fill-opacity="0.64"
          />
        </svg>

        <p class="menu-btn-text">Gambling</p>
      </button>
      <button
        class="menu-button"
        :class="{ active: activeTab === 'PlacementPingsManagement' }"
        @click="switchTab('PlacementPingsManagement', 'placement_pings.read')"
      >
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.7998 4.19995C9.4285 4.19995 9.07241 4.34745 8.80986 4.61C8.5473 4.87255 8.3998 5.22865 8.3998 5.59995C8.3998 5.97125 8.5473 6.32735 8.80986 6.5899C9.07241 6.85245 9.4285 6.99995 9.7998 6.99995H18.1998C18.5711 6.99995 18.9272 6.85245 19.1898 6.5899C19.4523 6.32735 19.5998 5.97125 19.5998 5.59995C19.5998 5.22865 19.4523 4.87255 19.1898 4.61C18.9272 4.34745 18.5711 4.19995 18.1998 4.19995H9.7998ZM5.5998 9.79995C5.5998 9.42865 5.7473 9.07255 6.00986 8.81C6.27241 8.54745 6.6285 8.39995 6.9998 8.39995H20.9998C21.3711 8.39995 21.7272 8.54745 21.9898 8.81C22.2523 9.07255 22.3998 9.42865 22.3998 9.79995C22.3998 10.1713 22.2523 10.5273 21.9898 10.7899C21.7272 11.0525 21.3711 11.2 20.9998 11.2H6.9998C6.6285 11.2 6.27241 11.0525 6.00986 10.7899C5.7473 10.5273 5.5998 10.1713 5.5998 9.79995ZM2.7998 15.4C2.7998 14.6573 3.0948 13.9452 3.61991 13.4201C4.14501 12.895 4.8572 12.6 5.5998 12.6H22.3998C23.1424 12.6 23.8546 12.895 24.3797 13.4201C24.9048 13.9452 25.1998 14.6573 25.1998 15.4V21C25.1998 21.7426 24.9048 22.4547 24.3797 22.9798C23.8546 23.505 23.1424 23.8 22.3998 23.8H5.5998C4.8572 23.8 4.14501 23.505 3.61991 22.9798C3.0948 22.4547 2.7998 21.7426 2.7998 21V15.4Z"
            fill="#BBD1D8"
            fill-opacity="0.64"
          />
        </svg>

        <p class="menu-btn-text">Placement Pings</p>
      </button>


      <button
        class="menu-button"
        :class="{ active: activeTab === 'AdminManagement' }"
        @click="switchTab('AdminManagement', 'admin_management.read')"
      >
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.7998 4.19995C9.4285 4.19995 9.07241 4.34745 8.80986 4.61C8.5473 4.87255 8.3998 5.22865 8.3998 5.59995C8.3998 5.97125 8.5473 6.32735 8.80986 6.5899C9.07241 6.85245 9.4285 6.99995 9.7998 6.99995H18.1998C18.5711 6.99995 18.9272 6.85245 19.1898 6.5899C19.4523 6.32735 19.5998 5.97125 19.5998 5.59995C19.5998 5.22865 19.4523 4.87255 19.1898 4.61C18.9272 4.34745 18.5711 4.19995 18.1998 4.19995H9.7998ZM5.5998 9.79995C5.5998 9.42865 5.7473 9.07255 6.00986 8.81C6.27241 8.54745 6.6285 8.39995 6.9998 8.39995H20.9998C21.3711 8.39995 21.7272 8.54745 21.9898 8.81C22.2523 9.07255 22.3998 9.42865 22.3998 9.79995C22.3998 10.1713 22.2523 10.5273 21.9898 10.7899C21.7272 11.0525 21.3711 11.2 20.9998 11.2H6.9998C6.6285 11.2 6.27241 11.0525 6.00986 10.7899C5.7473 10.5273 5.5998 10.1713 5.5998 9.79995ZM2.7998 15.4C2.7998 14.6573 3.0948 13.9452 3.61991 13.4201C4.14501 12.895 4.8572 12.6 5.5998 12.6H22.3998C23.1424 12.6 23.8546 12.895 24.3797 13.4201C24.9048 13.9452 25.1998 14.6573 25.1998 15.4V21C25.1998 21.7426 24.9048 22.4547 24.3797 22.9798C23.8546 23.505 23.1424 23.8 22.3998 23.8H5.5998C4.8572 23.8 4.14501 23.505 3.61991 22.9798C3.0948 22.4547 2.7998 21.7426 2.7998 21V15.4Z"
            fill="#BBD1D8"
            fill-opacity="0.64"
          />
        </svg>

        <p class="menu-btn-text">Admin Management</p>
      </button>
    </div>
    <button class="menu-button" @click="logout">Logout</button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import defaultPfp from '../../src/assets/default_discord_pfp.png';
import { validatePagePermissions } from '@/utils/permissions'; // Adjust the path as needed

export default {
  name: 'Menu',
  data() {
    return {};
  },

  computed: {
    ...mapState(['user', 'activeTab']),
    avatarUrl() {
      if (!this.user || !this.user.id || !this.user.avatar) {
        return defaultPfp;
      }
      return `https://cdn.discordapp.com/avatars/${this.user.id}/${this.user.avatar}.png`;
    }
  },

  methods: {
    switchTab(tabName, required_permission= null) {

      if (required_permission === null) {
        this.$store.commit('setActiveTab', tabName);
        this.$emit('change-tab', tabName);
        return;
      }
      
      let default_error = "Failed to validate permissions";
      try {

        let user_has_permission = validatePagePermissions(required_permission, this.$store);
        if (!user_has_permission) {
        return 
      }

      } catch (error) {
        return this.$store.commit('setStatusMessage', {
          text: default_error,
          type: 'error',
        });
      }

      this.$store.commit('setActiveTab', tabName);
      this.$emit('change-tab', tabName);
    },

    logout() {
      this.$store.dispatch('logout');
    },
  },
};
</script>

<style scoped>
.menu {
  left: 0;
  top: 60px; /* Adjust this value to the height of your navbar */
  height: 100%; /* Subtract the height of the navbar from the height of the menu */
  width: 287px; /* Set a width for the menu */
  background: #090f18;

  padding: 8px 24px;
}

.menu-flex-container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 0 16px;
}
.menu-button {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 12px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.menu-button .menu-btn-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 150%;
  /* identical to box height, or 24px */

  color: #bbd1d8;
}

.menu-button.active::before {
  content: ''; /* This is necessary for the pseudo-element to show */
  position: absolute;
  left: -12px; /* Position it 20px to the left of the button */
  top: 50%; /* Center it vertically */
  transform: translateY(
    -50%
  ); /* This is to perfectly center the dot, as top: 50% will put the top edge of the dot in the center */
  width: 4px; /* Adjust as needed */
  height: 4px; /* Adjust as needed */
  background-color: white;
  border-radius: 50%; /* Makes it a circle */
}

.menu-button.active .menu-btn-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 150%;
  /* identical to box height, or 24px */

  color: #ffffff;
}

.menu-button.active .menu-btn-img path {
  fill-opacity: 1;
  fill: #ffffff;
}

.welcome-container {
  /* Frame 22 */
  margin-bottom: 32px;

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px 12px 12px;
  gap: 16px;

  background: rgba(195, 218, 252, 0.08);
  border: 1px solid rgba(195, 238, 252, 0.16);
  border-radius: 12px;
}

.welcome-text {
  /* Welcome, Lucas */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 19px;

  color: #ffffff;
}
</style>
