import axios from 'axios';
import { createStore } from 'vuex';
import router from '../router/index';


const initialState = {
  websocket: null,
  websocketConnected: false,
  client_discord_server_id: null,
  commands_list: [],
  valid_nitrado_token: false,
  admin_details: {},
  linked_servers: [],
  dashboardHomeCacheTimestamp: null,
  apiUrl:
    process.env.VUE_APP_ENVIRONMENT === 'development'
      ? 'http://192.168.1.242:5000'
      : process.env.VUE_APP_API_URL,
  application_id:
    process.env.VUE_APP_ENVIRONMENT === 'development'
      ? '1161237266207875102'
      : process.env.VUE_APP_APPLICATION_ID,
  discordRedirectUrl: process.env.VUE_APP_ENVIRONMENT === 'development' ? `https://discord.com/oauth2/authorize?client_id=1161237266207875102&response_type=code&redirect_uri=http%3A%2F%2F192.168.1.242%3A8080&scope=identify+applications.commands.permissions.update+guilds` : `https://discord.com/oauth2/authorize?client_id=${process.env.VUE_APP_APPLICATION_ID}&response_type=code&redirect_uri=https%3A%2F%2Fdazza-dayz-bot.co.uk&scope=identify+guilds+applications.commands.permissions.update`,
  isAuthenticated: false,
  user: null,
  selected_discord_server_id: null,
  client_discord_server: null,
  discord_access_token: null,
  discord_refresh_token: null,
  activeTab: null,
  statusMessage: null,
  shopStatistics: null,
  lastCacheTimestamp: null,
  bannedItems: {
    banned_items: [],
    last_cache_timestamp: null,
  },
  gamblingConfigs: {
    configs: {},
    last_cache_timestamp: null,
  },
  placementPings: {
    placement_pings: [],
    last_cache_timestamp: null,
  },
  adminPermissions: {
    permissions: [],
    last_cache_timestamp: null,
  },
  cachedServers: [],
  cachedShopItems: {
    items: [],
    last_cache_timestamp: null,
  },

  cachedTaskSchedules: {
    schedules: []
  },
  cachedHeatmapSchedule: {
    schedule: {},
    lastCacheTimestamp: null,
  },
  cachedInDepthLogsSchedule: {
    schedule: {},
    lastCacheTimestamp: null,
},
cachedLeaderboardsTaskSchedule: {
  schedule: {},
  lastCacheTimestamp: null,
},
cachedRolesAndChannels: {
  roles: [],
  channels: [],
  lastCacheTimestamp: null,
}
};

export default createStore({
  state: {
    websocket: null,
    websocketConnected: false,
    client_discord_server_id: null,
    commands_list: [],
    valid_nitrado_token: false,
    admin_details: {},
    linked_servers: [],
    dashboardHomeCacheTimestamp: null,
    apiUrl:
      process.env.VUE_APP_ENVIRONMENT === 'development'
        ? 'http://192.168.1.242:5000'
        : process.env.VUE_APP_API_URL,
    application_id:
      process.env.VUE_APP_ENVIRONMENT === 'development'
        ? '1161237266207875102'
        : process.env.VUE_APP_APPLICATION_ID,

    discordRedirectUrl: process.env.VUE_APP_ENVIRONMENT === 'development' ? `https://discord.com/oauth2/authorize?client_id=1161237266207875102&response_type=code&redirect_uri=http%3A%2F%2F192.168.1.242%3A8080&scope=identify+applications.commands.permissions.update+guilds` : `https://discord.com/oauth2/authorize?client_id=${process.env.VUE_APP_APPLICATION_ID}&response_type=code&redirect_uri=https%3A%2F%2Fdazza-dayz-bot.co.uk&scope=identify+guilds+applications.commands.permissions.update`,
    isAuthenticated: false,
    user: null,
    selected_discord_server_id: null,
    client_discord_server: null,
    discord_access_token: null,
    discord_refresh_token: null,
    activeTab: null,
    statusMessage: null,
    shopStatistics: null,
    lastCacheTimestamp: null,
    bannedItems: {
      banned_items: [],
      last_cache_timestamp: null,
    },

    gamblingConfigs: {
      configs: {},
      last_cache_timestamp: null,
    },
    placementPings: {
      placement_pings: [],
      last_cache_timestamp: null,
    },
    adminPermissions: {
      permissions: [],
      last_cache_timestamp: null,
    },
    cachedServers: [],
    cachedShopItems: {
      items: [],
      last_cache_timestamp: null,
    },
    cachedTaskSchedules: {
      schedules: []
    },
    cachedHeatmapSchedule: {
      schedule: {},
      lastCacheTimestamp: null,
    },
    cachedInDepthLogsSchedule: {
      schedule: {},
      lastCacheTimestamp: null,
  },
  cachedLeaderboardsTaskSchedule: {
    schedule: {},
    lastCacheTimestamp: null,
  },
  cachedRolesAndChannels: {
    roles: [],
    channels: [],
    lastCacheTimestamp: null,
  }

},
  mutations: {
    updateCachedTaskSchedules(state, payload) {
      state.cachedTaskSchedules.schedules = payload;
      localStorage.setItem('cachedTaskSchedules', JSON.stringify(payload));
    },
    setCachedRolesAndChannels(state, payload) {
      state.cachedRolesAndChannels.roles = payload.roles;
      state.cachedRolesAndChannels.channels = payload.channels;
      state.cachedRolesAndChannels.lastCacheTimestamp = new Date();
      localStorage.setItem('cachedRolesAndChannels', JSON.stringify(payload));
    },
    setCachedLeaderboardsTaskSchedule(state, payload) {
      state.cachedLeaderboardsTaskSchedule.schedule = payload;
      state.cachedLeaderboardsTaskSchedule.lastCacheTimestamp = new Date();
      localStorage.setItem('setCachedLeaderboardsTaskSchedule', JSON.stringify(payload));
    },
    setCachedInDepthLogsSchedule(state, payload) {
      state.cachedInDepthLogsSchedule.schedule = payload;
      state.cachedInDepthLogsSchedule.lastCacheTimestamp = new Date();
      localStorage.setItem('cachedInDepthLogsSchedule', JSON.stringify(payload));
    },
    setCachedHeatmapSchedule(state, payload) {
      console.log(payload);
      console.log('setting cached heatmap schedule');
      state.cachedHeatmapSchedule.schedule = payload;
      state.cachedHeatmapSchedule.lastCacheTimestamp = new Date();
      localStorage.setItem('cachedHeatmapSchedule', JSON.stringify(payload));
    },

    setClientDiscordServer(state, discord_server_id) {
      state.client_discord_server_id = discord_server_id;
      localStorage.setItem('client_discord_server_id', JSON.stringify(`${discord_server_id}`));
    },
    setCommandsList(state, commands_list) {
      state.commands_list = commands_list;
      localStorage.setItem('commands_list', JSON.stringify(commands_list));
    },
    setNitradoTokenStatus(state, value) {
      state.valid_nitrado_token = value;
      localStorage.setItem('valid_nitrado_token', value);
    },
    setAdminDetails(state, admin_details) {
      state.admin_details = admin_details;
      localStorage.setItem('admin_details', JSON.stringify(admin_details));
    },
    setLinkedServers(state, linked_servers) {
      state.linked_servers = linked_servers;
      localStorage.setItem('linked_servers', JSON.stringify(linked_servers));
    },
    updateDashboardHomeCacheTimestamp(state, timestamp) {
      state.dashboardHomeCacheTimestamp = timestamp;
      localStorage.setItem('dashboardHomeCacheTimestamp', timestamp);
    },
    setWebsocket(state, websocket) {
      state.websocket = websocket;
      state.websocketConnected = !!websocket; // Update connection status
    },
    setGamblingConfigs(state, payload) {
      state.gamblingConfigs = payload
      localStorage.setItem('gamblingConfigs', JSON.stringify(payload));
    },

    setPlacementPings(state, payload) {
      state.placementPings = payload;
      localStorage.setItem('placementPings', JSON.stringify(payload));
    },

    setStatusMessage(state, message) {
      state.statusMessage = message;
    },

    setBannedItems(state, payload) {
      state.bannedItems = {
        banned_items: payload,
        last_cache_timestamp: new Date(),
      }
      localStorage.setItem('bannedItems', JSON.stringify(payload));
    },
    setShopStatistics(state, payload) {
      state.shopStatistics = payload;
      localStorage.setItem('shopStatistics', JSON.stringify(payload));
    },
    setAuthenticated(state, value) {
      state.isAuthenticated = value;
      localStorage.setItem('isAuthenticated', value);
    },
    setUser(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    setSelectedDiscordServerID(state, value) {
      state.selected_discord_server_id = value;
      localStorage.setItem('selected_discord_server_id', JSON.stringify(value));
    },

   
    setDiscordAccessToken(state, value) {
      state.discord_access_token = value;
      localStorage.setItem('discord_access_token', JSON.stringify(value));
    },
    setDiscordRefreshToken(state, value) {
      state.discord_refresh_token = value;
      localStorage.setItem('discord_refresh_token', JSON.stringify(value));
    },
    setActiveTab(state, value) {
      state.activeTab = value;
      localStorage.setItem('activeTab', JSON.stringify(value));
    },

    updateShopOpen(state, value) {
      state.shopStatistics.shop_open = value;
      localStorage.setItem('shopStatistics', JSON.stringify(state.shopStatistics));
    },

    resetState(state) {
      Object.assign(state, initialState);
  },

    setAdminPermissions(state, value) {
    
      state.adminPermissions = value;
     
      localStorage.setItem('adminPermissions', JSON.stringify(value));
    },

    addCachedServer(state, value) {
      let data = {
        cached_timestamp: new Date(),
        ...value,
      }
      state.cachedServers.push(data);
      localStorage.setItem('cachedServers', JSON.stringify(state.cachedServers));
    },

    updateCachedServers(state, value) {
  
      state.cachedServers = value;
      
      localStorage.setItem('cachedServers', JSON.stringify(value));
    },

    updateCachedShopItems(state, value) {
      state.cachedShopItems = value;
      localStorage.setItem('cachedShopItems', JSON.stringify(value));
    },
   
},
  actions: {
    connectWebSocket({ commit, dispatch }) {
      let websocketURL = process.env.VUE_APP_ENVIRONMENT === 'development' ? 'ws://localhost:8081' : 'wss://www.dazza-dayz-bot.co.uk/websocket';
      const websocket = new WebSocket(websocketURL);

      websocket.onopen = () => {
        
        commit('setWebsocket', websocket);
      };

      websocket.onmessage = (event) => {
        const handleMessage = (data) => {
          try {
            const message = JSON.parse(data);
        
            if (message.discord_server_id !== this.state.client_discord_server_id) {
              return
            }
            if (message.event === 'update_shop_items') {
              commit('updateCachedShopItems', message.data);
            }
            if (message.event == 'updated_linked_servers') {
              
              // we need to update the store with the new server length
              commit('setLinkedServers', message.data);
            }
  
            if (message.event == 'update_cached_servers') {
              
              commit('updateCachedServers', message.data);
            }

            if (message.event == 'update_banned_items') {
              commit('setBannedItems', message.data);
            }

            if (message.event == 'update_gambling_configs') {
              commit('setGamblingConfigs', message.data);
            }

            if (message.event == 'update_placement_pings') {
              commit('setPlacementPings', message.data);
            }

            if (message.event == 'updatingCache') {
              console.log('updating cache');
              commit(message.action, message.data);
            }


            if (message.event == 'logout_admin' && message.admin_discord_id == this.state.admin_details.discord_admin_id) {
            
              dispatch('logout');
            }
        
          } catch (error) {
            return
          }
        };
      
        if (event.data instanceof Blob) {
          const reader = new FileReader();
          reader.onload = (e) => handleMessage(e.target.result);
          reader.readAsText(event.data);
        } else {
          handleMessage(event.data);
        }
      };

      websocket.onclose = () => {
        
        commit('setWebsocket', null);

        // Attempt to reconnect after a delay
        setTimeout(() => {
          dispatch('connectWebSocket');
        }, 4000);
      };

      websocket.onerror = (error) => {
        console.error('WebSocket error:', error);
        websocket.close(); // Close the websocket if an error occurs
      };
    },

    setClientSessionDetails({commit, dispatch}, response_object) {
      
      commit('setClientDiscordServer', String(response_object.discord_server_id));
      commit('setCommandsList', response_object.commands_list);
      commit('setNitradoTokenStatus', response_object.valid_nitrado_token);
      commit('setAdminDetails', response_object.admin);
      commit('setLinkedServers', response_object.linked_servers);
      commit('updateDashboardHomeCacheTimestamp', new Date());   
    },

    login({ commit }, user, discord_access_token, discord_refresh_token) {
      // Perform login logic here
      commit('setAuthenticated', true);
      commit('setUser', user);
      commit('setDiscordAccessToken', discord_access_token);
      commit('setDiscordRefreshToken', discord_refresh_token);
    },
    resetStatusMessage({ commit }) {
      commit('setStatusMessage', null);
    },
    logout({ commit}) {
      // Perform logout logic here
  
      // set the state back to the initial state
      commit('resetState');
      localStorage.clear();
      // loop through the keys in the

      // send request to logout
      axios
        .post(`${this.state.apiUrl}/api/dashboard/logout`)
        .then(response => {})
        .catch(error => {});

      // send to /login route
      router.push({ name: 'Home' });
    },

    async ManageServer({ commit, dispatch }, selected_discord_server_id) {
      try {
        const response = await dispatch('sendRequest', {
          url: `${this.state.apiUrl}/api/dashboard/verify-client`,
          method: 'POST',
          data: { discord_server_id: selected_discord_server_id },
        });
        commit('setSelectedDiscordServerID', selected_discord_server_id);
        localStorage.setItem(
          'selected_discord_server_id',
          JSON.stringify(selected_discord_server_id)
        );
        return response;
      } catch (error) {
        // Perform some operation here if an error occurs in sendRequest

        return error;
      }
    },

    async sendRequest(
      { state, dispatch, commit },
      {
        url,
        method,
        data,
        params,
        extra_headers,
        show_message_on_success = true,
      }
    ) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + process.env.VUE_APP_API_ACCESS_TOKEN,
        ...extra_headers,
        // Add your specific headers here
      };

      const body = {
        // Add your specific body structure here
        ...data,
      };

      const requestParams = {
        // Add your specific params here
        ...params,
      };

      try {
        const response = await axios({
          url,
          method,
          headers,
          data: body,
          params: requestParams,
        });

        if (show_message_on_success) {
          commit('setStatusMessage', {
            text: response.data.data.message,
            type: 'success',
          });
        }
        return response.data.data;
      } catch (error) {
       
        // Handle the error here
        if (error.response && error.response.status === 401) {
          // update the status message
          commit('setStatusMessage', {
            text: 'Your session has expired. Please log in again.',
            type: 'error',
          });
          dispatch('logout');
        } else if (error.response && error.response.data) {
          commit('setStatusMessage', {
            text: error.response.data.reason,
            type: 'error',
          });
        } else {
          commit('setStatusMessage', {
            message: 'An Unexpected error occurred. Please try again.',
            type: 'error',
          });
        }
        // Don't return or rethrow the error
      }
    },

   
  },

  getters: {
    isAuthenticated: state => state.isAuthenticated,
    user: state => state.user,
  },

  plugins: [
    // Load the state from localStorage when the store is initialized
    store => {
      if (
        localStorage.getItem('isAuthenticated') &&
        localStorage.getItem('user')
      ) {
        store.commit(
          'setAuthenticated',
          JSON.parse(localStorage.getItem('isAuthenticated'))
        );
        store.commit('setUser', JSON.parse(localStorage.getItem('user')));
        store.commit(
          'setSelectedDiscordServerID',
          JSON.parse(localStorage.getItem('selected_discord_server_id'))
        );
        store.commit(
          'setDiscordAccessToken',
          JSON.parse(localStorage.getItem('discord_access_token'))
        );
        store.commit(
          'setDiscordRefreshToken',
          JSON.parse(localStorage.getItem('discord_refresh_token'))
        );
        store.commit(
          'setActiveTab',
          JSON.parse(localStorage.getItem('activeTab'))
        );
      }

      if (localStorage.getItem('shopStatistics')) {
        store.commit(
          'setShopStatistics',
          JSON.parse(localStorage.getItem('shopStatistics'))
        );
      }

      if (localStorage.getItem('lastCacheTimestamp')) {
        store.commit(
          'setLastCacheTimestamp',
          localStorage.getItem('lastCacheTimestamp')
        );

      }

      if (localStorage.getItem('bannedItems')) {
        store.commit(
          'setBannedItems',
          JSON.parse(localStorage.getItem('bannedItems'))
        );
      }

      if (localStorage.getItem('gamblingConfigs')) {
        store.commit(
          'setGamblingConfigs',
          JSON.parse(localStorage.getItem('gamblingConfigs'))
        );
      }

      if (localStorage.getItem('placementPings')) {
        store.commit(
          'setPlacementPings',
          JSON.parse(localStorage.getItem('placementPings'))
        );
    }

      if (localStorage.getItem('admin_permissions')) {
        store.commit(
          'setAdminPermissions',
          JSON.parse(localStorage.getItem('admin_permissions'))
        );
      }
      if (localStorage.getItem('cachedServers')) {
        store.commit(
          'updateCachedServers',
          JSON.parse(localStorage.getItem('cachedServers'))
        );
      }
      if (localStorage.getItem('cachedShopItems')) {
        store.commit(
          'updateCachedShopItems',
          JSON.parse(localStorage.getItem('cachedShopItems'))
        );
      }

      if (localStorage.getItem('commands_list')) {
        store.commit(
          'setCommandsList',
          JSON.parse(localStorage.getItem('commands_list'))
        );
      }
      if (localStorage.getItem('valid_nitrado_token')) {
        store.commit(
          'setNitradoTokenStatus',
          JSON.parse(localStorage.getItem('valid_nitrado_token'))
        );
      }

      if (localStorage.getItem('admin_details')) {
        store.commit(
          'setAdminDetails',
          JSON.parse(localStorage.getItem('admin_details'))
        );
      }

      if (localStorage.getItem('linked_servers')) {
        store.commit(
          'setLinkedServers',
          JSON.parse(localStorage.getItem('linked_servers'))
        );
      }

      if (localStorage.getItem('dashboardHomeCacheTimestamp')) {
        store.commit(
          'updateDashboardHomeCacheTimestamp',
          localStorage.getItem('dashboardHomeCacheTimestamp')
        );
      }

      if (localStorage.getItem('client_discord_server_id')) {
        store.commit(
          'setClientDiscordServer',
          JSON.parse(localStorage.getItem('client_discord_server_id'))
        );
      }

      if (localStorage.getItem('cachedHeatmapSchedule')) {
        store.commit(
          'setCachedHeatmapSchedule',
          JSON.parse(localStorage.getItem('cachedHeatmapSchedule'))
        );
      }

      if (localStorage.getItem('cachedInDepthLogsSchedule')) {
        store.commit(
          'setCachedInDepthLogsSchedule',
          JSON.parse(localStorage.getItem('cachedInDepthLogsSchedule'))
        );
      }

      if (localStorage.getItem('cachedLeaderboardsTaskSchedule')) {
        store.commit(
          'setCachedLeaderboardsTaskSchedule',
          JSON.parse(localStorage.getItem('cachedLeaderboardsTaskSchedule'))
        );
      }

      if (localStorage.getItem('cachedRolesAndChannels')) {
        store.commit(
          'setCachedRolesAndChannels',
          JSON.parse(localStorage.getItem('cachedRolesAndChannels'))
        );
      }

      if (localStorage.getItem('cachedTaskSchedules')) {
        store.commit(
          'updateCachedTaskSchedules',
          JSON.parse(localStorage.getItem('cachedTaskSchedules'))
        );
      }

  }
  ],

  modules: {},
});
