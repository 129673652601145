<template>
    <div class="admin-management-container">
      <div class="management-top">
        <h1 class="content-header">Admin Management</h1>
        <p class="content-header-sub">Manage Dashboard Admin Permissions</p>
      </div>
      <div class="management-lower">
        <button
        class="btn add-admin-btn"
        @click="openModal('Add')"
      >
        <p>Add Admin</p>
      </button>
      <button
        class="btn edit-admin-btn"
        @click="openModal('Edit')"
        :disabled="!currentAdminPermissions.length"
      >
        <p>Edit admin</p>
      </button>
        
        
      </div>
      <Modal :show="showModal" @update:show="showModal = $event">
      <template v-slot:header-content>
        <div class="header-content-container">
          <p class="modal-sub-heading">{{ selectedAdminUpdatedObject.discord_admin_id }}</p>
          <p class="modal-main-heading">{{modalHeader}} Admin</p>
          <div class="modal-heading-discord-id-validator" v-if="!validDiscordId && modalHeader == 'Add'">
            <input
              type="text"
              placeholder="Enter discord ID"
              class="modal-option-input validate-id-input"
              v-model="newAdminDiscordId"
            />
            <button class="btn validate-id-btn" @click="validateDiscordUser">
              
              <p class="btn-text">Validate</p>
            </button>
          </div>
          <div class="modal-heading-admin-selector" v-if="modalHeader == 'Edit' && currentAdminPermissions.length ">
              <select
              name=""
              id=""
              class="choose-admin-dropdown"
              v-model="selectedAdminUpdatedObject.discord_admin_id"
              @change="handleAdminChange"
              
            >
              <option disabled value="">Choose admin</option>
              <option
              v-for="admin in currentAdminPermissions"
              :key="admin.discord_admin_id"
              :value="admin.discord_admin_id"
              :disabled="admin.discord_admin_id === this.$store.state.admin_details.discord_admin_id"
              
            >
              {{ admin.discord_admin_id }}
            </option>
            </select>

            <div class="delete-btn" @click="deleteAdmin">
                <img src="../assets/TrashOutline.svg" alt="">
            </div>

          </div>
          
        </div>
      </template>
      <template v-slot:body-content v-if="(validDiscordId && modalHeader == 'Add') || (modalHeader == 'Edit' && selectedAdminUpdatedObject.discord_admin_id)">
        <div class="modal-body-container">
          <div class="modal-body-header">
            <p class="mb-heading">Permissions</p>
          </div>
          <div class="modal-body-permissions-container">
            <div
              v-for="permission in all_available_permsissions"
              :key="permission.id"
              class="modal-permission-select"
              :class="{
                'active-checked': isSelected(permission.id),
              }"
              @click="handleCheckboxChange(permission.id)"
            >
              <input
                type="checkbox"
                :id="permission.id"
                class="checkbox"
                :checked="isSelected(permission.id)"
                @click.stop="handleCheckboxChange(permission.id)"
                
              />
              <label :for="permission.id" class="checkbox-label"></label>
             
              <p class="permission-name">{{ permission.permission_name }}</p>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer-content>
        <div class="modal-footer-content-container">
          <button class="modal-btn cancel-btn" @click="closeModal">
            Cancel
          </button>
          <button class="modal-btn save-btn" @click="finishEdits">
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="btn-text">Done</p>
          </button>
        </div>
      </template>
    </Modal>
     
    
    
    
  
  
  
     
    </div>
  
  </template>


  
  <script>
  import { mapState } from 'vuex'
  import Modal from './Modal.vue'
  import { validatePagePermissions } from '@/utils/permissions'; // Adjust the path as needed

  export default {
    name: 'AdminManagement',
    components: {
      Modal
    },
    data() {
      return {
        showModal: false,
        modalHeader: '',
        newAdminDiscordId: '',
        validDiscordId: false,
        currentAdminPermissions: [],
        selectedAdminUpdatedObject: {
            'discord_admin_id': '',
            'admin_permissions': []
        },
        all_available_permsissions: []
          
      };
    },

    
  
    methods: {
        handleCheckboxChange(permission_id) {
           
            // Check if any of the permissions.permission_name matches the given permission
            const permissionIndex = this.selectedAdminUpdatedObject.admin_permissions.findIndex(p => p.id === permission_id);

            if (permissionIndex !== -1) {
                // If the permission is already in the list, remove it
                this.selectedAdminUpdatedObject.admin_permissions = JSON.parse(JSON.stringify(this.selectedAdminUpdatedObject.admin_permissions.filter(p => p.id !== permission_id)));
            } else {
                // get the actual permission object from all_available_permissions
                const permission = this.all_available_permsissions.find(p => p.id === permission_id);
                this.selectedAdminUpdatedObject.admin_permissions.push(permission);
            }
        },
        isSelected(permission_id) {
            if (!this.selectedAdminUpdatedObject.admin_permissions) {
                return false;
            }

            return this.selectedAdminUpdatedObject.admin_permissions.some(p => p.id === permission_id);
        },
      openModal(content) {

        this.modalHeader = content
          this.modalContent = content;
          
          if (content === 'Add') {
            let valid_perms = validatePagePermissions('admin_management.create', this.$store)
            if (!valid_perms) {
              return;
            }
            this.selectedAdmin = null;
            this.selectedAdminUpdatedObject = {
              'discord_admin_id': '',
              'admin_permissions': []
            }
          } else {
            let valid_perms = validatePagePermissions('admin_management.read', this.$store)
            if (!valid_perms) {
              return;
            }
          }
          this.showModal = true;
        },

        cacheNeedRefresh() {
            if (!this.$store.state.adminPermissions.last_cache_timestamp) {
                
                return true;
            }
            // Refresh cache every 5 minutes
            return Date.now() - this.$store.state.adminPermissions.last_cache_timestamp > 1000 * 60 * 5;
        },

        async fetchAdminPermissions() {
            let needRefresh = this.cacheNeedRefresh();
            if (!needRefresh) {
                this.currentAdminPermissions = this.$store.state.adminPermissions.permissions;
                return;
            }
            const response = await this.$store.dispatch('sendRequest', {
                method: 'GET',
                url: `${this.$store.state.apiUrl}/api/admin/admin_management`,
                show_message_on_success: false,
            });

           

            if (response) {
                response.admins.forEach(admin => {
                    admin.discord_admin_id = `${admin.discord_admin_id}`;
                });
            
                this.$store.commit('setAdminPermissions', {
                    permissions: response.admins,
                    last_cache_timestamp : Date.now()
                });

                // for each admin in response.admins we need to make the discord_admin_id a string

                

                this.currentAdminPermissions = response.admins;
            } 
        },

        async validateDiscordUser(){
            const response = await this.$store.dispatch('sendRequest', {
                method: 'GET',
                url: `${this.$store.state.apiUrl}/api/admin/admin_management/validate_discord_id`,
                params: {
                    discord_id: this.newAdminDiscordId
                },
                show_message_on_success: false,
            });

            if (response) {
                this.validDiscordId = true;
                this.selectedAdminUpdatedObject.discord_admin_id = `${this.newAdminDiscordId}`;
            }
        },

        async addNewAdmin() {
            const response = await this.$store.dispatch('sendRequest', {
                method: 'POST',
                url: `${this.$store.state.apiUrl}/api/admin/admin_management`,
                data: {
                    'discord_id': `${this.$store.state.admin_details.discord_admin_id}`,
                    'new_admin_discord_id': `${this.selectedAdminUpdatedObject.discord_admin_id}`,
                    'new_admin_permissions': this.selectedAdminUpdatedObject.admin_permissions.map(p => p.permission_name)
                },
                show_message_on_success: true,
            });

            if (response) {
               
                // grab the current admin permissions from the store, and update it with the new admin
                let currentAdminPermissions = JSON.parse(JSON.stringify(this.$store.state.adminPermissions.permissions));
                
                currentAdminPermissions.push(response.new_admin);
                this.$store.commit('setAdminPermissions', {
                    permissions: currentAdminPermissions,
                    last_cache_timestamp : Date.now()
                });

                this.currentAdminPermissions = currentAdminPermissions;
                this.closeModal()
            }
        },

        async handleAdminChange() {
            this.selectedAdminUpdatedObject = JSON.parse(JSON.stringify(this.currentAdminPermissions.find(admin => admin.discord_admin_id === this.selectedAdminUpdatedObject.discord_admin_id)));
          
        },

        async closeModal() {
            this.showModal = false;
            this.modalHeader = '';
            this.selectedAdminUpdatedObject = {
                'discord_admin_id': '',
                'admin_permissions': []
            }
            this.newAdminDiscordId = '';
            this.validDiscordId = false;
        },

        async updateAdmin() {
            const response = await this.$store.dispatch('sendRequest', {
                method: 'PUT',
                url: `${this.$store.state.apiUrl}/api/admin/admin_management`,
                data: {
                    'discord_id': `${this.$store.state.admin_details.discord_admin_id}`,
                    'admin_to_update_discord_id': `${this.selectedAdminUpdatedObject.discord_admin_id}`,
                    'permission_ids': this.selectedAdminUpdatedObject.admin_permissions.map(p => p.id)
                },
                show_message_on_success: true,
            });

            if (response) {
                // grab the current admin permissions from the store, and update it with the new admin
                let currentAdminPermissions = JSON.parse(JSON.stringify(this.$store.state.adminPermissions.permissions));
                // Assuming response contains the updated permissions and the discord_admin_id
                const updatedPermissions = response.updated_permissions;
                const updatedAdminId = this.selectedAdminUpdatedObject.discord_admin_id;

                // Update the permissions of the admin in the currentAdminPermissions array
                currentAdminPermissions = currentAdminPermissions.map(admin => {
                    if (admin.discord_admin_id === updatedAdminId) {
                        return {
                            ...admin,
                            admin_permissions: updatedPermissions
                        };
                    }
                    return admin;
                });
              this.$store.commit('setAdminPermissions', {
                    permissions: currentAdminPermissions,
                    last_cache_timestamp : Date.now()
                });

                this.currentAdminPermissions = currentAdminPermissions;

                this.closeModal()

                const websocket_message = {
                  event: 'logout_admin',
                  discord_server_id: this.$store.state.client_discord_server_id,
                  admin_discord_id: updatedAdminId,
                  data: {}
                }
                this.$store.state.websocket.send(
                  JSON.stringify(websocket_message)
                );
               
            }
        },

        async deleteAdmin() {
          let valid_perms = validatePagePermissions('admin_management.delete', this.$store)
          if (!valid_perms) {
            return;
          }
            if (!this.selectedAdminUpdatedObject.discord_admin_id) {
                return;
            }
            const response = await this.$store.dispatch('sendRequest', {
                method: 'DELETE',
                url: `${this.$store.state.apiUrl}/api/admin/admin_management`,
                data: {
                    'discord_id': `${this.$store.state.admin_details.discord_admin_id}`,
                    'admin_to_delete_discord_id': `${this.selectedAdminUpdatedObject.discord_admin_id}`
                },
                show_message_on_success: true,
            });

            if (response) {
                // grab the current admin permissions from the store, and update it with the new admin
                let currentAdminPermissions = JSON.parse(JSON.stringify(this.$store.state.adminPermissions.permissions));
                
                const updatedAdminId = this.selectedAdminUpdatedObject.discord_admin_id;

                // Update the permissions of the admin in the currentAdminPermissions array
                currentAdminPermissions = currentAdminPermissions.filter(admin => admin.discord_admin_id !== updatedAdminId);
                this.$store.commit('setAdminPermissions', {
                        permissions: currentAdminPermissions,
                        last_cache_timestamp : Date.now()
                    });

                this.currentAdminPermissions = currentAdminPermissions;

                this.closeModal()
               
            }

        },

        async finishEdits(){
            if (this.modalHeader === 'Add') {
                this.addNewAdmin();
            } else {
                this.updateAdmin();
            }
        },

        async getAllAvailablePermissions() {
            const response = await this.$store.dispatch('sendRequest', {
                method: 'GET',
                url: `${this.$store.state.apiUrl}/api/admin/admin_management/permissions`,
                show_message_on_success: false,
            });

            if (response) {
                // sort it by alphabetical order using permission.permission_name and set it to all_available_permissions, the sorted list
                let sorted_permissions = response.permissions.sort((a, b) => a.permission_name.localeCompare(b.permission_name));
                this.all_available_permsissions = sorted_permissions
            }
        },
    },

        mounted() {
           
            this.getAllAvailablePermissions()
            this.fetchAdminPermissions();
        },
    }
  </script>
  
  <style scoped>
  .admin-management-container {
  background: rgba(187, 218, 255, 0.04);
  border: 1px solid rgba(187, 218, 255, 0.08);
  border-radius: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  text-align: left;
}

.management-lower {
    display: flex;
    gap: 12px;
    margin-top: 24px;
}

.edit-admin-btn {
   
    background: #c3dafc29;
    border: none;
    cursor: pointer;
   
    color: #bbced8;
    
}

.modal-permission-select {
  padding: 12px;
  border-radius: 8px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: 'DM Mono';
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(187, 206, 216, 1);
}

.modal-body-container {
  height: 350px;
}
.modal-body-permissions-container {
  margin-top: 8px;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding-right: 8px;
}
.modal-body-permissions-container::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.modal-body-permissions-container::-webkit-scrollbar-track {
  background: #1d2632;
  border-radius: 12px;
}

/* Handle */
.modal-body-permissions-container::-webkit-scrollbar-thumb {
  background: rgba(187, 218, 255, 0.04);

  border-radius: 12px;
}

/* Handle on hover */
.modal-body-permissions-container::-webkit-scrollbar-thumb:hover {
  background: rgba(187, 218, 255, 0.04);
}

.active-checked {
  background: rgba(195, 218, 252, 0.08);
}
.checkbox {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #bbced8a3;
  border-radius: 4px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-label {
  width: 16px;
  height: 16px;
  border: 1px solid #bbced8a3;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.checkbox:checked + .checkbox-label {
  background: #42d09c3d;

  border: 2px solid #42d09ca3;
  background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M13.3657%204.23447C13.5157%204.3845%2013.5999%204.58794%2013.5999%204.80007C13.5999%205.0122%2013.5157%205.21565%2013.3657%205.36567L6.96568%2011.7657C6.81566%2011.9156%206.61221%2011.9999%206.40008%2011.9999C6.18795%2011.9999%205.9845%2011.9156%205.83448%2011.7657L2.63448%208.56567C2.48876%208.41479%202.40812%208.21271%202.40994%208.00295C2.41177%207.7932%202.4959%207.59255%202.64423%207.44422C2.79255%207.29589%202.9932%207.21176%203.20296%207.20993C3.41272%207.20811%203.6148%207.28875%203.76568%207.43447L6.40008%2010.0689L12.2345%204.23447C12.3845%204.0845%2012.588%204.00024%2012.8001%204.00024C13.0122%204.00024%2013.2157%204.0845%2013.3657%204.23447Z%22%20fill%3D%22%2342D09C%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: center;
}

.modal-heading-discord-id-validator, .modal-heading-admin-selector {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    align-items: center
}

.modal-heading-discord-id-validator {
    gap: 16px;
}

.validate-id-btn{
    font-size: 1.2rem ;

    padding: 6px 12px;
}

.validate-id-input {
    padding: 6px 12px;
}

.mb-heading {
  font-family: Inter;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(187, 206, 216, 1);
}

.choose-admin-dropdown {
  /* Frame 171 */

  box-sizing: border-box;

  display: inline-block;
  padding: 6px 16px;
  gap: 16px;

  max-width: 150px;
  

  border: 1px solid rgba(195, 238, 252, 0.16);
  border-radius: 12px;

  width: 100%;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  /* identical to box height, or 21px */

  color: #ffffff;
  appearance: none; /* Remove default arrow */
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><path d="M12.6666 6.5L7.99992 11.1667L3.33325 6.5" stroke="white" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-position: right 16px center; /* Position it 16px from the right edge */
  padding-right: 36px; /* Add padding to prevent the text from overlapping the arrow */
  cursor: pointer;
  background-color: rgba(195, 218, 252, 0.08);
}

.choose-admin-dropdown:focus {
  outline: none;
}

.choose-admin-dropdown option {
  color: black;
}



@media (max-width: 500px) {
    .management-lower {
        flex-direction: column;
    }
  
    
}
  </style>
  