export function validatePagePermissions(required_permission, store) {
  if (store.state.client_discord_server_id && !store.state.valid_nitrado_token) {
    store.commit('setStatusMessage', {
      text: 'Please Link Nitrado Token',
      type: 'error',
    });
    return false;
  }

  // Check if the Discord server information is available and the user is an admin
  if (store.state.client_discord_server_id) {
    if (store.state.admin_details.is_owner) {
      return true;
    }
    const admins_permissions = store.state.admin_details.allowed_permissions;
    if (admins_permissions.includes(required_permission)) {
      return true;
    } else {
      store.commit('setStatusMessage', {
        text: 'You do not have permission to perform this action',
        type: 'error',
      });
      return false;
    }
  }

  store.commit('setStatusMessage', {
    text: 'You do not have permission to perform this action',
    type: 'error',
  });
  return false;
}