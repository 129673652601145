<template>
  <div class="gambling-management-container">
    <div class="management-top">
      <h1 class="content-header">Gambling Management</h1>
      <p class="content-header-sub">Manage the gambling system</p>
    </div>
    <div class="management-lower">
      <button class="management-btn" @click="openModal('global')">Global Configuration</button>
      <button class="management-btn" @click="openModal('slots')">Slots</button>
      <button class="management-btn" @click="openModal('rps')">RPS</button>
      <button class="management-btn" @click="openModal('roulette')">Roulette</button>
      <button class="management-btn" @click="openModal('blackjack')">Blackjack</button>
      <button class="management-btn" @click="openModal('coinflip')">CoinFlip</button>
      <button class="management-btn" @click="openModal('p2p-coinflip')">P2P CoinFlip</button>
      <button class="management-btn" @click="openModal('p2p-highest-card')">P2P Highest Card</button>
    </div>




    <Modal :show="showModal" @update:show="showModal = $event">
        <template v-slot:header-content>
            <div class="header-content-container">
                <p class="modal-sub-heading">{{ modalContent }}</p>
          <p class="modal-main-heading">Edit Configuration</p>
        </div>
        </template>
        <template v-slot:body-content>
           <div class="global-config" v-if="modalContent == 'global'">
            <div class="modal-row">
                <div class="modal-option">
              <label for="gambling_enabled" class="">Allow Gambling</label>
              <select
                name=""
                class="dropdown"
                v-model="gamblingConfigsCopy.enabled"
              >
                <option :value="false">False</option>
                <option :value="true">True</option>
              </select>
            </div>

            </div>
        </div>

        <div class="slots-config" v-if="modalContent == 'slots'">
            <div class="modal-row">
                <div class="modal-option">
              <label for="slots_enabled" class="">Allow Slots</label>
              <select
                name=""
                class="dropdown"
                v-model="gamblingConfigsCopy.slots_config.enabled"
              >
                <option :value="false">False</option>
                <option :value="true">True</option>
              </select>
            </div>

            <div class="modal-option">
                <label for="slots_min_bet" class="">Minimum Bet</label>
                <input
                    type="number"
                    class="modal-option-input"
                    v-model="gamblingConfigsCopy.slots_config.min_bet"
                />
            </div>
            </div>
            <div class="modal-row">
            <div class="modal-option">
                <label for="slots_max_bet" class="">Maximum Bet</label>
                <input
                    type="number"
                    class="modal-option-input"
                    v-model="gamblingConfigsCopy.slots_config.max_bet"
                />
                </div>
            <div class="modal-option">
                <label for="slots_win_chance" class="">Win Chance</label>
                <input
                    type="number"

                    class="modal-option-input"
                    v-model="gamblingConfigsCopy.slots_config.win_chance"
                />
                </div>
            </div>
        </div>
        <div class="rps-config" v-if="modalContent == 'rps'">
            <div class="modal-row">
                <div class="modal-option">
              <label for="rps_enabled" class="">Allow </label>
              <select
                name=""
                class="dropdown"
                v-model="gamblingConfigsCopy.rps_config.enabled"
              >
                <option :value="false">False</option>
                <option :value="true">True</option>
              </select>
            </div>

            <div class="modal-option">
                <label for="rps_min_bet" class="">Minimum Bet</label>
                <input
                    type="number"
                    class="modal-option-input"
                    v-model="gamblingConfigsCopy.rps_config.min_bet"
                />
            </div>
            </div>
            <div class="modal-row">
            <div class="modal-option">
                <label for="rps_max_bet" class="">Maximum Bet</label>
                <input
                    type="number"
                    class="modal-option-input"
                    v-model="gamblingConfigsCopy.rps_config.max_bet"
                />
                </div>
            <div class="modal-option">
                <label for="rps_win_chance" class="">Win Chance</label>
                <input
                    type="number"

                    class="modal-option-input"
                    v-model="gamblingConfigsCopy.rps_config.win_chance"
                />
                </div>
            </div>
        </div>
        <div class="roulette-config" v-if="modalContent == 'roulette'">
            <div class="modal-row">
                <div class="modal-option">
              <label for="roulette_enabled" class="">Allow </label>
              <select
                name=""
                class="dropdown"
                v-model="gamblingConfigsCopy.roulette_config.enabled"
              >
                <option :value="false">False</option>
                <option :value="true">True</option>
              </select>
            </div>

            <div class="modal-option">
                <label for="roulette_min_bet" class="">Minimum Bet</label>
                <input
                    type="number"
                    class="modal-option-input"
                    v-model="gamblingConfigsCopy.roulette_config.min_bet"
                />
            </div>
            </div>
            <div class="modal-row">
            <div class="modal-option">
                <label for="roulette_max_bet" class="">Maximum Bet</label>
                <input
                    type="number"
                    class="modal-option-input"
                    v-model="gamblingConfigsCopy.roulette_config.max_bet"
                />
                </div>
            <div class="modal-option">
                <label for="roulette_win_chance" class="">Win Chance</label>
                <input
                    type="number"

                    class="modal-option-input"
                    v-model="gamblingConfigsCopy.roulette_config.win_chance"
                />
                </div>
            </div>
        </div>
        <div class="blackjack-config" v-if="modalContent == 'blackjack'">
            <div class="modal-row">
                <div class="modal-option">
              <label for="blackjack_enabled" class="">Allow</label>
              <select
                name=""
                class="dropdown"
                v-model="gamblingConfigsCopy.blackjack_config.enabled"
              >
                <option :value="false">False</option>
                <option :value="true">True</option>
              </select>
            </div>

            <div class="modal-option">
                <label for="blackjack_min_bet" class="">Minimum Bet</label>
                <input
                    type="number"
                    class="modal-option-input"
                    v-model="gamblingConfigsCopy.blackjack_config.min_bet"
                />
            </div>
            </div>
            <div class="modal-row">
            <div class="modal-option">
                <label for="blackjack_max_bet" class="">Maximum Bet</label>
                <input
                    type="number"
                    class="modal-option-input"
                    v-model="gamblingConfigsCopy.blackjack_config.max_bet"
                />
                </div>
            <div class="modal-option">
                <label for="blackjack_win_chance" class="">Win Chance</label>
                <input
                    type="number"

                    class="modal-option-input"
                    v-model="gamblingConfigsCopy.blackjack_config.win_chance"
                />
                </div>
            </div>
        </div>
        <div class="coinflip-config" v-if="modalContent == 'coinflip'">
            <div class="modal-row">
                <div class="modal-option">
              <label for="coinflip_enabled" class="">Allow</label>
              <select
                name=""
                class="dropdown"
                v-model="gamblingConfigsCopy.coin_flip_config.enabled"
              >
                <option :value="false">False</option>
                <option :value="true">True</option>
              </select>
            </div>

            <div class="modal-option">
                <label for="coinflip_min_bet" class="">Minimum Bet</label>
                <input
                    type="number"
                    class="modal-option-input"
                    v-model="gamblingConfigsCopy.coin_flip_config.min_bet"
                />
            </div>
            </div>
            <div class="modal-row">
            <div class="modal-option">
                <label for="coinflip_max_bet" class="">Maximum Bet</label>
                <input
                    type="number"
                    class="modal-option-input"
                    v-model="gamblingConfigsCopy.coin_flip_config.max_bet"
                />
                </div>
            <div class="modal-option">
                <label for="coinflip_win_chance" class="">Win Chance</label>
                <input
                    type="number"

                    class="modal-option-input"
                    v-model="gamblingConfigsCopy.coin_flip_config.win_chance"
                />
                </div>
            </div>
        </div>
        <div class="coinflip-config" v-if="modalContent == 'p2p-coinflip'">
            <div class="modal-row">
                <div class="modal-option">
              <label for="coinflip_enabled" class="">Allow</label>
              <select
                name=""
                class="dropdown"
                v-model="gamblingConfigsCopy.player_to_player_coin_flip_config.enabled"
              >
                <option :value="false">False</option>
                <option :value="true">True</option>
              </select>
            </div>

            <div class="modal-option">
                <label for="coinflip_min_bet" class="">Minimum Bet</label>
                <input
                    type="number"
                    class="modal-option-input"
                    v-model="gamblingConfigsCopy.player_to_player_coin_flip_config.min_bet"
                />
            </div>
            </div>
            <div class="modal-row">
            <div class="modal-option">
                <label for="coinflip_max_bet" class="">Maximum Bet</label>
                <input
                    type="number"
                    class="modal-option-input"
                    v-model="gamblingConfigsCopy.player_to_player_coin_flip_config.max_bet"
                />
                </div>
            </div>
        </div>
        <div class="coinflip-config" v-if="modalContent == 'p2p-highest-card'">
            <div class="modal-row">
                <div class="modal-option">
              <label for="coinflip_enabled" class="">Allow</label>
              <select
                name=""
                class="dropdown"
                v-model="gamblingConfigsCopy.player_to_player_highest_card_config.enabled"
              >
                <option :value="false">False</option>
                <option :value="true">True</option>
              </select>
            </div>

            <div class="modal-option">
                <label for="coinflip_min_bet" class="">Minimum Bet</label>
                <input
                    type="number"
                    class="modal-option-input"
                    v-model="gamblingConfigsCopy.player_to_player_highest_card_config.min_bet"
                />
            </div>
            </div>
            <div class="modal-row">
            <div class="modal-option">
                <label for="coinflip_max_bet" class="">Maximum Bet</label>
                <input
                    type="number"
                    class="modal-option-input"
                    v-model="gamblingConfigsCopy.player_to_player_highest_card_config.max_bet"
                />
                </div>
            </div>
        </div>
        </template>
        <template v-slot:footer-content>
        <div class="modal-footer-content-container">
          <button class="modal-btn cancel-btn" @click="discardChanges">
            Discard
          </button>
          <button class="modal-btn save-btn" @click="updateChanges" :disabled="!updateBtnEnabled">
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="btn-text">Done</p>
          </button>
        </div>
      </template>
    </Modal>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import Modal from './Modal.vue'
export default {
  name: 'GamblingManagement',
  components: {
    Modal
  },
  data() {
    return {
        showModal: false,
        modalHeader: '',
        modalContent: 'Global',
        gamblingConfigsOriginal: {},
        gamblingConfigsCopy: {},
        updateBtnEnabled: true
    };
  },

  methods: {
    openModal(content) {
        this.modalContent = content;
        this.showModal = true;
        },

    async loadGamblingConfigs() {
        let currentConfig = this.$store.state.gamblingConfigs.configs;

        const fetchAndUpdateConfigs = async () => {
            const response = await this.$store.dispatch('sendRequest', {
            method: 'GET',
            url: `${this.$store.state.apiUrl}/api/gambling/config`,
            show_message_on_success: false,
            });

            if (response) {
            this.$store.commit('setGamblingConfigs', {
                configs: response,
                last_cache_timestamp: new Date()
            });
            this.gamblingConfigsOriginal = response;
            this.gamblingConfigsCopy = JSON.parse(JSON.stringify(response));
            }
        };

        // Check if the cache is empty
        if (Object.keys(currentConfig).length === 0) {
        
            await fetchAndUpdateConfigs();
        } else {
            // Check if the cache needs to be updated
            let lastCache = this.$store.state.gamblingConfigs.last_cache_timestamp;
            lastCache = lastCache ? new Date(lastCache) : new Date();
            let currentTime = new Date();
            let timeDiff = currentTime - lastCache;

            if (timeDiff > 600000) {
       
            await fetchAndUpdateConfigs();
            } else {
            this.gamblingConfigsOriginal = currentConfig;
            this.gamblingConfigsCopy = JSON.parse(JSON.stringify(currentConfig));
            }
        }
        },

    discardChanges() {
        this.gamblingConfigsCopy = JSON.parse(JSON.stringify(this.gamblingConfigsOriginal));
        this.showModal = false;
    },
    getChangedKeyValuePairs(changdObject, originalObject) {
      let changedValues = {};
      for (let key in changdObject) {
        if (changdObject[key] !== originalObject[key]) {
          changedValues[key] = changdObject[key];
        }
        // if its an empty string, set it to null
        if (changedValues[key] === '') {
          changedValues[key] = null;
        }
      }
      return changedValues;
    },

    async updateGlobalConfig() {

        this.updateBtnEnabled = false;

        let changedConfig = JSON.parse(JSON.stringify(this.gamblingConfigsCopy));
        // the only key in the object is 'enabled', so check if its been changed, if it hasnt just return
        if (changedConfig.enabled == this.gamblingConfigsOriginal.enabled) {
            return;
        }

        const response = await this.$store.dispatch('sendRequest', {
            method: 'PATCH',
            url: `${this.$store.state.apiUrl}/api/gambling/config`,
            data: {'enabled': changedConfig.enabled},
            show_message_on_success: true,
        });

        if (response) {
            this.updateCachedConfig(response);
            this.gamblingConfigsOriginal = response.config;
            this.gamblingConfigsCopy = JSON.parse(JSON.stringify(response.config));
        }
        this.showModal = false;
        this.updateBtnEnabled = true;
    },

    async updateSlotsConfig() {
        this.updateBtnEnabled = false;

        let changed_config = this.getChangedKeyValuePairs(this.gamblingConfigsCopy.slots_config, this.gamblingConfigsOriginal.slots_config);

        const response = await this.$store.dispatch('sendRequest', {
            method: 'PATCH',
            url: `${this.$store.state.apiUrl}/api/gambling/config`,
            data: {
                'slots_config': changed_config,
            },
            show_message_on_success: true,
        });

        if (response) {
            this.updateCachedConfig(response);
            this.gamblingConfigsOriginal = response.config;
            this.gamblingConfigsCopy = JSON.parse(JSON.stringify(response.config));
        }

        this.showModal = false;
        this.updateBtnEnabled = true;
    },

    async updateRPSConfig() {
        this.updateBtnEnabled = false;

        let changedConfig = this.getChangedKeyValuePairs(this.gamblingConfigsCopy.rps_config, this.gamblingConfigsOriginal.rps_config);

        const response = await this.$store.dispatch('sendRequest', {
            method: 'PATCH',
            url: `${this.$store.state.apiUrl}/api/gambling/config`,
            data: {
                'rps_config': changedConfig,
            },
            show_message_on_success: true,
        });

        if (response) {
            this.updateCachedConfig(response);

            this.gamblingConfigsOriginal = response.config;
            this.gamblingConfigsCopy = JSON.parse(JSON.stringify(response.config));


        }

        this.showModal = false;
        this.updateBtnEnabled = true;
    },

    async updateRouletteConfig() {
        this.updateBtnEnabled = false;

        let changedConfig = this.getChangedKeyValuePairs(this.gamblingConfigsCopy.roulette_config, this.gamblingConfigsOriginal.roulette_config);



        const response = await this.$store.dispatch('sendRequest', {
            method: 'PATCH',
            url: `${this.$store.state.apiUrl}/api/gambling/config`,
            data: {
                'roulette_config': changedConfig,
            },
            show_message_on_success: true,
        });

        if (response) {
            this.updateCachedConfig(response);
            
            this.gamblingConfigsOriginal = response.config;
            this.gamblingConfigsCopy = JSON.parse(JSON.stringify(response.config));
        }

        this.showModal = false;
        this.updateBtnEnabled = true;
    },

    async updateBlackjackConfig() {
        this.updateBtnEnabled = false;

        let changedConfig = this.getChangedKeyValuePairs(this.gamblingConfigsCopy.blackjack_config, this.gamblingConfigsOriginal.blackjack_config);

        const response = await this.$store.dispatch('sendRequest', {
            method: 'PATCH',
            url: `${this.$store.state.apiUrl}/api/gambling/config`,
            data: {
                'blackjack_config': changedConfig,
            },
            show_message_on_success: true,
        });

        if (response) {
            this.updateCachedConfig(response);


            this.gamblingConfigsOriginal = response.config;
            this.gamblingConfigsCopy = JSON.parse(JSON.stringify(response.config));
        }

        this.showModal = false;
        this.updateBtnEnabled = true;
    },

    async updateCoinFlipConfig() {
        this.updateBtnEnabled = false;

        let changedConfig = this.getChangedKeyValuePairs(this.gamblingConfigsCopy.coin_flip_config, this.gamblingConfigsOriginal.coin_flip_config);

        const response = await this.$store.dispatch('sendRequest', {
            method: 'PATCH',
            url: `${this.$store.state.apiUrl}/api/gambling/config`,
            data: {
                'coin_flip_config': changedConfig,
            },
            show_message_on_success: true,
        });

        if (response) {
            this.updateCachedConfig(response);


            this.gamblingConfigsOriginal = response.config;
            this.gamblingConfigsCopy = JSON.parse(JSON.stringify(response.config));
        }

        this.showModal = false;
        this.updateBtnEnabled = true;
    },

    async updateP2PCoinFlipConfig() {
        this.updateBtnEnabled = false;

        let changedConfig = this.getChangedKeyValuePairs(this.gamblingConfigsCopy.player_to_player_coin_flip_config, this.gamblingConfigsOriginal.player_to_player_coin_flip_config);

        const response = await this.$store.dispatch('sendRequest', {
            method: 'PATCH',
            url: `${this.$store.state.apiUrl}/api/gambling/config`,
            data: {
                'p2p_coin_flip_config': changedConfig,
            },
            show_message_on_success: true,
        });

        if (response) {
            this.updateCachedConfig(response);


            this.gamblingConfigsOriginal = response.config;
            this.gamblingConfigsCopy = JSON.parse(JSON.stringify(response.config));
        }
        this.showModal = false;
        this.updateBtnEnabled = true;
      },

    async updateP2PHighestCardConfig() {
        this.updateBtnEnabled = false;

        let changedConfig = this.getChangedKeyValuePairs(this.gamblingConfigsCopy.player_to_player_highest_card_config, this.gamblingConfigsOriginal.player_to_player_highest_card_config);

        const response = await this.$store.dispatch('sendRequest', {
            method: 'PATCH',
            url: `${this.$store.state.apiUrl}/api/gambling/config`,
            data: {
                'p2p_highest_card_config': changedConfig,
            },
            show_message_on_success: true,
        });

        if (response) {
            this.updateCachedConfig(response);


            this.gamblingConfigsOriginal = response.config;
            this.gamblingConfigsCopy = JSON.parse(JSON.stringify(response.config));

        }

        this.showModal = false;
        this.updateBtnEnabled = true;
    },


    async updateChanges() {
        
        if (this.modalContent == 'global') {
            await this.updateGlobalConfig();
        }
        if (this.modalContent == 'slots') {
            await this.updateSlotsConfig();
        }
        if (this.modalContent == 'rps') {
            await this.updateRPSConfig();
        }
        if (this.modalContent == 'roulette') {
            await this.updateRouletteConfig();
        }
        if (this.modalContent == 'blackjack') {
            await this.updateBlackjackConfig();
        }

        if (this.modalContent == 'coinflip') {
            await this.updateCoinFlipConfig();

    }
    if (this.modalContent == 'p2p-coinflip') {
        await this.updateP2PCoinFlipConfig();
    }
    if (this.modalContent == 'p2p-highest-card') {
        await this.updateP2PHighestCardConfig();
    }
    },

    updateCachedConfig(response) {
      let currentConfig = this.$store.state.gamblingConfigs.configs;
      currentConfig.roulette_config = response.config.roulette_config;
      const websocketMessage = {
      event: "update_gambling_configs",
      discord_server_id: this.$store.state.client_discord_server_id,
      data: {
        configs: response.config,
        last_cache_timestamp: new Date(),
      },
      };

      this.$store.state.websocket.send(
      JSON.stringify(websocketMessage)
      );
    }
},

    created() {
        this.loadGamblingConfigs();
    }
  }
</script>

<style scoped>
.gambling-management-container {
  background: rgba(187, 218, 255, 0.04);
  border: 1px solid rgba(187, 218, 255, 0.08);
  border-radius: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  text-align: left;
}

.management-btn {
  display: flex;
  padding: 12px 16px;
  max-width: 200px;
  border: 1px solid rgba(195, 238, 252, 0.16);
  background-color: rgba(195, 218, 252, 0.08);
  border-radius: 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 150%;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
}

.management-lower {
  display: grid;
  /* auto responsive grid */
  grid-template-columns: repeat(auto-fill, minmax(min-content, 200px));
  column-gap: 16px;
  row-gap: 8px;
}
</style>
