<template>
    <div class="game-servers-management-container">
      <div class="game-servers-options-container">
        <h1 class="gsoc-heading content-header">Scheduled Tasks</h1>
      </div>
  
      <select
        name=""
        id=""
        class="choose-gameserver-dropdown"
        v-model="selectedGameServerID"
        @change=""
      >
        <option disabled value="null">Choose Game Server</option>
        <option v-for="server in allLinkedServers" :key="server.id" :value="server.id">
          {{ server.server_identifier }}
        </option>
      </select>
  
      <p class="server-options-sub-heading">Task Options</p>
  
      <div class="gsoc-content">
        <button
          class="gsoc-button"
          @click="openModal('heatmap')"
        >
          Heatmap Task
        </button>
        <button
          class="gsoc-button"
          @click="openModal('in_depth_logs')"
          
        >
          Nitrado Logs Task
        </button>
        <button
          class="gsoc-button"
          @click="openModal('leaderboards')"
          
        >
          Leaderboards
        </button>
       
      </div> 
      <Modal :show="showModal" @update:show="showModal = $event">
        <template v-slot:header-content>
          <div class="header-content-container">
            <p class="modal-sub-heading">{{ modalHeader }}</p>
            <p class="modal-main-heading">Edit Configuration</p>
          </div>
        </template>
        <template v-slot:body-content>
          <div class="modal-body-content-container">
            <form class="modal-body-cc-inner">
              <div class="config-row">
                <div class="config-option">
                  <label for="server_identifier">Task Enabled</label>
                    <select
                        name=""
                        id=""
                        class="config-option-dropdown"
                        v-model="taskConfigUpdated.enabled"
                    >
                        <option :value="true">True</option>
                        <option :value="false">False</option>
                    </select>

                </div>
                <div class="config-option">
                  <label for="role_id_on_link">Channel</label>
                  <select
                    name=""
                    class="config-option-dropdown"
                    v-model="taskConfigUpdated.channel_id"
                  >
                    <option value="null" disabled selected>No Channel</option>
                    <option
                      v-for="channel in allChannels"
                      :key="channel.name"
                      :value="String(channel.id)"
                    >
                      {{ channel.name }}
                    </option>
                  </select>
                </div>
              </div>
  
              <div class="config-row">
                <div class="config-option">
                  <label for="server_identifier">Task Interval (minutes)</label>
                  <input
                    name=""
                    class="config-option-input"
                    v-model="taskConfigUpdated.time_interval"
                    type="number"
                    max="1000000"
                    min="0"
                  />
                </div>
                <div class="config-option">
                <label for="server_restart_interval">Next Run Time</label>
                <input
                  name=""
                  class="config-option-input"
                  v-model="formattedNextTaskRun"
                  type="datetime-local"
                  
                />
              </div>
              </div>
  
            </form>
  
            
  
            
          </div>
        </template>
        <template v-slot:footer-content>
          
  
          <div
            class="modal-footer-content-container"
          
          >
            <button
              class="modal-btn cancel-btn"
              @click="cancelChanges()"
            >
              Cancel Changes
            </button>
            <button
              :disabled="!allowSubmitChanges"
              class="modal-btn save-btn"
              @click="submitTaskConfigChanges()"
            >
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p class="btn-text">Save Changes</p>
            </button>
          </div>
  
        
        </template>
      </Modal>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex';
  import axios from 'axios';
  import Modal from './Modal.vue';
  import { validatePagePermissions } from '../utils/permissions';
  export default {
    components: {
      Modal,
    },
    data() {
      return {
        taskConfigOriginal: {},
        taskConfigUpdated: {},
        showModal: false,
        modalContentType: null,
        selectedGameServerID: null,
        allowSubmitChanges: true,
        currentDateTimeLocal: new Date().toISOString().slice(0, 16), // Current local time in ISO format
        modalHeader: '',
        rolesAndChannels: [],
        guildWebhooks: [],
        
      };
    },
  
    computed: {
      
      formattedNextTaskRun: {
          get() {
            if (!this.taskConfigUpdated.next_task_run) {
              // Return the current local time if next_task_run is null or undefined
              const currentLocalDate = new Date();
              let local_date_string = `${currentLocalDate.getFullYear()}-${String(currentLocalDate.getMonth() + 1).padStart(2, '0')}-${String(currentLocalDate.getDate()).padStart(2, '0')}T${String(currentLocalDate.getHours()).padStart(2, '0')}:${String(currentLocalDate.getMinutes()).padStart(2, '0')}`;
              return local_date_string;
            }
           
            const hasZ = this.taskConfigUpdated.next_task_run.includes('Z');
            const hasOffset = /[+-]\d{2}:\d{2}$/.test(this.taskConfigUpdated.next_task_run);
         
            let isoString = this.taskConfigUpdated.next_task_run.replace(" ", "T");
            if (!hasZ && !hasOffset) {
              isoString += 'Z';
            }
           
  
            
  
            // 2024-10-15T01:33:00.000Z
            // Create a Date object assuming it's UTC
         
            const local_time = new Date(isoString);
      
            let local_date_string = `${local_time.getFullYear()}-${String(local_time.getMonth() + 1).padStart(2, '0')}-${String(local_time.getDate()).padStart(2, '0')}T${String(local_time.getHours()).padStart(2, '0')}:${String(local_time.getMinutes()).padStart(2, '0')}`;
          
            return local_date_string;
          },
          set(value) {
            
            // Convert local time back to UTC
            const localDate = new Date(value);
            this.taskConfigUpdated.next_task_run = localDate.toISOString();
          },
        },
      allLinkedServers() {
        return this.$store.state.linked_servers;
      },
      currentDateTime() {
        const now = new Date();
        const year = now.getUTCFullYear();
        const month = String(now.getUTCMonth() + 1).padStart(2, '0');
        const day = String(now.getUTCDate()).padStart(2, '0');
        const hours = String(now.getUTCHours()).padStart(2, '0');
        const minutes = String(now.getUTCMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
      },
  
      allChannels() {
        return this.rolesAndChannels.filter(permType => {
          return permType.type === 'channel';
        });
      },
    },
  
    methods: {

      async getLeaderboardsTaskSchedule() {
        
        let currentCache = this.$store.state.cachedTaskSchedules.schedules.find(
          schedule => schedule.server_id === this.selectedGameServerID && schedule.task_type === 'leaderboards'
        );

        console.log('currentCache', currentCache);
        console.log('store object', this.$store.state.cachedTaskSchedules.schedules);

        let last_cache_timestamp = currentCache ? currentCache.lastCacheTimestamp : 0;
        let current_timestamp = new Date();
        let time_difference = current_timestamp - last_cache_timestamp;
        let time_difference_in_minutes = time_difference / 60000;

        if (time_difference_in_minutes < 5) {
          return { scheduled_task: currentCache };
        }

        const response = await this.$store.dispatch('sendRequest', {
          url: `${this.$store.state.apiUrl}/api/scheduled_services/leaderboards`,
          params: {
            server_id: this.selectedGameServerID,
          },
          show_message_on_success: false
        });

        if (!response) {
          return null;
        }

        let currentCachedSchedules = this.$store.state.cachedTaskSchedules.schedules;

        // Check if the schedule already exists
        let scheduleExists = currentCachedSchedules.some(
          schedule => schedule.server_id === this.selectedGameServerID && schedule.task_type === 'leaderboards'
        );

        let updatedSchedules;

        if (scheduleExists) {
          // Update the existing schedule
          updatedSchedules = currentCachedSchedules.map(schedule => {
            if (schedule.server_id === this.selectedGameServerID && schedule.task_type === 'leaderboards') {
              return { ...response.scheduled_task, task_type: 'leaderboards', lastCacheTimestamp: current_timestamp };
            }
            return schedule;
          });
        } else {
          // Add the new schedule
          updatedSchedules = [
            ...currentCachedSchedules,
            { ...response.scheduled_task, task_type: 'leaderboards', lastCacheTimestamp: current_timestamp },
          ];
        }

        let websocketMessage = {
          event: 'updatingCache',
          action: 'updateCachedTaskSchedules',
          discord_server_id: this.$store.state.client_discord_server_id,
          data: updatedSchedules,
        };

        this.$store.state.websocket.send(JSON.stringify(websocketMessage));

        return { scheduled_task: response.scheduled_task };
      },

    async getInDepthLogsSchedule() {

      let currentCache = this.$store.state.cachedTaskSchedules.schedules.find( schedule => schedule.server_id === this.selectedGameServerID && schedule.task_type === 'in_depth_logs');

      let last_cache_timestamp = currentCache ? currentCache.lastCacheTimestamp : 0;
      let current_timestamp = new Date()
      let time_difference = current_timestamp - last_cache_timestamp;
      let time_difference_in_minutes = time_difference / 60000;
      if (time_difference_in_minutes < 5) {
          return {scheduled_task: currentCache};
      }

        
        const response = await this.$store.dispatch('sendRequest', {
          url: `${this.$store.state.apiUrl}/api/scheduled_services/in_depth_logs`,
          params: {
            server_id: this.selectedGameServerID,
          },
          show_message_on_success: false
        });

        if (!response) {
          return null;
        }

        let currentCachedSchedules = this.$store.state.cachedTaskSchedules.schedules;

        // Check if the schedule already exists
        let scheduleExists = currentCachedSchedules.some(
          schedule => schedule.server_id === this.selectedGameServerID && schedule.task_type === 'in_depth_logs'
        );

        let updatedSchedules;

        if (scheduleExists) {
          // Update the existing schedule
          updatedSchedules = currentCachedSchedules.map(schedule => {
            if (schedule.server_id === this.selectedGameServerID && schedule.task_type === 'in_depth_logs') {
              return { ...response.scheduled_task, task_type: 'in_depth_logs', lastCacheTimestamp: current_timestamp };
            }
            return schedule;
          });
        } else {
          // Add the new schedule
          updatedSchedules = [
            ...currentCachedSchedules,
            { ...response.scheduled_task, task_type: 'in_depth_logs', lastCacheTimestamp: current_timestamp },
          ];
        }

        let websocketMessage = {
          event: 'updatingCache',
          action: 'updateCachedTaskSchedules',
          discord_server_id: this.$store.state.client_discord_server_id,
          data: updatedSchedules,
        };

        this.$store.state.websocket.send(JSON.stringify(websocketMessage));


        return response;
    },

    async submitScheduledTaskChanges() {
        const changedValues = this.getChangedKeyValuePairs(
          this.taskConfigUpdated,
          this.taskConfigOriginal
        );

        if (Object.keys(changedValues).length === 0) {
          return this.$store.commit('setStatusMessage', {
            type: 'info',
            text: 'No changes detected.',
          });
        }


        const response = await this.$store.dispatch('sendRequest', {
          url: `${this.$store.state.apiUrl}/api/scheduled_services/${this.modalContentType}`,
          method: 'PUT',
          data: {
            server_id: this.selectedGameServerID,
            ...changedValues,
          },
        });


        if (!response) {
          return;
        }

        this.taskConfigOriginal = response.updated_task;
        this.taskConfigUpdated = JSON.parse(JSON.stringify(this.taskConfigOriginal));

        let currentCachedSchedules = this.$store.state.cachedTaskSchedules.schedules;

        // Check if the schedule already exists
        let scheduleExists = currentCachedSchedules.some(
          schedule => schedule.server_id === this.selectedGameServerID && schedule.task_type === this.modalContentType
        );

        let updatedSchedules;

        if (scheduleExists) {
          // Update the existing schedule
          updatedSchedules = currentCachedSchedules.map(schedule => {
            if (schedule.server_id === this.selectedGameServerID && schedule.task_type === this.modalContentType) {
              return { ...response.updated_task, task_type: this.modalContentType, lastCacheTimestamp: new Date() };
            }
            return schedule;
          });
        } else {
          // Add the new schedule
          updatedSchedules = [
            ...currentCachedSchedules,
            { ...response.updated_task, task_type: this.modalContentType, lastCacheTimestamp: new Date() },
          ];
        }

        let websocketMessage = {
          event: 'updatingCache',
          action: 'updateCachedTaskSchedules',
          discord_server_id: this.$store.state.client_discord_server_id,
          data: updatedSchedules,
        };

        this.$store.state.websocket.send(JSON.stringify(websocketMessage));

        this.showModal = false;
    },

    async getHeatmapSchedule() {
        let currentCache = this.$store.state.cachedTaskSchedules.schedules.find(
          schedule => schedule.server_id === this.selectedGameServerID && schedule.task_type === 'heatmap'
        );

        let last_cache_timestamp = currentCache ? currentCache.lastCacheTimestamp : 0;
        let current_timestamp = new Date();
        let time_difference = current_timestamp - last_cache_timestamp;
        let time_difference_in_minutes = time_difference / 60000;

        if (time_difference_in_minutes < 5) {
          return { scheduled_task: currentCache };
        }



        const response = await this.$store.dispatch('sendRequest', {
          url: `${this.$store.state.apiUrl}/api/scheduled_services/heatmap`,
          params: {
            server_id: this.selectedGameServerID,
          },
          show_message_on_success: false
        });

        if (!response) {
          return null;
        }

        let currentCachedSchedules = this.$store.state.cachedTaskSchedules.schedules;

        // Check if the schedule already exists
        let scheduleExists = currentCachedSchedules.some(
          schedule => schedule.server_id === this.selectedGameServerID && schedule.task_type === 'heatmap'
        );

        let updatedSchedules;

        if (scheduleExists) {
          // Update the existing schedule
          updatedSchedules = currentCachedSchedules.map(schedule => {
            if (schedule.server_id === this.selectedGameServerID && schedule.task_type === 'heatmap') {
              return { ...response.scheduled_task, task_type: 'heatmap', lastCacheTimestamp: current_timestamp };
            }
            return schedule;
          });
        } else {
          // Add the new schedule
          updatedSchedules = [
            ...currentCachedSchedules,
            { ...response.scheduled_task, task_type: 'heatmap', lastCacheTimestamp: current_timestamp },
          ];
        }

        let websocketMessage = {
          event: 'updatingCache',
          action: 'updateCachedTaskSchedules',
          discord_server_id: this.$store.state.client_discord_server_id,
          data: updatedSchedules,
        };

        this.$store.state.websocket.send(JSON.stringify(websocketMessage));


        return response;
    },
  
      convertUtcToLocal(datetime_string) {
        // if the string contains this :00.000Z, it means it's already in local time, and orginates from the fact that the task run time was initially set to null
        if (datetime_string.includes(':00.000Z')) {
          return datetime_string.slice(0, 16);
        }
  
        // Parse the UTC datetime string
        let date = new Date(Date.parse(datetime_string + 'Z')); // Append 'Z' to indicate UTC timezone
  
        // Extract date components
        let year = date.getFullYear();
        let month = ('0' + (date.getMonth() + 1)).slice(-2); // Add 1 to month because it's zero-based
        let day = ('0' + date.getDate()).slice(-2);
        let hours = ('0' + date.getHours()).slice(-2);
        let minutes = ('0' + date.getMinutes()).slice(-2);
  
        // Construct the formatted date string
        let formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
  
        // Assign the formatted date to the v-model
        return formattedDate;
  
      
      },
  
      convertLocalToUtc(local_datetime_string) {
        const localDate = new Date(local_datetime_string);
      
      // Extract date components
      const year = localDate.getUTCFullYear();
      const month = ('0' + (localDate.getUTCMonth() + 1)).slice(-2); // Add 1 to month because it's zero-based
      const day = ('0' + localDate.getUTCDate()).slice(-2);
      const hours = ('0' + localDate.getUTCHours()).slice(-2);
      const minutes = ('0' + localDate.getUTCMinutes()).slice(-2);
      
      // Construct the formatted UTC date string
      const utcDateString = `${year}-${month}-${day}T${hours}:${minutes}`;
      
      return utcDateString;
      },
  
  
      async updateTaskConfig() {
        let valid_perms = validatePagePermissions('scheduled_tasks.read', this.$store)
        if (!valid_perms) {
          this.selectedGameServerID = null;
          this.taskConfigOriginal = {};
          this.taskConfigUpdated = {};
                return;
              }

        if (this.modalContentType === 'heatmap') {
            let response = await this.getHeatmapSchedule();
            if (!response) {
                return;
            }
            
            this.taskConfigOriginal = response.scheduled_task;
            this.taskConfigUpdated = JSON.parse(JSON.stringify(this.taskConfigOriginal));
        }

        if (this.modalContentType === 'in_depth_logs') {
            let response = await this.getInDepthLogsSchedule();
            if (!response) {
                return;
            }
            this.taskConfigOriginal = response.scheduled_task;
            this.taskConfigUpdated = JSON.parse(JSON.stringify(this.taskConfigOriginal));
        }

        if (this.modalContentType === 'leaderboards') {
            let response = await this.getLeaderboardsTaskSchedule();
            if (!response) {
                return;
            }
            this.taskConfigOriginal = response.scheduled_task;
            this.taskConfigUpdated = JSON.parse(JSON.stringify(this.taskConfigOriginal));
        }
        
      
      },
  
      openModal(contentType) {
  
        if (!this.selectedGameServerID) {
          return this.$store.commit('setStatusMessage', {
            type: 'error',
            text: 'Please select a server to view its settings.',
          });
        }
  
        let has_permissions = validatePagePermissions(
          'scheduled_tasks.read',
          this.$store
        );
  
        if (!has_permissions) {
          return 
        }
        let isResponseGood = true;
  
        if (isResponseGood) {
          this.showModal = true;
          this.modalHeader =
            `${contentType} settings`.charAt(0).toUpperCase() +
            `${contentType} settings`.slice(1);

          if (this.modalContentType !== contentType) {
            this.taskConfigOriginal = {};
            this.taskConfigUpdated = {};
          }
  
          this.modalContentType = contentType;
          
            this.updateTaskConfig();
  
        }
      },
      closeModal() {
        this.showModal = false;
      },
      async getGuildChannelsAndRoles() {
    const response = await this.$store.dispatch('sendRequest', {
      url: `${this.$store.state.apiUrl}/api/dashboard/discord/guilds/roles-and-channels`,
      params: {
        guild_id: this.$store.state.client_discord_server_id,
      },
      show_message_on_success: false
    });
  
    if (!response) {
      return;
    }
  
    this.rolesAndChannels = [
      ...response.roles
        .filter(role => role.name !== '@everyone') // Exclude the '@everyone' role
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(role => ({
          ...role,
          type: 'role',
          selected: false,
        })),
      ...response.channels
        .filter(channel => channel.parent_id !== null)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(channel => ({
          ...channel,
          type: 'channel',
          selected: false,
        })),
    ];
  },
  
      getChangedKeyValuePairs(changdObject, originalObject) {
  
        let changedValues = {};
        for (let key in changdObject) {
          if (changdObject[key] !== originalObject[key]) {
            changedValues[key] = changdObject[key];
          }
        }
        return changedValues;
      },
  
      async submitTaskConfigChanges(){
        let valid_perms = validatePagePermissions('scheduled_tasks.update', this.$store)
        if (!valid_perms) {
          return;
        }
        await this.submitScheduledTaskChanges();
        
      },
  
      cancelChanges() {
        this.taskConfigUpdated = JSON.parse(
          JSON.stringify(this.taskConfigOriginal)
        );
        this.showModal = false;
  
      },
  

    },
  
    created() {
      this.getGuildChannelsAndRoles();
    },
  };
  </script>
  
  <style scoped>
  .game-servers-management-container {
    background: rgba(187, 218, 255, 0.04);
    border: 1px solid rgba(187, 218, 255, 0.08);
    border-radius: 16px;
    width: 100%;
    display: flex;
    padding: 24px;
    flex-direction: column;
    row-gap: 24px;
  }
  
  .choose-gameserver-dropdown {
    /* Frame 171 */
  
    box-sizing: border-box;
  
    display: inline-block;
    padding: 12px 16px;
    gap: 16px;
  
    max-width: 200px;
    height: 45px;
  
    border: 1px solid rgba(195, 238, 252, 0.16);
    border-radius: 12px;
  
    width: 100%;
  
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 150%;
    /* identical to box height, or 21px */
  
    color: #ffffff;
    appearance: none; /* Remove default arrow */
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><path d="M12.6666 6.5L7.99992 11.1667L3.33325 6.5" stroke="white" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    background-repeat: no-repeat;
    background-position: right 16px center; /* Position it 16px from the right edge */
    padding-right: 36px; /* Add padding to prevent the text from overlapping the arrow */
    cursor: pointer;
    background-color: rgba(195, 218, 252, 0.08);
  }
  
  .choose-gameserver-dropdown:focus {
    outline: none;
  }
  
  .choose-gameserver-dropdown option {
    color: black;
  }
  
  .gsoc-heading {
    text-align: left;
    margin: 0;
  }
  
  .gsoc-content {
    display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
      align-items: center;
      color: white;
      column-gap: 20px;
      row-gap: 10px;
  }
  
  .server-options-sub-heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 150%;
    /* identical to box height, or 24px */
    text-align: left;
    color: #ffffff;
  }
  
  .gsoc-button {
    display: flex;
    padding: 12px 16px;
  
    max-width: 200px;
  
    border: 1px solid rgba(195, 238, 252, 0.16);
    background-color: rgba(195, 218, 252, 0.08);
    border-radius: 12px;
  
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 150%;
    /* identical to box height, or 24px */
    text-align: left;
    color: #ffffff;
    cursor: pointer;
  }
  
  .modal-sub-heading {
    /* COMMAND NAME */
  
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 15px;
    /* identical to box height */
    letter-spacing: 0.08em;
  
    color: #bbced8;
  }
  .modal-main-heading {
    /* Add Channels */
  
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 29px;
    /* identical to box height */
  
    color: #ffffff;
  
    margin-top: 8px;
  }
  
  /* Frame 171 */
  
  .config-option-dropdown {
    box-sizing: border-box;
  
    display: inline-block;
  
    padding: 8px 12px;
  
    border: 1px solid rgba(195, 238, 252, 0.16);
    border-radius: 12px;
  
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 150%;
    /* identical to box height, or 21px */
  
    color: #ffffff;
    appearance: none; /* Remove default arrow */
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><path d="M12.6666 6.5L7.99992 11.1667L3.33325 6.5" stroke="white" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    background-repeat: no-repeat;
    background-position: right 16px center; /* Position it 16px from the right edge */
    padding-right: 36px; /* Add padding to prevent the text from overlapping the arrow */
    cursor: pointer;
    background-color: rgba(195, 218, 252, 0.08);
  }
  
  .config-option-input,
  .config-option-dropdown {
    width: 165px;
  }
  .config-option-input {
    background-color: rgba(195, 218, 252, 0.08);
    box-sizing: border-box;
  
    display: inline-block;
  
    padding: 8px 12px;
  
    border: 1px solid rgba(195, 238, 252, 0.16);
    border-radius: 12px;
  
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 150%;
    /* identical to box height, or 21px */
  
    color: #ffffff;
  }
  .config-option-dropdown:focus,
  .config-option-input:focus {
    outline: none;
  }
  
  .config-option-dropdown option,
  .config-option-input option {
    color: black;
  }
  
  .modal-body-cc-inner {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
  .config-row {
    display: flex;
    justify-content: space-between;
  }
  
  .config-option {
    flex-direction: column;
    text-align: left;
    display: flex;
    row-gap: 4px;
  }
  
  .config-option label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 15px;
    /* identical to box height */
    /* letter-spacing: 0.08em; */
    padding-left: 5px;
  
    color: #bbced8;
  }
  
  /* for screens below 700px */
  @media (max-width: 800px) {
    
  }
  
  </style>
  