<template>
  <div class="placement-pings-management-container">
    <div class="management-top">
      <h1 class="content-header">Placement Pings</h1>
      <p class="content-header-sub">
        Manage channel pings for certain item placements
      </p>
    </div>
    <div class="management-lower">
      <select
        name=""
        id=""
        class="choose-shop-item dropdown"
        v-model="selectedPlacementPing"
        @change="
          updateSelectedItemSelect
        "
      >
        <option disabled value="">Placement Ping</option>
        <option
          v-for="placement_ping in allPlacementPings"
          :key="placement_ping.id"
          :value="placement_ping"
        >
          {{ placement_ping.item_name }}
        </option>
      </select>
      <button class="btn new-item-btn" @click="openNewItemModal">
        <p>Add New Placement Ping</p>
      </button>
    </div>

    <div class="item-ping-management-section" v-if="selectedPlacementPing">
      <div class="modal-option">
        <label for="item name" class="required-input"
          >Item Name (Exactly like logs)</label
        >
        <input
          type="text"
          class="modal-option-input"
          v-model="selectedPlacementPingUpdated.item_name"
          maxlength="100"
        />
      </div>
      <div class="modal-option">
        <label for="item_usage_before_ping" class=""
          >Item usage before ping
        </label>
        <input
          type="text"
          class="modal-option-input"
          v-model="selectedPlacementPingUpdated.item_usage_before_ping"
          maxlength="100"
        />
      </div>

      <div class="modal-option">
        <label for="item_usage_timeframe" class=""
          >Item usage timeframe (minutes)</label
        >
        <input
          type="number"
          class="modal-option-input"
          v-model="selectedPlacementPingUpdated.item_usage_timeframe"
          min="1"
          max="120"
        />
      </div>
      <div class="modal-option">
        <label for="enabled" class="">Enabled</label>
        <select
          name=""
          class="dropdown"
          v-model="selectedPlacementPingUpdated.enabled"
        >
          <option value="false">False</option>
          <option value="true">True</option>
        </select>
      </div>

      <button class="btn edit-servers-btn" @click="openSelectedServersModal">
        <p>Edit Servers</p>
      </button>

      <div class="btn-container-item-ping-update">
        <button class="modal-btn cancel-btn" @click="discardUpdatedChanges">
          Cancel
        </button>
        <button
          class="modal-btn save-btn"
          @click="saveUpdatedItem"
          :disabled="!itemUpdateBtnEnabled"
        >
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p class="btn-text">Save</p>
        </button>
        <div class="delete-btn" @click="deletePlacementPing">
                <img src="../assets/TrashOutline.svg" alt="">
            </div>
      </div>
    </div>

    <Modal :show="showModal" @update:show="showModal = $event">
      <template v-slot:header-content>
        <div class="header-content-container">
          <p class="modal-sub-heading">Placement Pings</p>
          <p class="modal-main-heading">Add New Placement Ping</p>
        </div>
      </template>
      <template v-slot:body-content>
        <div class="modal-row-container">
          <div class="modal-row">
            <div class="modal-option">
              <label for="item name" class="required-input"
                >Item Name (Exactly like logs)</label
              >
              <input
                type="text"
                class="modal-option-input"
                v-model="newPlacementPing.item_name"
                maxlength="100"
              />
            </div>
            <div class="modal-option">
              <label for="item_usage_before_ping" class=""
                >Item Usage Before Ping</label
              >
              <input
                type="number"
                class="modal-option-input"
                v-model="newPlacementPing.item_usage_before_ping"
                min="0"
                max="50"
              />
            </div>
          </div>
          <div class="modal-row">
            <div class="modal-option">
              <label for="item_usage_timeframe" class=""
                >Item Usage Timeframe (minutes)</label
              >
              <input
                type="number"
                class="modal-option-input"
                v-model="newPlacementPing.item_usage_timeframe"
                min="1"
                max="120"
              />
            </div>
            <div class="modal-option">
              <label for="enabled" class="">Enabled</label>
              <select
                name=""
                class="dropdown"
                v-model="newPlacementPing.enabled"
              >
                <option value="false">False</option>
                <option value="true">True</option>
              </select>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer-content>
        <div class="modal-footer-content-container">
          <button class="modal-btn cancel-btn" @click="discardNewItem">
            Cancel
          </button>
          <button
            class="modal-btn save-btn"
            @click="saveNewPlacementPing"
            :disabled="!saveNewItemBtnEnabled || !newPlacementPing.item_name"
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="btn-text">Save Changes</p>
          </button>
        </div>
      </template>
    </Modal>

    <Modal
      :show="showSelectedServersModal"
      @update:show="showSelectedServersModal = $event"
    >
      <template v-slot:header-content>
        <div class="modal-header-content-container">
          <p class="modal-sub-heading">Edit Servers</p>
          <p class="modal-main-heading">
            Edit The Servers This Item Is Banned On
          </p>
        </div>
      </template>

      <template v-slot:body-content>
        <div class="modal-body-content-container">
          <div class="modal-body-restricted-servers-container">
            <div
              v-for="server in currentLinkedServers"
              :key="server.id"
              class="modal-checkbox-select"
              @click="updateselectedPlacementPingServers($event, server.id)"
              :class="{
                'active-checked': selectedPlacementPingUpdated.servers.some(
                  (selectedServer) => selectedServer.id === server.id
                )
              }"
            >
              <input
                type="checkbox"
                :id="server.id"
                class="checkbox"
                :checked="selectedPlacementPingUpdated.servers.some(
                  (selectedServer) => selectedServer.id === server.id
                )"
                @click.prevent
                
              />
              <label :for="server.id" class="checkbox-label"></label>
              <p class="checkbox-name">{{ server.server_identifier }}</p>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:footer-content>
       
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal.vue';
export default {
  name: 'PlacementPingsManagement',
  components: {
    Modal,
  },

  data() {
    return {
      selectedPlacementPing: '',
      selectedPlacementPingUpdated: {},
      addBtnEnabled: true,
      saveNewItemBtnEnabled: true,
      showSelectedServersModal: false,
      showModal: false,
      updateServersBtnEnabled: true,
      newPlacementPing: {},
      itemUpdateBtnEnabled: true,
    };
  },

  computed: {
    currentLinkedServers() {
      return this.$store.state.linked_servers
    },
    allPlacementPings() {
      return this.$store.state.placementPings.placement_pings;
    },
  },

  methods: {
    getChangedKeyValuePairs(changdObject, originalObject) {
      let changedValues = {};
      for (let key in changdObject) {
        if (changdObject[key] !== originalObject[key]) {
          changedValues[key] = changdObject[key];
        }
      }
      return changedValues;
    },
    async loadPlacementsData() {
      let currentCacheTimestamp = this.$store.state.placementPings.last_cache_timestamp;
    let currentTime = Date.now();
    let timeDifference = currentTime - currentCacheTimestamp;

    // if the cache is older than 5 minutes, refresh the cache
    if (timeDifference > 300000) {
      const response = await this.$store.dispatch('sendRequest', {
        method: 'GET',
        url: `${this.$store.state.apiUrl}/api/item-auto-ping`,
        show_message_on_success: false,
      });

      if (response) {
        const websocket_message = {
          event: 'update_banned_items',
          discord_server_id: this.$store.state.client_discord_server_id,
          data: {
            placement_pings: response.item_auto_pings,
            last_cache_timestamp: new Date()
          },
        };
        this.$store.state.websocket.send(
          JSON.stringify(websocket_message)
        );
      }
    } 


    },
    openNewItemModal() {
      this.showModal = true;
    },
    async updateselectedPlacementPingServers(event, server_id){

      if (!event.target.classList.contains('modal-checkbox-select')) {
        return;
      }

      // check if the server is already in the selected servers
      let server_index = this.selectedPlacementPingUpdated.servers.findIndex(
        (server) => server.id === server_id
      );

      if (server_index === -1) {
        this.selectedPlacementPingUpdated.servers.push({
          id: server_id,
          name: this.currentLinkedServers.find(
            (server) => server.id === server_id
          ).server_identifier,
        });
      } else {
        this.selectedPlacementPingUpdated.servers.splice(server_index, 1);
      }
      },


    async saveNewPlacementPing() {
      this.saveNewItemBtnEnabled = false;
      const response = await this.$store.dispatch('sendRequest', {
        url: `${this.$store.state.apiUrl}/api/item-auto-ping`,
        method: 'POST',
        data: this.newPlacementPing,
        show_message_on_success: true,
      });

      if (response) {
       let currentItemPings = JSON.parse(JSON.stringify(this.allPlacementPings));
      currentItemPings.push(response.item_auto_ping);

      const websocket_message = {
        event: 'update_placement_pings',
        discord_server_id: this.$store.state.client_discord_server_id,
        data: {
          placement_pings: currentItemPings,
          last_cache_timestamp: new Date()
        },
      }
      this.$store.state.websocket.send(
        JSON.stringify(websocket_message)
      );
      this.showModal = false;
    }
    this.saveNewItemBtnEnabled = true;

  },

    openSelectedServersModal() {
      this.showModal = false;
      this.showSelectedServersModal = true;
    },
    async saveUpdatedItem() {
      this.saveNewItemBtnEnabled = false;

      let changed_values = this.getChangedKeyValuePairs(
        this.selectedPlacementPingUpdated,
        this.selectedPlacementPing
      );

      let noServerChanges = this.selectedPlacementPingUpdated.servers.every(
        server => this.selectedPlacementPing.servers.some(s => s.id === server.id)
      ) && this.selectedPlacementPing.servers.every(
        server => this.selectedPlacementPingUpdated.servers.some(s => s.id === server.id)
      );
      // Handle the case where servers is present in changed_values
      if (changed_values.servers) {
        // If the servers array is empty, set it to an empty array
        if (changed_values.servers.length === 0) {
          changed_values.servers = [];
        }
      }

      // If there are no changes in restricted servers, remove the servers key from changed_values
      if (noServerChanges) {
        delete changed_values.servers;
      }
      // if key is servers, change it to restricted_server_ids
      if (changed_values.servers) {
        // we only need the server ids
        changed_values.server_ids = changed_values.servers.map(
          (server) => server.id
        );
        delete changed_values.servers;
      }


      const response = await this.$store.dispatch('sendRequest', {
        url: `${this.$store.state.apiUrl}/api/item-auto-ping`,
        method: 'PUT',
        data: {
          id: this.selectedPlacementPingUpdated.id,
          ...changed_values,
        },
        show_message_on_success: true,
      });

      if (response) {
        let currentItemPings = JSON.parse(JSON.stringify(this.allPlacementPings));
        let itemIndex = currentItemPings.findIndex(
          (item) => item.id === response.item_auto_ping.id
        );
        currentItemPings[itemIndex] = response.item_auto_ping;

        const websocket_message = {
          event: 'update_placement_pings',
          discord_server_id: this.$store.state.client_discord_server_id,
          data: {
            placement_pings: currentItemPings,
            last_cache_timestamp: new Date()
          },
        }
        this.$store.state.websocket.send(
          JSON.stringify(websocket_message)
        );
        this.selectedPlacementPing = response.item_auto_ping;
        this.selectedPlacementPingUpdated = JSON.parse(
          JSON.stringify(response.item_auto_ping)
        );
      }
      this.saveNewItemBtnEnabled = true;

    },

    async discardUpdatedChanges() {
      this.selectedPlacementPingUpdated = JSON.parse(
        JSON.stringify(this.selectedPlacementPing)
      );
    },

    async deletePlacementPing () {
      const response = await this.$store.dispatch('sendRequest', {
        url: `${this.$store.state.apiUrl}/api/item-auto-ping`,
        data: {
          id: this.selectedPlacementPing.id,
        },
        method: 'DELETE',
        show_message_on_success: true,
      });

      if (response) {
        let currentItemPings = JSON.parse(JSON.stringify(this.allPlacementPings));
        let itemIndex = currentItemPings.findIndex(
          (item) => item.id === this.selectedPlacementPing.id
        );
        currentItemPings.splice(itemIndex, 1);

        const websocket_message = {
          event: 'update_placement_pings',
          discord_server_id: this.$store.state.client_discord_server_id,
          data: {
            placement_pings: currentItemPings,
            last_cache_timestamp: new Date()
          },
        }
        this.$store.state.websocket.send(
          JSON.stringify(websocket_message)
        );
        this.selectedPlacementPing = '';
        this.selectedPlacementPingUpdated = {};
      }
    },

    updateSelectedItemSelect() {
      this.selectedPlacementPingUpdated = JSON.parse(
        JSON.stringify(this.selectedPlacementPing)
      );
    },


  },

  created() {
    this.loadPlacementsData();
  },
};
</script>

<style scoped>
.placement-pings-management-container {
  background: rgba(187, 218, 255, 0.04);
  border: 1px solid rgba(187, 218, 255, 0.08);
  border-radius: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  text-align: left;
}
.management-btn {
  display: flex;
  padding: 12px 16px;
  max-width: 200px;
  border: 1px solid rgba(195, 238, 252, 0.16);
  background-color: rgba(195, 218, 252, 0.08);
  border-radius: 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 150%;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
}

.management-lower {
  display: flex;
  gap: 24px;
  align-items: center;
}

.new-item-btn {
  box-sizing: border-box;
  display: inline-block;
  line-height: 150%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-family: 'DM Mono';
  font-size: 1.4em;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-align: left;
  padding: 10px 12px 10px 12px;
  border-radius: 12px;
  outline: none;
  border: none;
}

.edit-servers-btn {
  box-sizing: border-box;
  display: inline-block;
  line-height: 150%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-family: 'DM Mono';
  font-size: 1.4em;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-align: left;
  padding: 10px 12px 10px 12px;
  border-radius: 12px;
  outline: none;
  border: none;
  height: fit-content;
  width: fit-content;
}

.item-ping-management-section {
  margin-top: 24px;
  display: grid;
  /* make the grid auto responsive, as many columns as it can fit, each column 200px max */
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  row-gap: 16px;
  align-items: end;
}

.btn-container-item-ping-update {
  display: flex;
  gap: 8px;
  margin-top: 24px;
}

@media (max-width: 500px) {
  .management-lower {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  
}

.active-checked {
  background: rgba(195, 218, 252, 0.08);
}
.checkbox {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #bbced8a3;
  border-radius: 4px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-label {
  width: 16px;
  height: 16px;
  border: 1px solid #bbced8a3;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.checkbox:checked + .checkbox-label {
  background: #42d09c3d;

  border: 2px solid #42d09ca3;
  background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M13.3657%204.23447C13.5157%204.3845%2013.5999%204.58794%2013.5999%204.80007C13.5999%205.0122%2013.5157%205.21565%2013.3657%205.36567L6.96568%2011.7657C6.81566%2011.9156%206.61221%2011.9999%206.40008%2011.9999C6.18795%2011.9999%205.9845%2011.9156%205.83448%2011.7657L2.63448%208.56567C2.48876%208.41479%202.40812%208.21271%202.40994%208.00295C2.41177%207.7932%202.4959%207.59255%202.64423%207.44422C2.79255%207.29589%202.9932%207.21176%203.20296%207.20993C3.41272%207.20811%203.6148%207.28875%203.76568%207.43447L6.40008%2010.0689L12.2345%204.23447C12.3845%204.0845%2012.588%204.00024%2012.8001%204.00024C13.0122%204.00024%2013.2157%204.0845%2013.3657%204.23447Z%22%20fill%3D%22%2342D09C%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: center;
}

.delete-btn {
  align-self: center;
  margin-left: 12px;
}
</style>
