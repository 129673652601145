<template>
  <div class="shop-management-container">
    <h1 class="content-header shop-management-header">Shop Management</h1>
    <p class="content-header-sub">
      Manage your Shop Store items across your servers
    </p>
    <div class="shop-stats-container">
      <div class="shop-stat-box">
        <h1 class="stb-heading">
          Total Active Orders:
          {{ shopStats.active_orders ? shopStats.active_orders : 0 }}
        </h1>
      </div>
      <div class="shop-stat-box">
        <h1 class="stb-heading">
          Total Orders Weekly:
          {{ shopStats.weekly_orders ? shopStats.weekly_orders : 0 }}
        </h1>
      </div>
      <div class="shop-stat-box">
        <h1 class="stb-heading">
          Total Orders Monthly:
          {{ shopStats.monthly_orders ? shopStats.monthly_orders : 0 }}
        </h1>
      </div>
      <div class="shop-stat-box">
        <h1 class="stb-heading">
          Total Orders Lifetime:
          {{ shopStats.total_orders ? shopStats.total_orders : 0 }}
        </h1>
      </div>
    </div>

    <div class="shop-management-inner">
      <div class="smi-top">
        <select
          name=""
          id=""
          class="choose-shop-item dropdown"
          v-model="selectedShopItem"
          @change="changedSelectedShopItem"
        >
          <option disabled value="null">Shop Item</option>
          <option
            v-for="shopItem in currentShopItemsData"
            :key="shopItem.id"
            :value="shopItem"
          >
            {{ shopItem.item_name }}
          </option>
        </select>

        <button class="btn add-shop-item-btn" @click="showModal = true">
          <p>Add New Item</p>
        </button>
        <div class="shop-setting-input">
          <label for="shop-item-name" class="label">Shop Status</label>
          <select
            name=""
            id=""
            class="choose-shop-status dropdown"
            v-model="shopStatus"
            @change="updateShopStatus"
          >
            <option :value="false">Closed</option>
            <option :value="true">Open</option>
          </select>
        </div>
      </div>
      <div class="smi-body" v-if="selectedShopItem">
        <p class="smi-body-heading content-header-sub">Shop Item Settings</p>

        <div class="smi-body-settings">
          <div class="shop-setting-input">
            <label for="shop-item-name" class="label">Item ID</label>
            <input
              type="text"
              id="shop-item-name"
              class="input"
              v-model="selectedShopItemUpdated.id"
              readonly
            />
          </div>
          <div class="shop-setting-input">
            <label for="shop-item-name" class="label">Shop Item Name</label>
            <input
              type="text"
              id="shop-item-name"
              class="input"
              v-model="selectedShopItemUpdated.item_name"
            />
          </div>
          <div class="shop-setting-input">
            <label for="shop-item-name" class="label">Shop Item Type</label>
            <select
              name=""
              id=""
              class="dropdown"
              v-model="selectedShopItemUpdated.item_type"
            >
              <option value="npc">npc</option>
            </select>
          </div>
          <div class="shop-setting-input">
            <label for="shop-item-name" class="label">{{
              shopItemType === 'npc' ? 'Spawnable Type' : 'Shop Package'
            }}</label>
            <select
              name=""
              id=""
              class="dropdown"
              v-model="selectedShopItemUpdated.item_name_xml"
            >
              <option v-for="npc in Npcs" :key="npc.value" :value="npc.value">
                {{ npc.name }}
              </option>
            </select>
          </div>
          <div class="shop-setting-input">
            <label for="shop-item-name" class="label"
              >Item Price Per Restart</label
            >
            <input
              type="number"
              id="shop-item-name"
              class="input"
              min="1"
              max="1000000"
              v-model="selectedShopItemUpdated.item_price"
            />
          </div>
          <div class="shop-setting-input">
            <label for="shop-item-quantity" class="label">Item Quantity</label>
            <input
              type="number"
              id="shop-item-quantity"
              class="input"
              min="0"
              max="1000000"
              v-model="selectedShopItemUpdated.item_quantity"
            />
          </div>

          <div class="shop-setting-input">
            <label for="shop-item-name" class="label"
              >Max Item Duration (restarts)</label
            >
            <input
              type="number"
              id="shop-item-name"
              class="input"
              min="1"
              max="100000"
              v-model="selectedShopItemUpdated.max_duration"
            />
          </div>
          <div class="shop-setting-input">
            <label for="shop-item-name" class="label">Allow Extensions</label>
            <select
              name=""
              id=""
              class="dropdown"
              v-model="selectedShopItemUpdated.allow_extension"
            >
              <option value="false">False</option>
              <option value="true">True</option>
            </select>
          </div>
          <div class="shop-setting-input">
            <label for="shop-item-name" class="label"
              >Extension Price per restart</label
            >
            <input
              type="number"
              id="shop-item-name"
              class="input"
              min="1"
              max="100000"
              v-model="selectedShopItemUpdated.extension_price_per_restart"
            />
          </div>
          <div class="shop-setting-input">
            <label for="shop-item-name" class="label">Item Enabled</label>
            <select
              name=""
              id=""
              class="dropdown"
              v-model="selectedShopItemUpdated.enabled"
            >
              <option value="false">False</option>
              <option value="true">True</option>
            </select>
          </div>
          <div class="shop-setting-input">
            <button @click="openRestrictedServersModal">
              Edit Restricted Servers
            </button>
          </div>
        </div>

        <div class="smi-body-footer">
          <button
            class="modal-btn delete-btn cancel-btn"
            @click="deleteShopItem"
          >
            Delete Item
          </button>
          <button class="modal-btn save-settings-btn" @click="updateShopItem" :disabled="!allowSaveChanges">
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="btn-text">Save Changes</p>
          </button>
        </div>
      </div>
    </div>

    <Modal :show="showModal" @update:show="showModal = $event">
      <template v-slot:header-content>
        <div class="modal-header-content-container">
          <p class="modal-sub-heading">Shop Items</p>
          <p class="modal-main-heading">Add New Item</p>
        </div>
      </template>
      <template v-slot:body-content>
        <div class="modal-body-content-container">
          <div class="modal-row">
            <div class="shop-setting-input modal-input">
              <label for="shop-item-name" class="label required-input"
                >Shop Item Name</label
              >
              <input
                type="text"
                id="shop-item-name"
                class="input"
                v-model="newShopItem.item_name"
              />
            </div>
            <div class="shop-setting-input modal-input">
              <label for="shop-item-name" class="label required-input"
                >Shop Item Type</label
              >
              <select
                name=""
                id=""
                class="dropdown"
                v-model="newShopItem.item_type"
              >
                <option value="npc">npc</option>
              </select>
            </div>
          </div>
          <div class="modal-row">
            <div class="shop-setting-input modal-input">
              <label for="shop-item-name" class="label required-input">{{
                newShopItem.item_type === 'npc'
                  ? 'Spawnable Type'
                  : 'Shop Package'
              }}</label>

              <select
                name=""
                id=""
                class="dropdown"
                v-model="newShopItem.item_name_xml"
                :disabled="!newShopItem.item_type"
              >
                <option v-for="npc in Npcs" :key="npc.value" :value="npc.value">
                  {{ npc.name }}
                </option>
              </select>
            </div>
            <div class="shop-setting-input modal-input">
              <label for="shop-item-name" class="label required-input"
                >Item Price Per Restart</label
              >
              <input
                type="number"
                id="shop-item-name"
                class="input"
                min="1"
                max="1000000"
                v-model="newShopItem.item_price"
              />
            </div>
          </div>
          <div class="modal-row">
            <div class="shop-setting-input modal-input">
              <label for="shop-item-name" class="label">Item Quantity</label>
              <input
                type="number"
                id="shop-item-name"
                class="input"
                min="0"
                max="1000000"
                v-model="newShopItem.item_quantity"
              />
            </div>

            <div class="shop-setting-input modal-input">
              <label for="shop-item-name" class="label"
                >Max Item Time (restarts)</label
              >
              <input
                type="number"
                id="shop-item-name"
                class="input"
                min="1"
                max="100000"
                v-model="newShopItem.max_time"
              />
            </div>
          </div>

          <div class="modal-row">
            <div class="shop-setting-input modal-input">
              <label for="shop-item-name" class="label">Allow Extensions</label>
              <select
                name=""
                id=""
                class="dropdown"
                v-model="newShopItem.allow_extension"
              >
                <option value="false">False</option>
                <option value="true">True</option>
              </select>
            </div>
            <div class="shop-setting-input modal-input">
              <label for="shop-item-name" class="label"
                >Extension Price per restart</label
              >
              <input
                type="number"
                id="shop-item-name"
                class="input"
                min="1"
                max="100000"
                v-model="newShopItem.extension_price_per_restart"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer-content>
        <div class="modal-footer-content-container">
          <button class="modal-btn cancel-btn" @click="discardAddNewItem">
            Cancel
          </button>
          <button
            class="modal-btn save-btn"
            @click="addNewShopItem"
            :disabled="
              !newShopItem.item_name ||
              !newShopItem.item_type ||
              !newShopItem.item_name_xml ||
              !newShopItem.item_price ||
              !allowShopAdd
            "
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="btn-text">Add New Item</p>
          </button>
        </div>
      </template>
    </Modal>

    <Modal
      :show="showRestrictedServersModal"
      @update:show="showRestrictedServersModal = $event"
    >
      <template v-slot:header-content>
        <div class="modal-header-content-container">
          <p class="modal-sub-heading">Restricted Servers</p>
          <p class="modal-main-heading">Edit Restricted Servers For This Item</p>
        </div>
      </template>

      <template v-slot:body-content>
        <div class="modal-body-content-container">
          <div class="modal-body-restricted-servers-container">
            <div
              v-for="server in currentLinkedServers"
              :key="server.id"
              class="modal-checkbox-select"
              :class="{
                'active-checked': this.selectedShopItemUpdated.restricted_servers.includes(
                  server.id
                )
              }"
              @click="updateRestrictedServers($event, server.id)"
            >
              <input
                type="checkbox"
                :id="server.id"
                class="checkbox"
                :checked="selectedShopItemUpdated.restricted_servers.includes(
                  server.id
                )"
                @click.prevent

              />
              <label :for="server.id" class="checkbox-label" ></label>

              <p class="checkbox-name">{{ server.server_identifier }}</p>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:footer-content>
        <div class="modal-footer-content-container">
         
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Modal from './Modal.vue';
// New import statement (assuming you're in src/components)
import SpawnableTypeData from '../../src/assets/dayz_items_data.json'; 
import { validatePagePermissions } from '../utils/permissions';

export default {
  name: 'ShopManagement',
  components: {
    Modal,
  },
  data() {
    return {
      selectedShopItem: null,
      allowSaveChanges: true,
      selectedShopItemUpdated: {},
      shopItemType: 'npc',
      shopStatus: null,
      showModal: false,
      newShopItem: {},
      shopStats: {},
      showRestrictedServersModal: false,
      allowShopAdd: true,
      
    };
  },

  computed: {
    Npcs() {
      let survivor_names = SpawnableTypeData.Survivors.map(survivor => ({
        name: survivor,
        value: survivor,
      }));

      let vehicles = Object.entries(SpawnableTypeData.vehicles).flatMap(
        ([vehicle, details]) => {
          if (!details.variants || details.variants.length === 0) {
            // make the name the in_game_name plus the variant
            return { name: details.in_game_name, value: vehicle };
          }

          return details.variants.map(variant => {
            return {
              name: details.in_game_name + ` ${variant}`,
              value: `${vehicle}_${variant}`,
            };
          });
        }
      );

      return [...survivor_names, ...vehicles];
    },
    currentLinkedServers() {
      return this.$store.state.linked_servers
    },
    currentShopItemsData() {
      
      return this.$store.state.cachedShopItems.items
        ? this.$store.state.cachedShopItems.items.sort((a, b) => {
            if (a.item_name < b.item_name) return -1;
            if (a.item_name > b.item_name) return 1;
            return 0;
          })
        : [];
    }
  },

  methods: {
    getChangedKeyValuePairs(changdObject, originalObject) {
      let changedValues = {};
      for (let key in changdObject) {
        if (changdObject[key] !== originalObject[key]) {
          changedValues[key] = changdObject[key];
        }
      }
      return changedValues;
    },
    updateRestrictedServers (event, server_id) {

      console.log(event.target.classList)
      console.log(event.target.classList.contains('modal-checkbox-select'))
      console.log(server_id)
      
      
      // modal-checkbox-select
      if (!event.target.classList.contains('modal-checkbox-select') && !event.target.classList.contains('checkbox-name')) {
        return;
      }

      if (!this.selectedShopItemUpdated.restricted_servers) {
        this.selectedShopItemUpdated.restricted_servers = [];
      }

      let serverIndex = this.selectedShopItemUpdated.restricted_servers.findIndex(
        server_id => server_id === server_id
      );

      if (serverIndex === -1) {
        this.selectedShopItemUpdated.restricted_servers.push(server_id);
      } else {
        this.selectedShopItemUpdated.restricted_servers.splice(serverIndex, 1);
      }


      
    },

    async discardRestrictedServersChanges() {
      this.showRestrictedServersModal = false;
      this.selectedShopItemUpdatedCopy.restricted_servers = 
        this.$store.state.linked_servers.map(server => {
          return {
            id: server.id,
            name: server.server_identifier,
            selected: this.selectedShopItem.restricted_servers.includes(
              server.id
            ),
          };
        })
  
    },

    async changedSelectedShopItem() {
      let valid_perms = validatePagePermissions('shop_admin.read', this.$store)
      if (!valid_perms) {
        this.selectedShopItem = null;
              return;
            }
      this.selectedShopItemUpdated = JSON.parse(
        JSON.stringify(this.selectedShopItem)
      );
      
    },

    async openRestrictedServersModal() {
      this.showRestrictedServersModal = true;
    },

    async updateShopStatus() {
      let valid_perms = validatePagePermissions('shop_admin.update', this.$store)
      if (!valid_perms) {
              return;
            }
      // send request to api to update the shop status
      const response = await this.$store.dispatch('sendRequest', {
        method: 'PATCH',
        url: `${this.$store.state.apiUrl}/api/shop/config`,
        data: {
          shop_open: this.shopStatus,
        },
        show_message_on_success: true,
      });

      if (response) {
        this.$store.commit('updateShopOpen', response.shop_open);
        this.shopStats.shop_open = response.shop_open;
      }
    },

    async addNewShopItem() {
      this.allowShopAdd = false;
      // send request to the api to Add New Item
      const response = await this.$store.dispatch('sendRequest', {
        method: 'POST',
        url: `${this.$store.state.apiUrl}/api/shop/items`,
        data: {
          ...this.newShopItem,
        },
        show_message_on_success: true,
      });

      if (response) {
        this.showModal = false;
        let curentCachedShopItems = this.$store.state.cachedShopItems.items
          ? this.$store.state.cachedShopItems.items
          : [];
          curentCachedShopItems.push(response.shop_item);
        this.newShopItem = {};
        this.allowShopAdd = true;
        const websocketMessage = {
          event: "update_shop_items",
          discord_server_id: this.$store.state.client_discord_server_id,
          data: {
            items: curentCachedShopItems,
            last_cache_timestamp: new Date(),
          },
        };

        this.$store.state.websocket.send(
          JSON.stringify(websocketMessage)
        );
     
      } else {
        this.allowShopAdd = true;
        return;
      }
    },

    async loadShopItems() {
      // check for cached shop items
      let cachedShopItems = this.$store.state.cachedShopItems.items
        ? this.$store.state.cachedShopItems.items
        : null;

      

      if (cachedShopItems) {
        
        let cached_time = this.$store.state.cachedShopItems.last_cache_timestamp;
        if (cached_time) {
          cached_time = new Date(cached_time);
        }
       
        let current_time = new Date();
        
        let time_difference = current_time - cached_time;
        
        // if less than 10 minutes, use the cached shop items
        if (time_difference < 600000) {
          return;
        }

        let shopItems = await this.fetchShopItems();
       
        if (shopItems) {
          this.$store.commit('updateCachedShopItems', {
            items: shopItems,
            last_cache_timestamp: new Date()
          })
        } else {
          this.$store.commit('updateCachedShopItems', {
            items: [],
            last_cache_timestamp: new Date()
          })
        }

        
    }
  },

    async fetchShopItems() {
      const response = await this.$store.dispatch('sendRequest', {
        method: 'GET',
        url: `${this.$store.state.apiUrl}/api/shop/items`,
        show_message_on_success: false,
      });

      if (response) {
        return response.shop_items;
      } else {
        return null;
      }
    },

    async updateShopItem() {
      // send request to api to update the shop item with the updated field

      this.allowSaveChanges = false;

      let changed_values = this.getChangedKeyValuePairs(
        this.selectedShopItemUpdated,
        this.selectedShopItem
      );

      let noRestrictedServerChanges = this.selectedShopItemUpdated.restricted_servers.every(
        server => this.selectedShopItem.restricted_servers.includes(server)
      ) && this.selectedShopItem.restricted_servers.every(
        server => this.selectedShopItemUpdated.restricted_servers.includes(server)
      );

  

      // Handle the case where restricted_servers is present in changed_values
      if (changed_values.restricted_servers) {
        // If the restricted_servers array is empty, set it to an empty array
        if (changed_values.restricted_servers.length === 0) {
          changed_values.restricted_servers = [];
        }
      }

      // If there are no changes in restricted servers, remove the restricted_servers key from changed_values
      if (noRestrictedServerChanges) {
        delete changed_values.restricted_servers;
      }
      // if key is restricted_servers, change it to restricted_server_ids
      if (changed_values.restricted_servers) {
        changed_values.restricted_server_ids = changed_values.restricted_servers;
        delete changed_values.restricted_servers;
      }

      // send request to the api to update the shop item
      const response = await this.$store.dispatch('sendRequest', {
        method: 'PATCH',
        url: `${this.$store.state.apiUrl}/api/shop/items`,
        data: {
          id: this.selectedShopItem.id,
          ...changed_values,
        },
        show_message_on_success: true,
      });

      if (response) {
          this.selectedShopItem = response.shop_item;
          this.selectedShopItemUpdated = JSON.parse(
            JSON.stringify(this.selectedShopItem)
          );

          // current cached shop items 
          let cachedShopItems = this.$store.state.cachedShopItems

          // update the cached shop items
          let updatedShopItems = cachedShopItems.items.map(item => {
            if (item.id === this.selectedShopItem.id) {
              return this.selectedShopItem;
            }
            return item;
          });

          const websocketMessage = {
            event: "update_shop_items",
            discord_server_id: this.$store.state.client_discord_server_id,
            data: {
              items: updatedShopItems,
              last_cache_timestamp: new Date(),
            },
          };

          this.$store.state.websocket.send(
            JSON.stringify(websocketMessage)
          );


          this.allowSaveChanges = true;

      } else {
        this.allowSaveChanges = true;
        return
      }


    },

    async deleteShopItem() {
      // send request to api to delete the selected shop item
      const response = await this.$store.dispatch('sendRequest', {
        method: 'DELETE',
        url: `${this.$store.state.apiUrl}/api/shop/items`,
        data: {
          id: this.selectedShopItem.id,
        },
        show_message_on_success: true,
      });

      if (response) {
        this.showModal = false;
        let curentCachedShopItems = this.$store.state.cachedShopItems.items
          ? this.$store.state.cachedShopItems.items
          : [];
          curentCachedShopItems = curentCachedShopItems.filter(
            item => item.id !== this.selectedShopItem.id
          );
        const websocketMessage = {
          event: "update_shop_items",
          discord_server_id: this.$store.state.client_discord_server_id,
          data: {
            items: curentCachedShopItems,
            last_cache_timestamp: new Date(),
          },
        };

        this.$store.state.websocket.send(
          JSON.stringify(websocketMessage)
        );
        this.selectedShopItem = null;
      } else {
        return;
      }
    },

    async fetchShopStats() {
      let cachedStats = this.$store.state.shopStatistics
        ? this.$store.state.shopStatistics
        : null;

      if (cachedStats) {
        this.shopStats = cachedStats;
      
        return;
      }

      const response = await this.$store.dispatch('sendRequest', {
        method: 'GET',
        url: `${this.$store.state.apiUrl}/api/shop/stats`,
        show_message_on_success: false,
      });

      if (response) {
        this.shopStats = response.shop_stats;
        this.$store.commit('setShopStatistics', response.shop_stats);
      }
    },

    discardAddNewItem() {
      this.showModal = false;
      this.newShopItem = {};
    },

    async fetchShopStatus() {
      const response = await this.$store.dispatch('sendRequest', {
        method: 'GET',
        url: `${this.$store.state.apiUrl}/api/shop/config`,
        show_message_on_success: false,
      });

      if (response) {
        this.shopStatus = response.shop_open;
    }


  },
},

  async mounted() {
    await this.fetchShopStatus();
    await this.loadShopItems();
    await this.fetchShopStats();
  },
};
</script>

<style scoped>
.shop-management-container {
  background: rgba(187, 218, 255, 0.04);
  border: 1px solid rgba(187, 218, 255, 0.08);
  border-radius: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  text-align: left;
}

.shop-management-header {
  margin-bottom: 8px;
}

.shop-stats-container {
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, max-content)); */
  display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
    align-items: center;
    color: white;
    column-gap: 20px;
    row-gap: 10px;
  /* column-gap: 50px;
  row-gap: 12px; */
}

.stb-heading {
  font-size: 1.6rem;
  white-space: nowrap;
}

.shop-stat-box {
  background-color: rgba(195, 218, 252, 0.08);
  display: flex;
  height: 100%;
  justify-content: center;
  color: white;
  border-radius: 16px;
  padding: 4px 12px;
}

.shop-management-inner {
  margin-top: 24px;
}

.smi-top {
  display: grid;
    grid-template-columns: repeat(auto-fit, minmax(165px, 165px));
    align-items: center;
    color: white;
    column-gap: 20px;
    row-gap: 10px;
  align-items: flex-end;
}
.add-shop-item-btn {
  box-sizing: border-box;
  display: inline-block;
  line-height: 150%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-family: 'DM Mono';
  font-size: 1.4em;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-align: left;
  padding: 10px 12px 10px 12px;
  border-radius: 12px;
  outline: none;
  border: none;
}

.smi-body-heading {
  margin-top: 36px;
}

.smi-body-settings {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, max-content));
  column-gap: 20px;
  row-gap: 12px;
}

.shop-setting-input {
  flex-direction: column;
  text-align: left;
  display: flex;
  row-gap: 4px;
  justify-content: center;
}

.input {
  background-color: rgba(195, 218, 252, 0.08);
  box-sizing: border-box;
  display: inline-block;
  padding: 8px 12px;
  border: 1px solid rgba(195, 238, 252, 0.16);
  border-radius: 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  /* identical to box height, or 21px */

  color: #ffffff;
}
.input:focus,
.dropdown:focus {
  outline: none;
}

.dropdown option {
  color: black;
}

.input option {
  color: black;
}

.label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 15px;
  /* identical to box height */
  /* letter-spacing: 0.08em; */
  padding-left: 5px;

  color: #bbced8;
}

.add-shop-item-btn,
.choose-shop-item {
  height: max-content;
}

.modal-body-content-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.smi-body-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  column-gap: 12px;
}
.save-settings-btn, .delete-btn {
  width: max-content;
}



.modal-body-restricted-servers-container {
  margin-top: 8px;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding-right: 8px;
}

.active-checked {
  background: rgba(195, 218, 252, 0.08);
}
.checkbox {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #bbced8a3;
  border-radius: 4px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-label {
  width: 16px;
  height: 16px;
  border: 1px solid #bbced8a3;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.checkbox:checked + .checkbox-label {
  background: #42d09c3d;

  border: 2px solid #42d09ca3;
  background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M13.3657%204.23447C13.5157%204.3845%2013.5999%204.58794%2013.5999%204.80007C13.5999%205.0122%2013.5157%205.21565%2013.3657%205.36567L6.96568%2011.7657C6.81566%2011.9156%206.61221%2011.9999%206.40008%2011.9999C6.18795%2011.9999%205.9845%2011.9156%205.83448%2011.7657L2.63448%208.56567C2.48876%208.41479%202.40812%208.21271%202.40994%208.00295C2.41177%207.7932%202.4959%207.59255%202.64423%207.44422C2.79255%207.29589%202.9932%207.21176%203.20296%207.20993C3.41272%207.20811%203.6148%207.28875%203.76568%207.43447L6.40008%2010.0689L12.2345%204.23447C12.3845%204.0845%2012.588%204.00024%2012.8001%204.00024C13.0122%204.00024%2013.2157%204.0845%2013.3657%204.23447Z%22%20fill%3D%22%2342D09C%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: center;
}
</style>
