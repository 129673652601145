<template>
  <div class="game-servers-management-container">
    <div class="game-servers-options-container">
      <h1 class="gsoc-heading content-header">Game Server Options</h1>
    </div>

    <select
      name=""
      id=""
      class="choose-gameserver-dropdown"
      v-model="selectedGameServerID"
      @change="updateSelectedGameServer()"
    >
      <option disabled value="null">Choose Game Server</option>
      <option v-for="server in allLinkedServers" :key="server.id" :value="server.id">
        {{ server.server_identifier }}
      </option>
    </select>

    <p class="server-options-sub-heading">Server Options</p>

    <div class="gsoc-content">
      <button
        class="gsoc-button"
        @click="openModal('general')"
        
      >
        General settings
      </button>
      <button
        class="gsoc-button"
        @click="openModal('bounty')"
        
      >
        Bounty settings
      </button>
      <button
        class="gsoc-button"
        @click="openModal('spawn_kill')"
        
      >
        Spawn Kill settings
      </button>
      <button
        class="gsoc-button"
        @click="openModal('killfeed')"
        
      >
        Killfeed settings
      </button>
      <button
        class="gsoc-button"
        @click="openModal('faction-wars')"
        
      >
        Faction War settings
      </button>
      <button
        class="gsoc-button"
        @click="openModal('webhooks')"
        
      >
        Webhook settings
      </button>
    </div> 
    <Modal :show="showModal" @update:show="showModal = $event">
      <template v-slot:header-content>
        <div class="header-content-container">
          <p class="modal-sub-heading">{{ modalHeader }}</p>
          <p class="modal-main-heading">Edit Configuration</p>
        </div>
      </template>
      <template v-slot:body-content>
        <div class="modal-body-content-container">
          <form v-if="modalContentType === 'general'" class="modal-body-cc-inner">
            <div class="config-row">
              <div class="config-option">
                <label for="server_identifier">Server Identifier</label>
                <input
                  name=""
                  class="config-option-input"
                  v-model="selectedGameServerUpdatedObject.server_identifier"
                  type="text"
                  maxlength="50"
                  required
                />
              </div>
              <div class="config-option">
                <label for="role_id_on_link">Role On Account Link</label>
                <select
                  name=""
                  class="config-option-dropdown"
                  v-model="selectedGameServerUpdatedObject.link_role_id"
                >
                  <option value="null" disabled selected>No Role</option>
                  <option
                    v-for="role in allRoles"
                    :key="role.name"
                    :value="String(role.id)"
                  >
                    {{ role.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="config-row">
              <div class="config-option">
                <label for="server_identifier">Credits Per Minute Played</label>
                <input
                  name=""
                  class="config-option-input"
                  v-model="selectedGameServerUpdatedObject.credits_per_minute_played"
                  type="number"
                  max="1000000"
                  min="0"
                />
              </div>
              <div class="config-option">
              <label for="server_restart_interval">Server Restart Interval (Minutes)</label>
              <input
                name=""
                class="config-option-input"
                v-model="selectedGameServerUpdatedObject.server_restart_interval"
                type="number"
                min="1" 
              />
            </div>
            </div>

            <div class="config-row">
              <div class="config-option">
              <label for="server_restart_time">Server Restart Time (Local)</label>
              <input
                name=""
                class="config-option-input"
                v-model="formattedDateTimeServerRestart"
                type="datetime-local"
                :min="currentDateTimeLocal"
              />
            </div>
              <div class="config-option">
                <label for="max_account_link">Max Account Link</label>
                <input
                  name=""
                  class="config-option-input"
                  v-model="selectedGameServerUpdatedObject.max_account_link"
                  type="number"
                  min="1"
                  max="15"
                />
              </div>
            </div>

            <!-- <div class="config-row">
              <div class="config-option">
                <label for="apply_credits_on_build"
                  >Apply Credits on Build</label
                >
                <select
                name=""
                class="config-option-dropdown"
                v-model="selectedGameServerUpdatedObject.apply_credits_on_build"
              >
                <option :value="true">True</option>
                <option :value="false">False</option>
              </select>
              </div>
              <div class="config-option">
                <label for="credits_on_build">Credits On Build</label>
                <input
                  name=""
                  class="config-option-input"
                  v-model="selectedGameServerUpdatedObject.credits_on_build"
                  type="number"
                  min="0"
                  max="1000000"
                />
              </div>
            </div> -->

            <div class="config-row">
              <div class="config-option">
                <label for="server_active_connections_channel_id">
                  Connections Display Channel
                </label>
                <select
                  name=""
                  class="config-option-dropdown"
                  v-model="selectedGameServerUpdatedObject.server_stats_channel_id"
                >
                <option value="">None</option>
                  <option
                    v-for="channel in allChannels"
                    :key="channel.id"
                    :value="channel.id"
                  >
                    {{ channel.name }}
                  </option>

                </select>

              </div>
              <div class="config-option">
                <label for="allow_shop_purchases">
                  Allow Shop Purchases
                </label>
                <select
                  name=""
                  class="config-option-dropdown"
                  v-model="selectedGameServerUpdatedObject.allow_shop_purchases"
                >
                  <option :value="true">True</option>
                  <option :value="false">False</option>

                </select>

              </div>
            </div>
          </form>

          <form class="modal-body-cc-inner" v-if="modalContentType === 'bounty'">
            <div class="config-row">
              <div class="config-option">
                <label for="allow_bounties">Allow Bounties</label>
                <select
                  name=""
                  id=""
                  class="config-option-dropdown"
                  v-model="
                    selectedGameServerUpdatedObject.bounty_config.allow_bounties
                  "
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div class="config-option">
                <label for="minimum_bounty_amount">Minimum Bounty Amount</label>
                <input
                  name=""
                  class="config-option-input"
                  v-model="
                    selectedGameServerUpdatedObject.bounty_config
                      .minimum_bounty_amount
                  "
                  type="number"
                  min="0"
                  max="1000000"
                />
              </div>
            </div>

            <div class="config-row">
              <div class="config-option">
                <label for="maximum_bounty_amount">Maximum Bounty Amount</label>
                <input
                  name=""
                  class="config-option-input"
                  v-model="
                    selectedGameServerUpdatedObject.bounty_config
                      .maximum_bounty_amount
                  "
                  type="number"
                  min="0"
                  max="1000000"
                />
              </div>
              <div class="config-option">
                <label for="maximum_bounty_duration_minutes"
                  >Max Bounty Time (Minutes)
                </label>
                <input
                  name=""
                  class="config-option-input"
                  v-model="
                    selectedGameServerUpdatedObject.bounty_config
                      .maximum_bounty_duration_minutes
                  "
                  type="number"
                  min="0"
                  max="525600"
                />
              </div>
            </div>
            <div class="config-row">
              <div class="config-option">
                <label for="server_active_connections_channel_id">
                  Bounties Channel
                </label>
                <select
                  name=""
                  class="config-option-dropdown"
                  v-model="selectedGameServerUpdatedObject.bounty_config.bounties_channel_id"
                >
                <option value="">None</option>
                  <option
                    v-for="channel in allChannels"
                    :key="channel.id"
                    :value="channel.id"
                  >
                    {{ channel.name }}
                  </option>

                </select>

              </div>
              
            </div>
          </form>

          
          <form
            class="modal-body-cc-inner"
            v-if="modalContentType === 'spawn_kill'"
          >
            <div class="config-row">
              <div class="config-option">
                <label for="remove_credits_on_spawn_kill">Remove Credits</label>
                <select
                  name=""
                  id=""
                  class="config-option-dropdown"
                  v-model="
                    selectedGameServerUpdatedObject.spawn_kill_config
                      .spawn_kill_deduction
                  "
                >
                  <option :value="true">True</option>
                  <option :value="false">False</option>
                </select>
              </div>
              <div class="config-option">
                <label for="minimum_bounty_amount"
                  >Spawn Kill Timer (seconds)</label
                >
                <input
                  name=""
                  class="config-option-input"
                  v-model="
                    selectedGameServerUpdatedObject.spawn_kill_config
                      .spawn_kill_seconds
                  "
                  type="number"
                  min="10"
                  max="1000000"
                />
              </div>
            </div>

            <div class="config-row">
              <div class="config-option">
                <label for="remove_credits_amount">Remove Credits Amount</label>
                <input
                  name=""
                  class="config-option-input"
                  v-model="
                    selectedGameServerUpdatedObject.spawn_kill_config
                      .spawn_kill_amount
                  "
                  type="number"
                  min="1"
                  max="1000000"
                />
              </div>
             
              <div class="config-option">
                <label for="spawn_kill_bail_amount">Ban Bail Amount</label>
                <input
                  name=""
                  class="config-option-input"
                  v-model="
                    selectedGameServerUpdatedObject.spawn_kill_config.spawn_kill_bail
                  "
                  type="number"
                  min="0"
                  max="1000000"
                />
              </div>
            </div>
            <div class="config-row">
              <div class="config-option">
                <label for="ban_on_spawn_kill">Ban On Spawn Kill</label>
                <select
                  name=""
                  class="config-option-dropdown"
                  v-model="
                    selectedGameServerUpdatedObject.spawn_kill_config.spawn_kill_ban
                  "
                >
                  <option :value="true">True</option>
                  <option :value="false">False</option>
                </select>
              </div>
              <div class="config-option">
                <label for="spawn_kill_ban_duration">Ban duration (days)</label>
                <input
                  name=""
                  class="config-option-input"
                  v-model="
                    selectedGameServerUpdatedObject.spawn_kill_config
                      .spawn_kill_ban_duration
                  "
                  type="number"
                  min="1"
                  max="365"
                />
              </div>
            </div>
            
          </form>

          <form
            action=""
            v-if="modalContentType === 'killfeed'"
            class="modal-body-cc-inner"
          >
            <div class="config-row">
              <div class="config-option">
                <label for="apply_credits_on_kill">Apply Credits On Kill</label>
                <select
                  name=""
                  id=""
                  class="config-option-dropdown"
                  v-model="
                    selectedGameServerUpdatedObject.killfeed_config.credits_on_kill
                  "
                >
                  <option :value="true">True</option>
                  <option :value="false">False</option>
                </select>
              </div>
              <div class="config-option">
                <label for="credit_amount_on_kill">Credits On Kill</label>
                <input
                  type="number"
                  min="1"
                  max="1000000"
                  class="config-option-input"
                  v-model="
                    selectedGameServerUpdatedObject.killfeed_config.kill_credits
                  "
                />
              </div>
            </div>
            <div class="config-row">
              <div class="config-option">
                <label for="show_killer_location">Show Killer Location</label>
                <select
                  name=""
                  id=""
                  class="config-option-dropdown"
                  v-model="
                    selectedGameServerUpdatedObject.killfeed_config
                      .show_killer_location
                  "
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div class="config-option">
                <label for="server_active_connections_channel_id">
                  Killfeed Channel
                </label>
                <select
                  name=""
                  class="config-option-dropdown"
                  v-model="selectedGameServerUpdatedObject.killfeed_config.killfeed_channel_id"
                >
                <option value="">None</option>
                  <option
                    v-for="channel in allChannels"
                    :key="channel.id"
                    :value="channel.id"
                  >
                    {{ channel.name }}
                  </option>

                </select>

              </div>
            </div>
          </form>

          <form
            action=""
            class="modal-body-cc-inner"
            v-if="modalContentType === 'faction-wars'"
            >
            <div class="config-row">
              <div class="config-option">
                <label for="show_killer_location">Max War Duration (Days)</label>
                <input
                  name=""
                  id=""
                  class="config-option-input"
                  type="number"
                  min="1"
                  max="30"
                  v-model="
                    selectedGameServerUpdatedObject.faction_wars_config
                      .max_faction_war_duration
                  "
                >
              </div>
              <div class="config-option">
                <label for="server_active_connections_channel_id">
                  War History Channel
                </label>
                <select
                  name=""
                  class="config-option-dropdown"
                  v-model="selectedGameServerUpdatedObject.faction_wars_config.faction_wars_history_channel_id"
                >
                <option value="">None</option>
                  <option
                    v-for="channel in allChannels"
                    :key="channel.id"
                    :value="channel.id"
                  >
                    {{ channel.name }}
                  </option>

                </select>

              </div>
            </div>
          </form>

          <form
            action=""
            class="modal-body-cc-inner"
            v-if="modalContentType === 'webhooks'"
          >
            <div class="config-row">
        
              <div class="config-option">
                <label for="connections_webhook_url"
                  >Connections Webhook Channel</label
                >
                <select
                  name=""
                  id=""
                  class="config-option-dropdown"
                  v-model="selectedGameServerUpdatedObject.webhooks_config.connection_logs_channel_id"
                >
                <option value="" selected>None</option>
                  <option
                    v-for="channel in allChannels"
                    :key="channel.id"
                    :value="channel.id"
                  >
                    {{ channel.name }}
                  </option>
                </select>
              </div>
              <div class="config-option">
                <label for="pvp_zones_webhook_channel">PvP Zones Channel</label>
                <select
                  name=""
                  id=""
                  class="config-option-dropdown"
                  v-model="selectedGameServerUpdatedObject.webhooks_config.pvp_zones_channel_id"
                >
                 <option value="" selected>None</option>
                  <option
                    v-for="channel in allChannels"
                    :key="channel.id"
                    :value="channel.id"
                  >
                    {{ channel.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="config-row">
              <div class="config-option">
                <label for="placements_webhook_channel"
                  >Placement Logs Channel</label
                >
                <select
                  name=""
                  id=""
                  class="config-option-dropdown"
                  v-model="selectedGameServerUpdatedObject.webhooks_config.placement_logs_channel_id"
                >
                <option value="" selected>None</option>
                  <option
                    v-for="channel in allChannels"
                    :key="channel.id"
                    :value="channel.id"
                  >
                    {{ channel.name }}
                  </option>
                </select>
              </div>
            </div>
           
            
          </form>

          

        </div>
      </template>
      <template v-slot:footer-content>
        

        <div
          class="modal-footer-content-container"
        
        >
          <button
            class="modal-btn cancel-btn"
            @click="cancelChanges()"
          >
            Cancel Changes
          </button>
          <button
            :disabled="!allowSubmitChanges"
            class="modal-btn save-btn"
            @click="submitServerChanges()"
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="btn-text">Save Changes</p>
          </button>
        </div>

      
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import Modal from './Modal.vue';
import { validatePagePermissions } from '../utils/permissions';
export default {
  components: {
    Modal,
  },
  data() {
    return {
      showModal: false,
      modalContentType: null,
      selectedGameServerID: null,
      selectedGameServerOriginalObject: {},
      selectedGameServerUpdatedObject: {},
      allowSubmitChanges: true,
      currentDateTimeLocal: new Date().toISOString().slice(0, 16), // Current local time in ISO format
      modalHeader: '',
      rolesAndChannels: [],
      
      guildWebhooks: [],
      
    };
  },

  computed: {
   
    
    formattedDateTimeServerRestart: {
        get() {
          if (!this.selectedGameServerUpdatedObject.server_restart_time) {
            // Return the current local time if server_restart_time is null or undefined
            const currentLocalDate = new Date();
            let local_date_string = `${currentLocalDate.getFullYear()}-${String(currentLocalDate.getMonth() + 1).padStart(2, '0')}-${String(currentLocalDate.getDate()).padStart(2, '0')}T${String(currentLocalDate.getHours()).padStart(2, '0')}:${String(currentLocalDate.getMinutes()).padStart(2, '0')}`;
            return local_date_string;
          }
         
          const hasZ = this.selectedGameServerUpdatedObject.server_restart_time.includes('Z');
          const hasOffset = /[+-]\d{2}:\d{2}$/.test(this.selectedGameServerUpdatedObject.server_restart_time);
       
          let isoString = this.selectedGameServerUpdatedObject.server_restart_time.replace(" ", "T");
          if (!hasZ && !hasOffset) {
            isoString += 'Z';
          }
         

          

          // 2024-10-15T01:33:00.000Z
          // Create a Date object assuming it's UTC
       
          const local_time = new Date(isoString);
    

    
          let local_date_string = `${local_time.getFullYear()}-${String(local_time.getMonth() + 1).padStart(2, '0')}-${String(local_time.getDate()).padStart(2, '0')}T${String(local_time.getHours()).padStart(2, '0')}:${String(local_time.getMinutes()).padStart(2, '0')}`;
        
          return local_date_string;
        },
        set(value) {
          
          // Convert local time back to UTC
          const localDate = new Date(value);
          this.selectedGameServerUpdatedObject.server_restart_time = localDate.toISOString();
        },
      },
    allLinkedServers() {
      return this.$store.state.linked_servers;
    },
    currentDateTime() {
      const now = new Date();
      const year = now.getUTCFullYear();
      const month = String(now.getUTCMonth() + 1).padStart(2, '0');
      const day = String(now.getUTCDate()).padStart(2, '0');
      const hours = String(now.getUTCHours()).padStart(2, '0');
      const minutes = String(now.getUTCMinutes()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    },

    allRoles() {
      return this.rolesAndChannels.filter(permType => {
        return permType.type === 'role';
      });
    },
    allChannels() {
      return this.rolesAndChannels.filter(permType => {
        return permType.type === 'channel';
      });
    },
  },

  methods: {
   

    convertUtcToLocal(datetime_string) {
      // if the string contains this :00.000Z, it means it's already in local time, and orginates from the fact that the task run time was initially set to null
      if (datetime_string.includes(':00.000Z')) {
        return datetime_string.slice(0, 16);
      }

      // Parse the UTC datetime string
      let date = new Date(Date.parse(datetime_string + 'Z')); // Append 'Z' to indicate UTC timezone

      // Extract date components
      let year = date.getFullYear();
      let month = ('0' + (date.getMonth() + 1)).slice(-2); // Add 1 to month because it's zero-based
      let day = ('0' + date.getDate()).slice(-2);
      let hours = ('0' + date.getHours()).slice(-2);
      let minutes = ('0' + date.getMinutes()).slice(-2);

      // Construct the formatted date string
      let formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

      // Assign the formatted date to the v-model
      return formattedDate;

    
    },

    convertLocalToUtc(local_datetime_string) {
      const localDate = new Date(local_datetime_string);
    
    // Extract date components
    const year = localDate.getUTCFullYear();
    const month = ('0' + (localDate.getUTCMonth() + 1)).slice(-2); // Add 1 to month because it's zero-based
    const day = ('0' + localDate.getUTCDate()).slice(-2);
    const hours = ('0' + localDate.getUTCHours()).slice(-2);
    const minutes = ('0' + localDate.getUTCMinutes()).slice(-2);
    
    // Construct the formatted UTC date string
    const utcDateString = `${year}-${month}-${day}T${hours}:${minutes}`;
    
    return utcDateString;
    },


    async getSelectedServerDetails() {
      const response = await this.$store.dispatch('sendRequest', {
        url: `${this.$store.state.apiUrl}/api/servers`,
        params: {
          server_id: this.selectedGameServerID,
        },
        show_message_on_success: false
      });

      if (!response) {
        return;
      }
      this.selectedGameServerOriginalObject = response[0];

    },

    checkForServerCacheUpdate() {
      let cachedServers = this.$store.state.cachedServers;
      let is_server_cached = cachedServers.find(
        server => server.id === this.selectedGameServerID
      );
      if (is_server_cached) {
          let current_timestamp = new Date();
          let time_difference = current_timestamp - is_server_cached.timestamp;
          if (time_difference > 600000) { // 600,000 milliseconds = 10 minutes
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
    },

    async updateSelectedGameServer() {
      let valid_perms = validatePagePermissions('servers.read', this.$store)
      if (!valid_perms) {
        this.selectedGameServerID = null;
        this.selectedGameServerOriginalObject = {};
        this.selectedGameServerUpdatedObject = {};
              return;
            }
      let cache_need_update = this.checkForServerCacheUpdate();
      if (!cache_need_update) {
        
        this.selectedGameServerOriginalObject = this.$store.state.cachedServers.find(
          server => server.id === this.selectedGameServerID
        );
        this.selectedGameServerUpdatedObject = JSON.parse(
          JSON.stringify(this.selectedGameServerOriginalObject)
        );
        return;
      }

      await this.getSelectedServerDetails();
      // check if the object is empty, if so, return a status message
      if (Object.keys(this.selectedGameServerOriginalObject).length === 0) {
        this.$store.commit('setStatusMessage', {
          type: 'error',
          text: 'The server details could not be retrieved. Please try again later.',
        });
        return;
      }
      this.selectedGameServerUpdatedObject = JSON.parse(
        JSON.stringify(this.selectedGameServerOriginalObject)
      );

      this.$store.commit('addCachedServer', this.selectedGameServerOriginalObject);
    
    },

    openModal(contentType) {

      if (!this.selectedGameServerID) {
        return this.$store.commit('setStatusMessage', {
          type: 'error',
          text: 'Please select a server to view its settings.',
        });
      }

      let has_permissions = validatePagePermissions(
        'servers.read',
        this.$store
      );

      if (!has_permissions) {
        return 
      }
      let isResponseGood = true;

      if (isResponseGood) {
        this.showModal = true;
        this.modalHeader =
          `${contentType} settings`.charAt(0).toUpperCase() +
          `${contentType} settings`.slice(1);

        this.modalContentType = contentType;

      }
    },
    closeModal() {
      this.showModal = false;
    },
    async getGuildChannelsAndRoles() {
  const response = await this.$store.dispatch('sendRequest', {
    url: `${this.$store.state.apiUrl}/api/dashboard/discord/guilds/roles-and-channels`,
    params: {
      guild_id: this.$store.state.client_discord_server_id,
    },
    show_message_on_success: false
  });

  if (!response) {
    return;
  }

  this.rolesAndChannels = [
    ...response.roles
      .filter(role => role.name !== '@everyone') // Exclude the '@everyone' role
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(role => ({
        ...role,
        type: 'role',
        selected: false,
      })),
    ...response.channels
      .filter(channel => channel.parent_id !== null)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(channel => ({
        ...channel,
        type: 'channel',
        selected: false,
      })),
  ];
},

    getChangedKeyValuePairs(changdObject, originalObject) {

      let changedValues = {};
      for (let key in changdObject) {
        if (changdObject[key] !== originalObject[key]) {
          changedValues[key] = changdObject[key];
        }
      }
      return changedValues;
    },

    async submitServerChanges(){
      if (this.modalContentType === 'general') {
        await this.submitGeneralServerDetails();
      }

      if (this.modalContentType === 'bounty') {
        await this.submitBountyConfigChanges();
      }
      if (this.modalContentType === 'spawn_kill') {
        await this.submitSpawnKillConfigChanges();
      }

      if (this.modalContentType === 'killfeed') {
        await this.submitKillfeedConfigChanges();
      }

      if (this.modalContentType === 'webhooks') {
        await this.submitWebhooksConfigChanges();
      }
      if (this.modalContentType === 'faction-wars') {
        await this.submitFactionWarConfigChanges();
      }
    },

    cancelChanges() {
      this.selectedGameServerUpdatedObject = JSON.parse(
        JSON.stringify(this.selectedGameServerOriginalObject)
      );
      this.showModal = false;

    },

    async submitGeneralServerDetails() {
      // disable the button
      this.allowSubmitChanges = false;
      // Only get the updated values
      let updated_keys = this.getChangedKeyValuePairs(
        this.selectedGameServerUpdatedObject,
        this.selectedGameServerOriginalObject
      );
      // remove any keys from updated_keys, that are nested objects
      updated_keys = Object.keys(updated_keys).reduce((acc, key) => {
        if (typeof updated_keys[key] !== 'object') {
          acc[key] = updated_keys[key];
        }
        return acc;
      }, {});
      // if server_restart_time is in keys, change it to server_next_restart_time
      if (updated_keys.server_restart_time) {
        updated_keys.server_next_restart_time = updated_keys.server_restart_time;
        delete updated_keys.server_restart_time;
      }

      const response = await this.$store.dispatch('sendRequest', {
        method: 'PATCH',
        url: `${this.$store.state.apiUrl}/api/servers`,
        data: {
          id: this.selectedGameServerID,
          ...updated_keys,
        },
      });

      if (!response) {
        this.allowSubmitChanges = true;
        return;
      }
      this.allowSubmitChanges = true;
      this.showModal = false;

      let updatedObject = {
        ...this.selectedGameServerOriginalObject,
        ...response.updated_server,
      };
      this.selectedGameServerOriginalObject = updatedObject;
      this.selectedGameServerUpdatedObject = JSON.parse(
        JSON.stringify(this.selectedGameServerOriginalObject)
      );
      await this.updateCachedServers();

    },

    async submitBountyConfigChanges() {
      // disable the button
      this.allowSubmitChanges = false;

      let updated_keys = this.getChangedKeyValuePairs(
        this.selectedGameServerUpdatedObject.bounty_config,
        this.selectedGameServerOriginalObject.bounty_config
      );

      const response = await this.$store.dispatch('sendRequest', {
        method: 'PATCH',
        url: `${this.$store.state.apiUrl}/api/servers/configs/bounty_config`,
        data: {
          server_id: this.selectedGameServerID,
          ...updated_keys
        },
      });

      if (!response) {
        this.allowSubmitChanges = true;
        return;
      }

      this.selectedGameServerOriginalObject.bounty_config = response.updated_config;
      this.selectedGameServerUpdatedObject = JSON.parse(
        JSON.stringify(this.selectedGameServerOriginalObject)
      );

      this.allowSubmitChanges = true;
      this.showModal = false;
      await this.updateCachedServers();

    
      
    },

    async submitSpawnKillConfigChanges() {
      // disable the button
      this.allowSubmitChanges = false;

      let updated_keys = this.getChangedKeyValuePairs(
        this.selectedGameServerUpdatedObject.spawn_kill_config,
        this.selectedGameServerOriginalObject.spawn_kill_config
      );

      const response = await this.$store.dispatch('sendRequest', {
        method: 'PATCH',
        url: `${this.$store.state.apiUrl}/api/servers/configs/spawn_kill_config`,
        data: {
          server_id: this.selectedGameServerID,
          ...updated_keys
        },
      });

      if (!response) {
        this.allowSubmitChanges = true;
        return;
      }

     
      this.selectedGameServerOriginalObject.spawn_kill_config = response.updated_config;
      this.selectedGameServerUpdatedObject = JSON.parse(
        JSON.stringify(this.selectedGameServerOriginalObject)
      );

      this.allowSubmitChanges = true;
      this.showModal = false;

      await this.updateCachedServers();
    },
    async submitKillfeedConfigChanges() {
      // disable the button
      this.allowSubmitChanges = false;

      let updatedValues = this.getChangedKeyValuePairs(
        this.selectedGameServerUpdatedObject.killfeed_config,
        this.selectedGameServerOriginalObject.killfeed_config
      );

      const response = await this.$store.dispatch('sendRequest', {
        method: 'PATCH',
        url: `${this.$store.state.apiUrl}/api/servers/configs/killfeed_config`,
        data: {
          server_id: this.selectedGameServerID,
          ...updatedValues
        },
      });

      if (!response) {
        this.allowSubmitChanges = true;
        return;
      }

      this.selectedGameServerOriginalObject.killfeed_config = response.updated_config;
      this.selectedGameServerUpdatedObject = JSON.parse(
        JSON.stringify(this.selectedGameServerOriginalObject)
      );

      
      this.allowSubmitChanges = true;
      this.showModal = false;

      await this.updateCachedServers();
    },

    async submitWebhooksConfigChanges() {
      // disable the button
      this.allowSubmitChanges = false;

      let updatedValues = this.getChangedKeyValuePairs(
        this.selectedGameServerUpdatedObject.webhooks_config,
        this.selectedGameServerOriginalObject.webhooks_config
      );

    
      const response = await this.$store.dispatch('sendRequest', {
        method: 'PATCH',
        url: `${this.$store.state.apiUrl}/api/servers/configs/webhooks_config`,
        data: {
          server_id: this.selectedGameServerID,
          ...updatedValues
        },
      });

      if (!response) {
        this.allowSubmitChanges = true;
        return;
      }
      
      this.selectedGameServerOriginalObject.webhooks_config = response.updated_config;
      this.selectedGameServerUpdatedObject = JSON.parse(
        JSON.stringify(this.selectedGameServerOriginalObject)
      );

      this.allowSubmitChanges = true;
      this.showModal = false;

      await this.updateCachedServers();
    },

    async submitFactionWarConfigChanges() {
      // disable the button
      this.allowSubmitChanges = false;

      let updatedValues = this.getChangedKeyValuePairs(
        this.selectedGameServerUpdatedObject.faction_wars_config,
        this.selectedGameServerOriginalObject.faction_wars_config
      );

      const response = await this.$store.dispatch('sendRequest', {
        method: 'PATCH',
        url: `${this.$store.state.apiUrl}/api/servers/configs/faction_wars_config`,
        data: {
          server_id: this.selectedGameServerID,
          ...updatedValues
        },
      });

      if (!response) {
        this.allowSubmitChanges = true;
        return;
      }

      this.selectedGameServerOriginalObject.faction_wars_config = response.updated_config;
      this.selectedGameServerUpdatedObject = JSON.parse(
        JSON.stringify(this.selectedGameServerOriginalObject)
      );

      this.allowSubmitChanges = true;
      this.showModal = false;

      await this.updateCachedServers();
    },

    
    async updateCachedServers() {
       // update the cached servers
      let current_cached_servers = this.$store.state.cachedServers;
      let updated_server_index = current_cached_servers.findIndex(
        server => server.id === this.selectedGameServerID
      );

      current_cached_servers[updated_server_index] = this.selectedGameServerOriginalObject;
      const websocketMessage = {
          event: "update_cached_servers",
          discord_server_id: this.$store.state.client_discord_server_id,
          data: current_cached_servers,
        };
        this.$store.state.websocket.send(
          JSON.stringify(websocketMessage)
        );
        // now we need to update the linked servers, we only need a list of {'id': server.id, 'server_identifier': server.server_identifier}
      let current_linked_servers = JSON.parse(JSON.stringify(this.$store.state.linked_servers.map(server => {
        return {'id': server.id, 'server_identifier': server.server_identifier};
      })));



      // now we just need the id and server_identifier for each current cached server
      
      let updated_linked_servers = current_linked_servers.map(server => {
        if (server.id === this.selectedGameServerOriginalObject.id) {
          return {'id': this.selectedGameServerOriginalObject.id, 'server_identifier': this.selectedGameServerUpdatedObject.server_identifier};
        }
        return server;
      });

      let websocket_message = {
        event: "updated_linked_servers",
        discord_server_id: this.$store.state.client_discord_server_id,
        data: updated_linked_servers,
      };
      this.$store.state.websocket.send(
        JSON.stringify(websocket_message)
      );
    }
  },

  created() {
    this.getGuildChannelsAndRoles();
  },
};
</script>

<style scoped>
.game-servers-management-container {
  background: rgba(187, 218, 255, 0.04);
  border: 1px solid rgba(187, 218, 255, 0.08);
  border-radius: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  row-gap: 24px;
}

.choose-gameserver-dropdown {
  /* Frame 171 */

  box-sizing: border-box;

  display: inline-block;
  padding: 12px 16px;
  gap: 16px;

  max-width: 200px;
  height: 45px;

  border: 1px solid rgba(195, 238, 252, 0.16);
  border-radius: 12px;

  width: 100%;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  /* identical to box height, or 21px */

  color: #ffffff;
  appearance: none; /* Remove default arrow */
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><path d="M12.6666 6.5L7.99992 11.1667L3.33325 6.5" stroke="white" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-position: right 16px center; /* Position it 16px from the right edge */
  padding-right: 36px; /* Add padding to prevent the text from overlapping the arrow */
  cursor: pointer;
  background-color: rgba(195, 218, 252, 0.08);
}

.choose-gameserver-dropdown:focus {
  outline: none;
}

.choose-gameserver-dropdown option {
  color: black;
}

.gsoc-heading {
  text-align: left;
  margin: 0;
}

.gsoc-content {
  display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
    align-items: center;
    color: white;
    column-gap: 20px;
    row-gap: 10px;
}

.server-options-sub-heading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 150%;
  /* identical to box height, or 24px */
  text-align: left;
  color: #ffffff;
}

.gsoc-button {
  display: flex;
  padding: 12px 16px;

  max-width: 200px;

  border: 1px solid rgba(195, 238, 252, 0.16);
  background-color: rgba(195, 218, 252, 0.08);
  border-radius: 12px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 150%;
  /* identical to box height, or 24px */
  text-align: left;
  color: #ffffff;
  cursor: pointer;
}

.modal-sub-heading {
  /* COMMAND NAME */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 0.08em;

  color: #bbced8;
}
.modal-main-heading {
  /* Add Channels */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 29px;
  /* identical to box height */

  color: #ffffff;

  margin-top: 8px;
}

/* Frame 171 */

.config-option-dropdown {
  box-sizing: border-box;

  display: inline-block;

  padding: 8px 12px;

  border: 1px solid rgba(195, 238, 252, 0.16);
  border-radius: 12px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  /* identical to box height, or 21px */

  color: #ffffff;
  appearance: none; /* Remove default arrow */
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><path d="M12.6666 6.5L7.99992 11.1667L3.33325 6.5" stroke="white" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-position: right 16px center; /* Position it 16px from the right edge */
  padding-right: 36px; /* Add padding to prevent the text from overlapping the arrow */
  cursor: pointer;
  background-color: rgba(195, 218, 252, 0.08);
}

.config-option-input,
.config-option-dropdown {
  width: 165px;
}
.config-option-input {
  background-color: rgba(195, 218, 252, 0.08);
  box-sizing: border-box;

  display: inline-block;

  padding: 8px 12px;

  border: 1px solid rgba(195, 238, 252, 0.16);
  border-radius: 12px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  /* identical to box height, or 21px */

  color: #ffffff;
}
.config-option-dropdown:focus,
.config-option-input:focus {
  outline: none;
}

.config-option-dropdown option,
.config-option-input option {
  color: black;
}

.modal-body-cc-inner {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
.config-row {
  display: flex;
  justify-content: space-between;
}

.config-option {
  flex-direction: column;
  text-align: left;
  display: flex;
  row-gap: 4px;
}

.config-option label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 15px;
  /* identical to box height */
  /* letter-spacing: 0.08em; */
  padding-left: 5px;

  color: #bbced8;
}

/* for screens below 700px */
@media (max-width: 800px) {
  
}

</style>
