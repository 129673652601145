<template>
    <div v-if="showMessage && message" :class="['status-message', message.type]">
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="message.type === 'error'">
<path d="M1 9L9 1M1 1L9 9" stroke="#F64B74" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="message.type !== 'error'">
<path d="M1 5.8L4.2 9L12.2 1" stroke="#42D09C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


    {{ message.type === 'error' ? 'Error: ' : '' }} {{ message.text }}
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'StatusMessage',
  data() {
    return {
      showMessage: false,
    };
  },
  computed: {
    message() {

      return this.$store.state.statusMessage;
    }
  },
  watch: {
    message: {
    handler() {
      if (this.message) {
        this.showMessage = true;
        setTimeout(() => {
          this.showMessage = false;
          this.$store.dispatch('resetStatusMessage');
        }, 4500);
      }
    },
    immediate: true,
  },
},
};
</script>

<style>
.status-message {
  position: fixed; /* Change from absolute to fixed */
  padding: 8px 16px 8px 12px;
  /* centre it in the middle of page */
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  font-family: DM Mono;
  top: 5%; /* Adjust as needed */
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-align: left;
  z-index: 1000;
}

.success {
  background: #42D09C3D;
  color: #42D09C;

}

.error {
  background: #F64B7429;
  color: #F64B74;
  
}

@media (max-width: 700px) {
  .status-message {
    top: 12% !important;
  }
  
}


</style>
